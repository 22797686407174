<template>
  <div>
    <div class="row">


      <div class="col-12 col-md-6 col-lg-8 col-xl-9 m-auto">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <h4 class="title mt-1">{{ $t('documents_folders.resolution') }}</h4>
                  </div>

                  <div class="col-12 text-center">
                    <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
                  </div>

                  <div class="col-12 col-lg-6 text-center mt-lg-5">

                    <n-button class="m-auto btn-default btn-sm"
                              :disabled="loading || saving || selectedDocuments.length === 0"
                              v-on:click="downloadSelectedDocuments"
                    >
                      <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      {{ $t('common.download') }} {{ selectedDocuments.length }} {{ $t('common.selected') }}
                    </n-button>

                  </div>

                  <div class="col-12 col-lg-6 my-2">
                    <label class="mt-3">{{ $t('document.search_doc') }}</label>
                    <el-input
                            v-model="searchDocument"
                            type="text"
                            name="searchDocument"
                            v-on:change="handleSearchDocument"
                            v-on:clear="loadDocs(currentPageNumber, pageSize)"
                            clearable
                            :disabled="loading"
                            :placeholder="$t('common.search_info')">
                      <template #prepend><i class="el-icon-search"></i></template>
                    </el-input>
                  </div>

                  <div class="col-12">
                    <el-table class="table-striped"
                              :data="documents"
                              @selection-change="documentSelection"
                              border style="width: 100%">

                      <el-table-column type="selection" width="55"></el-table-column>

                      <el-table-column key="regDate" :min-width="80" prop="regDate" :label="$t('document.upload_date')">
                        <template #default="scope">
                          {{ formatDate(scope.row.uploadDate, true) }}
                        </template>
                      </el-table-column>
                      <el-table-column key="name" :min-width="120" prop="name" label="File"></el-table-column>
                      <el-table-column key="notes" :min-width="120" prop="description" :label="$t('document.notes')"></el-table-column>
                      <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">
                        <template #default="scope">
                          <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                    :disabled="loading || saving"
                                    v-on:click="preview(scope.row.id)">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                          </n-button>

                          <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                    :disabled="loading || saving"
                                    v-on:click="downloadDocument(scope.row.id, scope.row.name)">
                            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                          </n-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>


                  <div class="col-12">
                    <div class="row">
                      <div class="order-0 order-lg-0 col-6 col-lg-3">
                        <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                        <el-select class="select-default" v-model="pageSize" value="" label=""
                                   v-on:change="handlePageSizeChange">
                          <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                          <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                          <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                          <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                        </el-select>
                      </div>

                      <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                        <el-pagination
                                class="pt-3"
                                v-model:currentPage="currentPageNumber"
                                v-model:page-size="pageSize"
                                :page-sizes="[10,20,50,100]"
                                :pager-count="11"
                                :small="true"
                                :disabled="loading || saving"
                                layout="prev, pager, next"
                                :total="totalItems"
                                @current-change="handlePageChange"
                        >
                        </el-pagination>
                      </div>

                      <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                        <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElPagination } from "element-plus";

  export default {
    name: "ResolutionsTemplates",
    components: {
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElPagination.name]: ElPagination,
      [ElInput.name]: ElInput
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        documents: [],
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
        searchDocument: null,
        selectedDocuments: []
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message) {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true) {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
        preview(docIri) {
            let id = docIri.split("/");
            id = id[id.length-1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-public-document/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.target = "_blank";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);});
        },
        downloadDocument(docIri, nomeFile) {
            let id = docIri.split("/");
            id = id[id.length-1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-public-document/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = nomeFile;
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        },
        handlePageSizeChange () {
            this.loadDocs(this.currentPageNumber, this.pageSize);
        },
        handlePageChange (val) {
            this.loadDocs(val, this.pageSize);
        },
        loadDocs(page, pageSize) {
            this.loading = true;
            this.error = null;

            let filter = "";
            if (this.searchDocument && this.searchDocument.length > 2)
                filter = "&name=" + encodeURIComponent(this.searchDocument);

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'public_documents?folder=resolution&itemsPerPage=' + pageSize + '&page=' + page + filter)
                .then(response => {
                    this.documents = response.data["hydra:member"];
                    this.totalItems = response.data["hydra:totalItems"];
                    // this.activeFolder = folder;
                    this.currentPageNumber = page;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
        handleSearchDocument() {
            if (this.searchDocument && this.searchDocument.length > 2)
                this.loadDocs(this.currentPageNumber, this.pageSize);
        },
        documentSelection(items) {
            this.selectedDocuments = items;
        },
        downloadSelectedDocuments() {
            this.loading = true;

            let ids = "";
            for(let i=0; i<this.selectedDocuments.length; i++) {
                ids += this.selectedDocuments[i].id;
                if(i<this.selectedDocuments.length -1)
                    ids += ",";
            }

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'documents/get_zip_selected?ids=' + ids, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/zip'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = this.$i18n.t('documents_folders.resolution') + ".zip";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
    },
    computed: {
    },
    beforeMount() {
        this.loadDocs(this.currentPageNumber, this.pageSize);
    },
  }
</script>

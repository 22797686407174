<template>

  <div class="row">
    <div class="col-lg-5 m-auto order-2 order-lg-1">
      <div class="info-area info-horizontal">
        <div class="icon icon-primary text-primary">
          <font-awesome-icon icon="phone-alt" size="2x"></font-awesome-icon>
        </div>
        <div class="description">
          <h5 class="info-title">{{ $t("contacts.phone_number" )}}</h5>
          <p class="description">
            {{ phone }}
          </p>
        </div>
      </div>

      <div class="info-area info-horizontal">
        <div class="icon icon-primary text-primary">
          <i class="now-ui-icons location_pin text-primary"></i>
        </div>
        <div class="description">
          <h5 class="info-title">{{ $t("contacts.location" )}}</h5>
          <p class="description">
            {{ company_name }} {{ company_type }}
            <br>
            {{ company_country }}<br>
            {{ company_address }}<br>
          </p>
        </div>
      </div>
    </div>

    <div class="col-lg-7 m-auto order-1 order-lg-2">

      <vnud-card class="card-signup text-center mb-5">
        <template v-slot:header>
          <h4 class="card-title">{{ $t("contact_us") }}</h4>
          <div class="social">
            <button v-if="twitter !== ''" class="btn btn-icon btn-round btn-twitter" :href="twitter">
              <i class="fa fa-twitter"></i>
            </button>
            <button v-if="facebook !== ''" class="btn btn-icon btn-round btn-facebook" :href="facebook">
              <i class="fa fa-facebook-f"></i>
            </button>
            <button v-if="instagram !== ''" class="btn btn-icon btn-round btn-instagram" :href="instagram">
              <i class="fa fa-instagram"></i>
            </button>
          </div>
        </template>

        <Form @submit="contactUs" :validation-schema="schema">
          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <vee-input
                  name="name"
                  type="text"
                  :placeholder="$t('contacts.name')"
                  addon-left-icon="now-ui-icons users_single-02"
          />

          <vee-input
                  name="email"
                  type="text"
                  placeholder="Email..."
                  addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <Field
                  name="subject"
                  type="select"
                  v-slot="{ value, errorMessage, handleChange }"
          >
            <el-select
                    class="d-block"
                    size="large"
                    name="subject"
                    :placeholder="$t('contacts.subject')"
                    :model-value="value" @update:model-value="handleChange"
                    v-model="subject">

              <el-option
                      v-for="option in subject_options"
                      :value="option"
                      :label="$t('contacts.' + option)"
                      :key="option">
              </el-option>
            </el-select>
            <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
              <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
              <div v-else>{{ $t(errorMessage) }}</div>
            </div>
          </Field>


          <Field
                  name="message"
                  type="text"
                  v-slot="{ value, field, errorMessage }">
            <el-input
                    v-bind="field"
                    :model-value="value"
                    name="message"
                    maxlength="1000"
                    :placeholder="$t('contacts.message')"
                    show-word-limit
                    type="textarea"
                    rows="5"
                    v-model="message">
            </el-input>
            <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
              <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
              <div v-else>{{ $t(errorMessage) }}</div>
            </div>
          </Field>

          <vee-checkbox class="text-left" name="privacy">
            <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('registration.accept_check') }}
<!--              <router-link to="/privacy_policy/" target="_blank">Privacy Policy</router-link>.-->
              <a :href="privacy_policy_url" target="_blank">Privacy Policy</a>.
            </p>
          </vee-checkbox>



          <n-button type="primary" native-type="submit" round :disabled="buttonDisabled" class="mt-4">
            {{ $t('contact_us') }}
          </n-button>

          <div v-if="saving" class="col-12 mt-2"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>

        </Form>
      </vnud-card>
    </div>

  </div>

</template>

<script>
  import { VeeCheckbox, VeeInput } from "src/components";
  import { ElSelect, ElOption, ElInput } from "element-plus";
  import { Form, Field } from "vee-validate";
  import * as Yup from "yup";
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {
    components: {
      VeeCheckbox,
      VeeInput,
      Form,
      Field,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElInput.name]: ElInput,
    },
    data() {
      return {
        error: '',
        buttonDisabled: false,
        subject_options: ["general_info", "bug_report", "marketing", "help", "other"],
        saving: false,
        phone: process.env.VUE_APP_COMPANY_PHONE,
        company_name: process.env.VUE_APP_COMPANY_NAME,
        company_type: process.env.VUE_APP_COMPANY_TYPE,
        company_number: process.env.VUE_APP_COMPANY_NUMBER,
        company_address: process.env.VUE_APP_COMPANY_ADDRESS,
        company_country: process.env.VUE_APP_COMPANY_COUNTRY,
        twitter: process.env.VUE_APP_SOCIAL_TWITTER,
        facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
        instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
        telegram: process.env.VUE_APP_SOCIAL_TELEGRAM,
        subject: "",
        message: "",
        privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
      }
    },
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('contact');
      },
      async contactUs(values, actions) {
        // actions.setFieldValue('message', this.message);
        this.error = '';
        this.buttonDisabled = true;

        values.captchaToken = await this.recaptcha();

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'contact_us/', values)
        .then(response => {
          actions.resetForm({});
            Swal.fire({
                icon: 'success',
                title: this.$i18n.t("registration.email_sent"),
                text: this.$i18n.t("contacts.request_received"),
                confirmButtonClass: "btn btn-info",
                buttonsStyling: false
            });

        }).catch(error => {
          if (error.response && error.response.data && error.response.data.violations &&
                  error.response.data.violations.length > 0) {
            for (let i = 0; i < error.response.data.violations.length; i++)
            {
              let stringErr = error.response.data.violations[i].message;
              if(stringErr.endsWith("."))
                stringErr = stringErr.slice(0, -1);
              this.error += this.$i18n.t("error." + stringErr) + ". ";
            }
          } else if(error.response && error.response.data && error.response.data.message)
          {
            let stringErr = error.response.data.message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error = this.$i18n.t("error." + stringErr);
          }
          else this.error = this.$i18n.t("error.try_again");
          console.log(error);
        }).finally(() => {this.buttonDisabled = false; this.saving = false;});

      },
    },
    setup() {
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        name: Yup.string().required(),
        subject: Yup.string().required(),
        message: Yup.string().required().min(50).max(1000),
        privacy: Yup.boolean().oneOf([true], "yup.checkbox_required"),
      });

      return {
        schema,
      };
    },
  }
</script>
<style>
</style>

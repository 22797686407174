<template>
  <div class="row">
    <div class="col-lg-6 col-md-8 col-12 ml-auto mr-auto">

      <vnud-card centered class="text-center p-5">
        <template v-slot:header>
          <h4 class="card-title">{{ $t('registration.email_verification') }}</h4>
        </template>

        <div v-if="loading">
          <p class="title" style="color: black;"><i class="fa fa-spinner fa-spin"></i> {{ $t("registration.check_progress") }} ...</p>
        </div>
        <div v-else-if="verified">
          <i class="fa fa-check text-success fa-3x"></i><br>
          <div v-html="$t('registration.email_verified', {company: product_name})"></div>
          <br><br>

          <div class="row">
            <div class="col-12 text-center">
              <p>{{ $t('common.redirect_in') }} .. {{timeout}}</p>
            </div>
          </div>

<!--          <router-link to="/login">-->
<!--            <n-button type="success" round size="lg">{{ $t('login.login') }}</n-button>-->
<!--          </router-link>-->
        </div>
        <div v-else>{{ $t('registration.err_email_verification') }}</div>
      </vnud-card>
    </div>
  </div>
</template>

<script>

  import axios from "axios";

  export default {
    components: {
    },
    methods: {
      startTimeout() {
        setTimeout(() => {
          this.timeout --;
          if(this.timeout > 0)
            this.startTimeout();
          else
            this.$router.push("/complete_registration/form")
        }, 1000);
      }
    },
    data() {
      return {
        loading: true,
        verified: false,
        error: false,
        timeout: 3,
        product_name: process.env.VUE_APP_PRODUCT_NAME,
      }
    },
    beforeMount() {
      let fetchedId = this.$route.params.userId;
      let fetchedRegistrationString = this.$route.params.registrationString;

      let config = {
        method: 'get',
        url: process.env.VUE_APP_SERVER_BASE_URL + 'confirm_registration/' + fetchedId + "/" + fetchedRegistrationString,
        withCredentials: false
      };

      axios(config).then(response => {
        this.loading = false;
        this.verified = true;
        this.startTimeout();

      }).catch(error => {
        this.loading = false;
        this.error = true;
        console.log(error);
      });
    }
  }
</script>
<style>
</style>


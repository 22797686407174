<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                <p v-if="userData" class="m-1 text-center font-weight-bold">
                  <span v-if="userData.company">{{ userData.company.name }}</span>
                  <span v-else>
                    {{userData.email}} <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <h4 class="title">{{ $tc('royalty.royalty', 2) }}</h4>
                <p class="text-justify">Deadline for customer to compile the royalties table: <b v-if="royaltiesDeadline !== null">{{ formatDate(royaltiesDeadline, false) }}</b>.</p>
                <p v-if="chData">{{ $t('document.financial_statement_dates') }} <b>{{ formatDate(balanceStart, false) }} - {{ formatDate(balanceEnd, false) }}</b></p>
                <p v-if="loading" class="m-1 text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{$t('common.loading')}}...</p>
              </div>
            </div>


<!--            <div class="row">-->
<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--                <label class="mb-2">{{ $t('common.date') }}:</label>-->
<!--                <br>-->
<!--                <el-date-picker-->
<!--                        v-model="date"-->
<!--                        type="date"-->
<!--                        format="DD/MM/YYYY"-->
<!--                        value-format="DD/MM/YYYY"-->
<!--                        :disabled-date="disabledDate">-->
<!--                </el-date-picker>-->
<!--              </div>-->


<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--                <label class="mb-2">{{ $t('common.amount') }}:</label>-->
<!--                <br>-->
<!--                <el-input-->
<!--                        v-model="amount"-->
<!--                        name="amount"-->
<!--                        type="number">-->
<!--                </el-input>-->
<!--              </div>-->

<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--                <label>{{ $t('royalty.select_currency') }}:</label>-->
<!--                <el-select class="select-default" v-model="currency" value-key="currencyCode" filterable>-->
<!--                  <el-option v-for="c in listCurrencies" v-bind:key="c"-->
<!--                             class="select-default"-->
<!--                             :label="c.currencySymbol + ' - ' + c.currencyCode"-->
<!--                             :value="c"></el-option>-->
<!--                </el-select>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="row">-->
<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--                <label class="mb-2">{{ $t('royalty.beneficiary_name') }}:</label>-->
<!--                <br>-->
<!--                <el-input-->
<!--                        v-model="beneficiaryName"-->
<!--                        name="beneficiaryName"-->
<!--                        type="text">-->
<!--                </el-input>-->
<!--              </div>-->

<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--                <label>{{ $t('royalty.select_country') }}:</label>-->
<!--                <el-select class="select-default" v-model="beneficiaryCountry" filterable>-->
<!--                  <el-option v-for="c in listCountries" v-bind:key="c"-->
<!--                             class="select-default"-->
<!--                             :label="$t('countries.' + c)"-->
<!--                             :value="c"></el-option>-->
<!--                </el-select>-->
<!--              </div>-->

<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto pt-3">-->
<!--                <n-button native-type="button" type="primary" class="d-block px-2" v-on:click="addToTable">-->
<!--                  {{ $t('royalty.add_to_table') }}-->
<!--                </n-button>-->
<!--              </div>-->


<!--              <div v-if="saving" class="col-12 text-center mt-3">-->
<!--                <p class="text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{$t('common.saving')}}...</p>-->
<!--              </div>-->

<!--              <div class="col-12 text-center mt-3" v-if="form_error">-->
<!--                <p class="text-center text-danger"> {{ form_error }}</p>-->
<!--              </div>-->

<!--            </div>-->

            <div class="row mt-5">
              <div class="col-12 text-center">

                <el-table class="table-striped" :data="conversions" border style="width: 100%">

                  <el-table-column key="royaltyDate" :min-width="80" prop="royaltyDate" :label="$t('common.date')">
                    <template #default="scope">
                      {{ formatDate(scope.row.royaltyDate, false) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="amount" :min-width="120" prop="amount" :label="$t('common.amount')">
                    <template #default="scope">
                      {{ scope.row.amount }} {{ scope.row.hmrcRateRef.currencyCode }}
                    </template>
                  </el-table-column>
                  <el-table-column key="rate" :min-width="120" prop="rate" :label="$t('royalty.rate')">
                    <template #default="scope">
                      {{ scope.row.hmrcRateRef.rate }}
                    </template>
                  </el-table-column>
                  <el-table-column key="amountInPounds" :min-width="120" prop="amountInPounds" :label="$t('royalty.poundsAmount')">
                    <template #default="scope">
                      {{ scope.row.amountInPounds }} £
                    </template>
                  </el-table-column>
                  <el-table-column key="beneficiaryName" :min-width="120" prop="beneficiaryName" :label="$t('royalty.beneficiary')">
                    <template #default="scope">
                      {{ scope.row.beneficiaryName }} - {{ scope.row.beneficiaryCountry }}
                    </template>
                  </el-table-column>
<!--                  <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">-->
<!--                    <template #default="scope">-->
<!--                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon"-->
<!--                                :disabled="loading || saving"-->
<!--                                v-on:click="deleteRoyalty(scope.$index)">-->
<!--                        <font-awesome-icon icon="trash"></font-awesome-icon>-->
<!--                      </n-button>-->
<!--                    </template>-->
<!--                  </el-table-column>-->
                </el-table>

              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-right">
                <p class="font-weight-bold">{{ $t('royalty.sum') }}: {{ sumConversions }} £</p>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-sm-6 m-auto text-center pt-3">
                <n-button native-type="button" type="primary" class="d-block m-auto px-2"
                          v-on:click="exportTable"
                          :disabled="conversions.length === 0">
                  {{ $t('royalty.csv_export') }}
                </n-button>
              </div>


              <div class="col-12 col-sm-6 m-auto text-center pt-3">
                <n-button native-type="button" type="danger" class="d-block m-auto px-2"
                          v-on:click="clearTable"
                          :disabled="conversions.length === 0">
                  Clear table
                </n-button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElDatePicker, ElInput, ElSelect, ElOption, ElTable, ElTableColumn } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "CompanyRoyalties",
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElInput.name]: ElInput,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
    },
    data () {
      return {
        loading: false,
        saving: false,
        error: null,
        form_error: null,
        conversions: [],
        date: null,
        amount: null,
        currency: null,
        listCurrencies: [{currencyCode: "EUR", currencySymbol: "€"},
            {currencyCode: "USD", currencySymbol: "$"}],
        beneficiaryName: null,
        beneficiaryCountry: null,
        listCountries: ["italy", "spain", "uk"],
        chData: null,
        balanceStart: null,
        balanceEnd: null,
        userData: null,
        royaltiesDeadline: null,
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            return time.getTime() > this.endDate;
        },
        // addToTable() {
        //     this.form_error = null;
        //     if (this.date === null) {
        //         this.form_error = this.$t("royalty.date_required");
        //         return;
        //     }
        //     if (this.amount === null) {
        //         this.form_error = this.$t("royalty.amount_required");
        //         return;
        //     }
        //
        //     if (this.currency === null) {
        //         this.form_error = this.$t("royalty.currency_required");
        //         return;
        //     }
        //
        //     if (this.beneficiaryName === null) {
        //         this.form_error = this.$t("royalty.beneficiary_name_required");
        //         return;
        //     }
        //
        //     if (this.beneficiaryCountry === null) {
        //          this.form_error = this.$t("royalty.beneficiary_country_required");
        //         return;
        //     }
        //
        //     this.saving = true;
        //     axios.post(process.env.VUE_APP_SERVER_BASE_URL + "royalties",
        //         { royaltyDate: new Date(this.date).toDateString(),
        //           amount: parseFloat(this.amount),
        //           currencyCode: this.currency.currencyCode,
        //           beneficiaryName: this.beneficiaryName,
        //           beneficiaryCountry: this.beneficiaryCountry})
        //     .then(response => {
        //         this.conversions.push(response.data);
        //         this.date = null;
        //         this.amount = null;
        //         this.currency = null;
        //         this.beneficiaryName = null;
        //         this.beneficiaryCountry = null;
        //     }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        // },
        // deleteRoyalty(index) {
        //     Swal.fire({
        //         title: this.$t("are_you_sure"),
        //         text: this.$t("royalty.delete_message") + this.formatDate(this.conversions[index].royaltyDate, false) + ". " + this.$t("common.proceed") + "?",
        //         confirmButtonClass: "btn btn-primary",
        //         showCancelButton: true,
        //         confirmButtonText: this.$t("common.yes_proceed"),
        //         confirmButtonColor: "#FF3636",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.showLoading();
        //             axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'royalties/' + this.conversions[index].id)
        //             .then(response => {
        //                 ElMessage.success(this.$t("royalty.removed"));
        //                 this.conversions.splice(index, 1);
        //             }).catch(error => {this.showError(error);}).finally(() => {Swal.hideLoading();});
        //         }
        //     });
        // },
        exportTable() {
            let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            csvContent += ["Date;Amount;Currency;Rate;Amount in Pounds;Beneficiary;Beneficiary Country;\n"];

            for (let row of this.conversions)
            {
                let date = this.formatDate(row.royaltyDate, true);
                let amount = row.amount;
                let currency = row.hmrcRateRef.currencyCode;
                let rate = row.hmrcRateRef.rate;
                let amountInPounds = row.amountInPounds;
                let beneficiaryName = row.beneficiaryName;
                let beneficiaryCountry = row.beneficiaryCountry;
                csvContent += [date + ";" + amount + ";" + currency + ";" + rate + ";" + amountInPounds + ";" +
                              beneficiaryName + ";" + beneficiaryCountry + ";\n"];
            }

            csvContent += ["\n\n;;;Total:;" + this.sumConversions + ";\n"];

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "royalties.csv");
            link.click();
        },
        clearTable() {
            Swal.fire({
                icon: "question",
                title: this.$t("are_you_sure"),
                text: "You are going to clear the table of the royalties. Proceed?",
                confirmButtonClass: "btn btn-primary",
                showCancelButton: true,
                confirmButtonText: this.$t("common.yes_proceed"),
                confirmButtonColor: "#FF3636",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'royalties/archive/?customerId=' + this.userData.id)
                    .then(response => {
                        ElMessage.success(this.$t("royalty.removed"));
                        this.conversions = [];
                    }).catch(error => {this.showError(error);}).finally(() => {Swal.hideLoading();});
                }
            });

        }
    },
    computed: {
        sumConversions() {
            let sum = 0;
            for (let item of this.conversions)
                sum += item.amountInPounds;
            return sum;
        }
    },
    beforeMount() {
        let fetchedId = this.$route.params.customer_id;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
        .then(response => {
            this.userData = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "royalties?userRef.id=" + fetchedId)
        .then(response => {
            this.conversions = response.data["hydra:member"];
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});


        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_house/filter_by_customer/?customerId=' + fetchedId)
        .then(response => {
            this.chData = response.data;
            if (response.data.accountLastMadeUpTo) {
                this.balanceStart = response.data.accountLastMadeUpTo;
                if (!response.data.accountNextMadeUpTo) {
                    let d = (new Date(response.data.accountLastMadeUpTo));
                    d = d.setFullYear(d.getFullYear() + 1);
                    this.balanceEnd = d;
                }
            }

            if (response.data.accountNextMadeUpTo) {
                this.balanceEnd = response.data.accountNextMadeUpTo;
                if (this.balanceStart === null) {
                    let d = (new Date(response.data.accountNextMadeUpTo));
                    d = d.setFullYear(d.getFullYear() - 1);
                    this.balanceStart = d;
                }
            }

            if (this.balanceEnd !== null) {
                this.royaltiesDeadline = new Date(this.balanceEnd);
                this.royaltiesDeadline.setMonth(this.royaltiesDeadline.getMonth() -1);
                this.royaltiesDeadline.setDate(this.royaltiesDeadline.getDate() - 7);
            }

        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>

<template>
  <div>

    <div class="row">
      <div class="col-12 mt-3">
        <el-upload action="" accept=".pdf,.csv,image/*"
                   drag
                   ref="uploadComp"
                   :auto-upload="false"
                   :multiple="true"
                   :show-file-list="true"
                   :limit="maxUpload"
                   :on-exceed="handleExceed"
                   :on-change="prepareDocument"
                   :on-remove="removeDocument"
        >
          <div class="el-upload__text">
            <i class="now-ui-icons arrows-1_share-66 text-center my-3 align-middle mr-2" style="font-size: 26px"></i>
            {{ $t('document.drop_file') }} <em>{{ $t('document.click_upload') }}</em>
            <br>{{ $t('document.accepted') }}: pdf, csv, images
          </div>
        </el-upload>
      </div>

      <div class="col-12 mt-3">
        <label>{{ $t('document.notes') }}:</label>
        <el-input
                v-model="form.notes"
                name="notes"
                maxlength="500"
                show-word-limit
                rows="3"
                type="textarea">
        </el-input>
      </div>

      <div class="col-12 mt-3" v-if="categories && categories.length > 0">
        <label>{{ $t('document.category') }}:</label>
        <el-select class="select-default d-block" v-model="form.category" label="" value="" clearable :placeholder="$t('common.select')">
          <el-option v-for="cat in categories" v-bind:key="cat.id" class="select-default" :value="cat.id" :label="$t('categories.name.' + cat.code)">
            {{ $t('categories.name.' + cat.code) }}
          </el-option>
        </el-select>
      </div>

      <div class="col-12 mt-3 text-center">
        <n-button class="btn btn-primary m-auto" :disabled="loading || saving" v-on:click="uploadDocument">
          {{ $t('document.add_document') }}
        </n-button>
      </div>

      <div class="col-12 mt-3 text-center" v-if="error || saving">
        <p v-if="saving" class="text-danger">{{ $t("common.saving") }}...</p>
        <p v-if="error" class="text-danger">{{ error }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElUpload, ElButton, ElPagination } from "element-plus";
  import NButton from "src/components/Button";

  export default {
    name: "UploadInFolder",
    components: {
      NButton,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElInput.name]: ElInput,
      [ElDatePicker.name]: ElDatePicker,
      [ElUpload.name]: ElUpload,
      [ElButton.name]: ElButton,
      [ElPagination.name]: ElPagination
    },
    data () {
      return {
        loading: false,
        saving: false,
        error: null,
        form: {fileList: [], notes: '', category: ''},
        maxUpload: 50,
        listErrors: [],
        numFilesUploaded: 0,
      }
    },
    props: {
        folder: {
            type: String,
            default: "bankStatement",
        },
        categories: {
            type: Array
        }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message) {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true) {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
      prepareDocument(file) {
        // this.form.file = new Blob([file.raw], {type: file.type});
        // this.form.fileName = file.name;
          let f = new Blob([file.raw], {type: file.type});
          let fileName = file.name;
          this.form.fileList.push({file: f, fileName: fileName});
      },
      removeDocument(file, uploadFiles) {
          let list = [];
          for (let obj of uploadFiles) {
              let f = new Blob([obj.raw], {type: obj.type});
              let fileName = obj.name;
              list.push({file: f, fileName: fileName});
          }
          this.form.fileList = list;
      },
      handleExceed(files, uploadFiles) {
          ElMessage.warning(
              `The limit is ${this.maxUpload}, you selected ${files.length} files`
          )
      },
      uploadDocument() {
          this.saving = true;
          this.error = null;
          if (this.form.fileList.length === 0) {
              this.error = "Please add at least one file.";
              this.saving = false;
              return;
          }
          this.numFilesUploaded = this.form.fileList.length;
          for (let i=0; i<this.form.fileList.length; i++) {
            let data = new FormData();
            data.append('file', this.form.fileList[i].file, this.form.fileList[i].fileName);
            data.append('notes', this.form.notes);
            if(this.form.category !== "")
                data.append('category', this.form.category);
            data.append('folder', this.folder);

            axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents', data)
            .then(response => {}).catch(error => {
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  this.$store.commit('logout');
                  this.$router.push('/login');
              }
              else {
                  if (error.response && error.response.data && error.response.data["hydra:description"])
                      this.listErrors.push({message: this.form.fileList[i].fileName + ": " + this.$t("error." + error.response.data["hydra:description"]), indexFile: i});
                  else
                      this.listErrors.push({message: this.form.fileList[i].fileName + ": " + error, indexFile: i});
              }
            }).finally(() => {this.endOfSave();});
          }
        },
        endOfSave() {
          if(this.numFilesUploaded === this.form.fileList.length) {
              let newFileList = [];
              for (let i=0; i<this.listErrors.length; i++)
              {
                  let f = this.form.fileList[this.listErrors[i].indexFile].file;
                  let fileName = this.form.fileList[this.listErrors[i].indexFile].fileName;
                  newFileList.push({file: f, fileName: fileName});
              }
              this.form.fileList = newFileList;

              if (this.form.fileList.length === 0) {
                  ElMessage.success(this.$t("document.upload_success"));
                  this.form.notes = '';
              }
              else {
                  let error = {response: {data: {violations: this.listErrors}}};
                  this.showError(error);
              }
              this.saving = false;
              this.numFilesUploaded = 0;
              this.$refs.uploadComp.clearFiles();
              this.$emit('upload-completed');
          }
        },
    },
    computed: {
    },
    beforeMount() {
    },
  }
</script>

<style>
  .el-upload-dragger {
    width: 100% !important;
    height: 100px;
  }
  .el-upload{
    width: 100% !important;
  }
</style>

<template>
  <div>

    <div class="row">
      <div class="col-xl-4 col-sm-6 m-auto">
        <stats-card type="success"
                    icon="fa fa-check"
                    :subTitle="'active'"
                    :title="numActiveSubs.toString()">

          <template v-slot:footer>
            <p class="text-center">
              <i class="now-ui-icons arrows-1_refresh-69"></i> Total number of active subscriptions
            </p>
          </template>
        </stats-card>
      </div>

      <div class="col-xl-4 col-sm-6 m-auto">
          <stats-card type="danger"
                      icon="now-ui-icons ui-1_simple-remove"
                      :subTitle="'Customers not paying'"
                      :title="numFailedOrPendingSubs.toString()">

              <template v-slot:footer>
                  <p class="text-center">
                      <i class="now-ui-icons arrows-1_refresh-69"></i> Total number of customers with failed payments
                  </p>
              </template>
          </stats-card>
      </div>

      <div class="col-xl-4 col-sm-6 m-auto">
        <stats-card type="primary"
                    icon="fa fa-hourglass-half"
                    :subTitle="'Pending Renewals'"
                    :title="numUnpaidInvoices.toString()">

          <template v-slot:footer>
            <p class="text-center">
              <i class="now-ui-icons arrows-1_refresh-69"></i> Total number of pending or failed renewals of subscriptions
            </p>
          </template>
        </stats-card>
      </div>

    </div>

    <div class="row">
      <div class="col-12 m-auto">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-12 col-lg-6">
                <h4 class="title">List Failed or Pending renewals</h4>
              </div>
              <div class="col-12 col-lg-6 mt-2">
                <label class="mt-3">Filter by customer email (at least 3 chars):</label>
                <el-input
                        v-model="searchCustomer"
                        type="text"
                        name="searchCustomer"
                        v-on:change="searchCustomerInvoices"
                        v-on:clear="changeTableSubs"
                        clearable
                        :disabled="subsLoading"
                        placeholder="Press Enter to search, click on 'x' to cancel">
                  <template #prepend><i class="el-icon-search"></i></template>
                </el-input>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <el-table class="table-striped" :data="unpaidInvoices" border style="width: 100%"
                          :header-cell-style="{'font-size': '14px', 'font-weight': 'bold'}">

                    <el-table-column key="createdAt" :min-width="110" prop="createdAt" label="Date">
                        <template #default="scope">
                          <span class="text-break">{{ formatDate(scope.row.createdAt.date, true) }}</span>
                        </template>
                    </el-table-column>

                  <el-table-column key="email" :min-width="140" prop="email" label="Email"></el-table-column>
                  <el-table-column key="name" :min-width="100" prop="name" label="Name">
                      <template #default="scope">
                          <span v-if="scope.row.name !== null && scope.row.name !== ''" class="text-break">
                              {{ scope.row.name }}
                          </span>
                          <span v-else class="text-break">{{ scope.row.firstName }}</span>
                      </template>
                  </el-table-column>

                  <el-table-column key="total" :min-width="100" prop="total" label="Amount">
                      <template #default="scope">
                        {{ formatPrice(scope.row.total) }}
                      </template>
                  </el-table-column>

                  <el-table-column key="status" :min-width="90" prop="status" label="Status">
                    <template #default="scope">
                      <span class="text-break">{{ $t("subh_status." + scope.row.status) }}</span>
                      <span class="text-break" v-if="scope.row.paidOutsideStripeNote"> - {{ scope.row.paidOutsideStripeNote }}</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="attemptDate" :min-width="110" prop="manualAttemptDate" label="Manual Attempt" class-name="text-center">
                    <template #default="scope">
                      <span v-if="scope.row.manualAttemptNumber === 0">-</span>
                      <span v-else class="text-break">#{{scope.row.manualAttemptNumber}} at {{ formatDate(scope.row.manualAttemptDate.date, true) }}
                        <br>{{scope.row.manualAttemptResponse}}
                      </span>
                    </template>
                  </el-table-column>

                    <el-table-column key="actions" width="170" prop="stripeId" label="Actions">
                      <template #default="scope">

                        <el-select class="select-default select-" placeholder="Actions ..." model-value="" size="small" :disabled="loading">

                          <el-option class="select-default" key="1" label="" value="" @click="newAttempt(scope.row.stripeId, scope.$index)"
                               :disabled="loading || saving ||
                                  scope.row.status === 'invoice_cancelled' || scope.row.status === 'paid_outside_stripe' ||
                                  (scope.row.manualAttemptDate && (new Date(scope.row.manualAttemptDate.date)) >= minAgo30)">
                              <p class="text-primary"> <i class="now-ui-icons business_money-coins" style="vertical-align: middle"></i> Pay now</p>
                          </el-option>

                          <el-option class="select-default" key="3" label="" value="" @click="voidInvoice(scope.row, scope.$index)"
                                     :disabled="loading || saving || scope.row.status === 'invoice_cancelled' || scope.row.status === 'paid_outside_stripe'">
                            <p class="text-primary"> <font-awesome-icon icon="trash"></font-awesome-icon> Cancel payment</p>
                          </el-option>

                          <el-option class="select-default" key="3" label="" value=""
                                     @click="cancelSubscription(scope.row.id, scope.row.email, scope.row.shStripeId)"
                                     :disabled="loading || saving || scope.row.status === 'invoice_cancelled' || scope.row.status === 'paid_outside_stripe'">
                            <p class="text-primary"> <i class="now-ui-icons ui-1_simple-remove" style="vertical-align: middle"></i> Cancel subscription</p>
                          </el-option>

                          <el-option class="select-default" key="3" label="" value=""
                                     @click="markAsPaidOutsideStripe(scope.row, scope.$index)"
                                     :disabled="loading || saving || scope.row.status === 'invoice_cancelled' || scope.row.status === 'paid_outside_stripe'">
                            <p class="text-primary"> <i class="now-ui-icons location_compass-05"></i> Paid Outside Stripe</p>
                          </el-option>

                          <el-option class="select-default" key="2" label="" value="">
                            <router-link :to="'/manager/sub_history/' + scope.row.id" target="_blank">
                              <p> <font-awesome-icon icon="history"></font-awesome-icon> Subscription History</p>
                            </router-link>
                          </el-option>

                        </el-select>

                      </template>
                    </el-table-column>

                  <template #empty="">
                      <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                      <span v-else>{{ $t('common.no_data') }}</span>
                  </template>
                </el-table>
                  <div class="text-right"><p class="m-1">{{unpaidInvoices.length}}/{{numUnpaidInvoices}}</p></div>
                  <div v-if="saving"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</div>
              </div>
            </div>


            <div class="row mt-3">
                <div class="col-4">
                    <label>Per Page:</label><br>
                    <el-select class="select-default" v-model="subsLimit" value=""  label="">
                        <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                        <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                        <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                        <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                </div>
                <div class="col-4">
                  <label>Page (Max {{subsMaxPage}}):</label>
                  <el-input type="number" name="page" v-model="subsPage"></el-input>

                </div>
                <div class="col-4 text-center">
                    <n-button native-type="button" type="primary" class="btn btn-sm mt-4 d-inline"
                              :disabled="subsLoading || (subsMaxPage === 1 && unpaidInvoices.length === numUnpaidInvoices)"
                              @click="changeTableSubs()">{{ $t('common.change') }}</n-button>
                </div>
            </div>

            <div class="row">
              <div class="col-12">
                <p>Legenda: </p>

                <p><i class="now-ui-icons business_money-coins text-primary" style="font-size: 16px; vertical-align: middle"></i> Pay now: Attempt a new charge to the customer for a specific payment</p>

                <p><font-awesome-icon icon="trash" class="text-primary" style="font-size: 16px;"></font-awesome-icon> Cancel payment: Cancel a specific payment. No further attempt will be made for this payment. The subscription will stay active.</p>

                <p><i class="now-ui-icons ui-1_simple-remove text-primary" style="font-size: 16px; vertical-align: middle"></i> Cancel subscription: Cancel the subscription and all the pending payments. No further attempt will be made for any of the pending payments.</p>

                <p><font-awesome-icon icon="history" class="text-primary" style="font-size: 16px;"></font-awesome-icon> Subscription History: Show the subscription history of a customer</p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import axios from 'axios';
  import  { StatsCard } from "src/components";
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ManageFailedSubscriptions",
    components: {
      StatsCard,
      [ElSelect.name]: ElSelect,
      [ElInput.name]: ElInput,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        numActiveSubs: 0,
        numFailedOrPendingSubs: 0,
        numUnpaidInvoices: 0,
        unpaidInvoices: [],
        subsLimit: 10,
        subsOffset: 0,
        subsPage: 1,
        subsLoading: false,
        minAgo30: (new Date).setMinutes((new Date).getMinutes() - 30),
        searchCustomer: null,
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else if (error.message)
                this.error = error.message;
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
            return dateObj.toLocaleDateString();
        },
        formatPrice(price) {
            return "€ " + (price/100).toFixed(2);
        },
        searchCustomerInvoices() {
            if(this.searchCustomer && this.searchCustomer.length > 2)
                this.changeTableSubs();
        },
        changeTableSubs() {
            this.subsLoading = true;

            if (this.subsPage < 1)
                this.subsPage = 1;

            if (this.subsPage > this.subsMaxPage)
                this.subsPage = this.subsMaxPage;

            this.subsOffset = this.subsLimit * (this.subsPage - 1);

            let url = process.env.VUE_APP_SERVER_BASE_URL + 'manager/subscriptions_info/' + this.subsOffset + "/" + this.subsLimit;
            if(this.searchCustomer !== null && this.searchCustomer !== '')
                url += "/" + this.searchCustomer;

            // Necessary to let "refresh" the select components.
            this.unpaidInvoices = [];

            axios.get(url)
                .then(response => {
                    this.unpaidInvoices = response.data['unpaid_invoices'];
                    this.numActiveSubs = response.data['num_active_subs'];
                    this.numFailedOrPendingSubs = response.data['num_not_active_subs'];
                    this.numUnpaidInvoices = response.data['num_unpaid_invoices'];
                    this.subsLoading = false;
                }).catch(error => {
                this.subsLoading = false;
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                } else {
                    console.log(error);
                }
            });
        },
        newAttempt(invoiceStripeId, rowIndex) {
            this.saving = true;
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/stripe_new_payment_attempt/' + invoiceStripeId)
                .then(response => {
                    if(response.data.invoice_data)
                    {
                        this.unpaidInvoices[rowIndex]["manualAttemptDate"] = response.data.invoice_data.manualAttemptDate;
                        this.unpaidInvoices[rowIndex]["manualAttemptNumber"] = response.data.invoice_data.manualAttemptNumber;
                        this.unpaidInvoices[rowIndex]["manualAttemptResponse"] = response.data.invoice_data.manualAttemptResponse;
                    }
                }).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response.data.message === "stripe_error") {
                    console.log("stripe_error");
                    console.log(error);
                }
                else {
                    console.log(error);
                }
            }).finally(()=> {this.saving = false;});
        },
        voidInvoice(data, rowIndex) {
            let text = "You are cancelling the following payment:<br><br>";
            text += "Customer " + data.email + "<br>";
            text += this.formatDate(data.createdAt.date, false) + " - " + this.formatPrice(data.total);
            Swal.fire({
                title: "Cancel Invoice",
                html: text,
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#FF3636'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/stripe_void_invoice/' + data.stripeId)
                        .then(response => {
                            if (response.data.status === 'ok') {
                                ElMessage.success("Inovice successfully cancelled");
                                this.unpaidInvoices[rowIndex].status = 'invoice_cancelled';
                            }
                        }).catch(error => {this.showError(error);}).finally(() => {Swal.hideLoading();});
                }
            });
        },
        cancelSubscription(userId, email, shStripeId) {
            Swal.fire({
                title: this.$i18n.t("common.loading"),
                html: "<span></span>",
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#FF3636',
                didOpen: () => {
                    Swal.showLoading();
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/get_failed_payments/' + userId + "/" + shStripeId)
                    .then(response => {
                      Swal.hideLoading();
                      Swal.getTitle().textContent = 'Cancel Subscription';
                      let text = "The customer " + email + " has " + response.data.failed_payments.length +
                          " pending payments that will be cancelled too:<br><br>";
                      for(let i=0; i<response.data.failed_payments.length; i++)
                          text += this.formatDate(response.data.failed_payments[i].createdAt.date, false) + ": " +
                              this.formatPrice(response.data.failed_payments[i].total) + "<br>";

                      Swal.getHtmlContainer().querySelector('span').outerHTML = text;
                    }).catch(error => {this.showError(error);}).finally(() => {});
                },
                allowOutsideClick: () => false
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/stripe_cancel_subscription/' + userId + "/" + shStripeId)
                    .then(response => {
                        let text = "The subscription has been successfully cancelled.<br>";
                        if (response.data.void_failed.length > 0) {
                            text += "However, the following payments have not been cancelled for an error:<br>";
                            for (let i = 0; i < response.data.void_failed.length; i++)
                                text += this.formatDate(response.data.failed_payments[i].createdAt.date, false) + ": " +
                                    this.formatPrice(response.data.failed_payments[i].total) + "<br>";
                            text += "Please, cancel them manually."
                        } else
                            text += "All the failed payments have been cancelled too.";
                        Swal.hideLoading();

                        Swal.fire({
                            icon: 'success',
                            title: 'Subscription canceled',
                            html: text
                        });
                        this.changeTableSubs();
                    }).catch(error => {this.showError(error);}).finally(() => {});
                }
            });
        },
        markAsPaidOutsideStripe(data, rowIndex) {
            Swal.fire({
                title: "Mark as paid",
                html: "Customer: " + data.email + "<br>Amount: " + this.formatPrice(data.total) + "<br>Mark this payment as: paid outside Stripe",
                input: 'textarea',
                inputLabel: "Additional notes:",
                showCancelButton: true,
                confirmButtonText: 'Proceed',
                confirmButtonColor: '#FF3636'
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("res: " + result.value);
                    Swal.showLoading();

                    axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'manager/stripe_paid_externally/', {stripeId: data.stripeId, notes: result.value})
                    .then(response => {
                        if (response.data.status === 'ok') {
                            ElMessage.success("Invoice successfully mark as paid");
                            this.unpaidInvoices[rowIndex].status = 'paid_outside_stripe';
                            this.unpaidInvoices[rowIndex].paidOutsideStripeNote = result.value;
                        }
                        else if (response.data.status === 'error' && response.data.message === 'Voided invoices cannot be paid.') {
                            this.unpaidInvoices[rowIndex].status = 'invoice_cancelled';
                        }
                        else
                            this.showError(response.data);
                    }).catch(error => {this.showError(error);}).finally(() => {Swal.hideLoading();});
                }
            });
        }
    },
    computed: {
        subsMaxPage: function () {
            if (this.numUnpaidInvoices === 0)
                return 1;
            return Math.ceil(this.numUnpaidInvoices / this.subsLimit);
        }
    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/subscriptions_info')
      .then(response => {
        this.unpaidInvoices = response.data['unpaid_invoices'];
        this.numFailedOrPendingSubs = response.data['num_not_active_subs'];
        this.numUnpaidInvoices = response.data['num_unpaid_invoices'];
        this.numActiveSubs = response.data['num_active_subs'];
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style>
</style>

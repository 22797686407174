<template>

  <div class="row">

    <div class="col-xl-7 m-auto ">

      <vnud-card class="card-signup text-center mb-5 p-5">
        <template v-slot:header>
          <div v-if="loading">
            <h4 class="card-title text-center text-primary">
              <i class="fa fa-spin fa-spinner"></i> {{ $t("book_consultancy.checking") }}...</h4>
            <p class="my-4" style="font-size: large">{{ $t("book_consultancy.checking_message") }}</p>
          </div>
          <div v-else-if="error">
            <h4 class="card-title text-center text-primary">
              <i class="now-ui-icons ui-1_simple-remove align-middle"></i> {{ $t("book_consultancy.error") }}</h4>
            <p class="my-4" style="font-size: large">{{ error }}</p>
            <p class="my-4" style="font-size: large">{{ $t("book_consultancy.error_message") }}</p>
          </div>
          <div v-else>
            <h4 class="card-title text-center text-primary">
              <i class="now-ui-icons ui-1_check align-middle"></i> {{ $t("book_consultancy.payment_received") }}</h4>
            <p class="my-4" style="font-size: large">{{ $t("book_consultancy.payment_received_message") }}</p>

          </div>
        </template>


      </vnud-card>
    </div>
  </div>

</template>

<script>
  import axios from "axios";
  import { ElMessage } from "element-plus";

  export default {
    name: "BookConsultancySuccess",
    components: {
    },
    data() {
      return {
        error: null,
        buttonDisabled: false,
        loading: true,
      }
    },
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('book_consultancy');
      },
      showError(error) {
        if (error.response && error.response.data && error.response.data.violations &&
            error.response.data.violations.length > 0) {
            for (let i = 0; i < error.response.data.violations.length; i++)
            {
                let stringErr = error.response.data.violations[i].message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error += this.$i18n.t(stringErr) + ". ";
            }
        } else if(error.response && error.response.data && error.response.data.message)
        {
            let stringErr = error.response.data.message;
            if(stringErr.endsWith("."))
                stringErr = stringErr.slice(0, -1);
            this.error = this.$i18n.t("error." + stringErr);
        }
        else if (error.response && error.response.data && error.response.data['hydra:description'])
            this.error = this.$i18n.t("error." + error.response.data['hydra:description']);
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
    },
    async mounted() {
      let fetchedSessionId = this.$route.query.session_id;
      let captchaToken = await this.recaptcha();

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'consultancies/check_payment', {
          captchaToken: captchaToken,
          session_id: fetchedSessionId
      }).then(response => {
        if (response.data.status !== 'ok')
            this.error = response.data.message ? this.$i18n.t("error." + response.data.message) : this.$i18n.t("error.try_again");
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>
<style>
</style>

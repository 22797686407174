<template>
  <div>
    <Form ref="form" @submit="validate" :validation-schema="schema">
    <h5 class="info-text">
      {{ $t('company.shareholder_data') }}
    </h5>

    <div class="row">
      <div class="col-12">

        <collapse>
          <collapse-item v-for="(item, index) in shareholders" v-bind:key="index"
                         :set="indexSucc = index + 1"
                         :title="'Shareholder #' + indexSucc" :id="'' + index"
                         :active="index === 0">

            <div class="row">
              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].name'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.shareholder_name') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          :name="'holder_' + index + '_name'"
                          type="text"
                          v-model="shareholders[index].name">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].surname'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.shareholder_surname') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          :name="'holder_' + index + '_surname'"
                          type="text"
                          v-model="shareholders[index].surname">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].phone'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('contacts.phone_number') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          :name="'holder_' + index + '_phone'"
                          type="text"
                          v-model="shareholders[index].phone">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].email'"
                        type="email"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('contacts.email_address') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          :name="'holder_' + index + '_email'"
                          type="email"
                          v-model="shareholders[index].email">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].birth'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.date_birth') }}</label>
                  <el-date-picker
                          v-bind="field"
                          :model-value="value"
                          v-model="shareholders[index].birth"
                          type="date"
                          format="DD/MM/YYYY"
                          value-format="DD/MM/YYYY"
                          :disabled-date="disabledDate"
                          :default-value="new Date(yearsAgo16)">
                  </el-date-picker>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].placeBirth'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.place_birth') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          v-model="shareholders[index].placeBirth"
                          type="text">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].addressStreet'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.address') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          v-model="shareholders[index].addressStreet"
                          type="text">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].addressCity'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.city') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          v-model="shareholders[index].addressCity"
                          type="text">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>


              <div class="col-12 col-md-6 col-xl-4">
                <Field
                        :name="'holders[' + index + '].addressCountry'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.country') }}</label>
                  <el-input
                          v-bind="field"
                          :model-value="value"
                          v-model="shareholders[index].addressCountry"
                          type="text">
                  </el-input>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>


              <div class="col-12 col-md-6 col-xl-4 px-5 mt-4">
                <Field
                        :name="'holders[' + index + '].shareOwned'"
                        type="number"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.shareholder_share') }}</label>
                  <el-slider v-bind="field"
                             :model-value="value"
                             v-model="shareholders[index].shareOwned"
                             v-on:change="error = null"
                             show-input>
                  </el-slider>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>

              <div class="col-12 col-md-6 col-xl-4 mt-3">
                <Field
                        :name="'holders[' + index + '].isDirector'"
                        type="text"
                        v-slot="{ value, field, errorMessage }">
                  <label>{{ $t('company.shareholder_is_director') }}</label>
                  <br>
                  <el-select v-bind="field"
                             :model-value="value"
                             v-model="shareholders[index].isDirector">
                    <el-option value="false" :label="$t('common.no')" key="false"></el-option>
                    <el-option value="true" :label="$t('common.yes')" key="true"></el-option>
                  </el-select>
                  <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                    <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                    <div v-else>{{ $t(errorMessage) }}</div>
                  </div>
                </Field>
              </div>
            </div>
          </collapse-item>
        </collapse>

        <div v-if="error" class="text-danger text-center">{{ error }}</div>

      </div>

    </div>
    </Form>
  </div>
</template>
<script>

import { Collapse, CollapseItem } from "src/components";
import * as Yup from "yup";

import { ElInput, ElDatePicker, ElSlider, ElSelect, ElOption } from "element-plus";
import { Form, Field } from "vee-validate";

export default {
  components: {
    Collapse,
    CollapseItem,
    [ElInput.name]: ElInput,
    [ElDatePicker.name]: ElDatePicker,
    [ElSlider.name]: ElSlider,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    Form,
    Field
  },
  data() {

    const schema = Yup.object().shape({
      holders: Yup.array().of(
              Yup.object().shape({
                  name: Yup.string().required(),
                  surname: Yup.string().required(),
                  phone: Yup.string().required().min(10).max(14),
                  email: Yup.string().email().required(),
                  birth: Yup.date().typeError('yup.invalid_date_required').required(),
                  placeBirth: Yup.string().required(),
                  addressStreet: Yup.string().required(),
                  addressCity: Yup.string().required(),
                  addressCountry: Yup.string().required(),
                  shareOwned: Yup.number().required().min(1).max(100),
                  isDirector: Yup.string().default("false").oneOf(["true","false"]),
              })).strict()
    });

    return {
      schema,
      selectedTab: "0",
      shareholders: [],
      error: null,
      yearsAgo16: (new Date).setFullYear((new Date).getFullYear() - 16)
    };
  },
  props: ['numShareholders'],
  computed: {
    sumShares() {
      let sum = 0;
      for (let i=0; i<this.shareholders.length; i++)
      {
        let val = parseFloat(this.shareholders[i].shareOwned);
        if (!isNaN(val))
          sum += val;
      }
      return sum;
    }
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > this.yearsAgo16;
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
        if(this.sumShares !== 100)
        {
          this.error = this.$i18n.t('company.wrong_sum_shares', [this.sumShares]);
          return false;
        }
        this.$emit("on-validated", res);
        if(res.valid)
          this.$emit('on-new-data', {shareholders: this.shareholders});
        return res;
      });
    },
  },
  beforeUpdate() {
    let num = parseInt(this.numShareholders);
    if(this.shareholders.length === 0) {
      for (let i = 0; i < num; i++)
      {
        this.shareholders[i] = {name: "", surname: "", phone: null, email: "", birth: null, placeBirth: "",
          addressStreet: "", addressCity: "", addressCountry: "", shareOwned: 0, isDirector: "false"};
      }
    }
    else if(this.shareholders.length < num) {
      for(let i = this.shareholders.length; i<num; i++)
        this.shareholders[i] = {name: "", surname: "", phone: null, email: "", birth: null, placeBirth: "",
          addressStreet: "", addressCity: "", addressCountry: "", shareOwned: 0, isDirector: "false"};
    }
    else
      this.shareholders = this.shareholders.slice(0, num);



  }
};
</script>
<style></style>

<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Revenue Report</h4>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-md-4">
                <el-date-picker
                        v-model="firstDate"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                        :disabled-date="disabledDate"
                        class="mt-2">
                </el-date-picker>
              </div>

              <div class="col-md-4">
                <el-date-picker
                        v-model="secondDate"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                        :disabled-date="disabledDate"
                        class="mt-2">
                </el-date-picker>
              </div>

              <div class="col-md-4">
                <el-select v-model="subscription" class="d-block">
                  <el-option v-for="sub in listSubs" :key="sub.id" :value="sub.id" :label="sub.name"></el-option>
                </el-select>
              </div>

              <div class="col-12 m-auto text-center">
                <n-button native-type="button" type="primary" class="btn" @click="getReport" :disabled="loading || saving">Get Report</n-button>

                <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
              </div>



              <div class="col-12 mt-5">

                <h4 class="title text-center">Total Revenue: {{ formatPrice(revenue) }}</h4>
                <h4 class="text-center mt-0">(of which paid outside Stripe: {{ formatPrice(revenueOutsideStripe) }})</h4>
                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <n-button native-type="button" type="primary" class="btn" @click="csvExport"
                              :disabled="loading || saving || listInvoices.length === 0">Export Data</n-button>
                  </div>
                </div>

                <el-table class="table-striped" :data="listInvoices" border style="width: 100%">

                  <el-table-column key="Date" :min-width="80" prop="lastChangeOn" :label="$t('managers.stats.date')">
                    <template #default="scope">
                      {{ formatDate(scope.row.lastChangeOn.date, true) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="amount" :min-width="120" prop="total" class-name="text-center" label="Amount">
                    <template #default="scope">
                      {{ formatPrice(scope.row.total) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="paidOutsideStripeDate" :min-width="100" prop="paidOutsideStripeDate" class-name="text-center" label="Outside Stripe">
                    <template #default="scope">
                      <span v-if="scope.row.paidOutsideStripeDate !== null">
                        {{ formatDate(scope.row.paidOutsideStripeDate.date, false) }}
                        <span v-if="scope.row.paidOutsideStripeNotes"> - {{ scope.row.paidOutsideStripeNotes }}</span>
                      </span>
                    </template>
                  </el-table-column>

                  <el-table-column key="period" :min-width="80" prop="startAt" class-name="text-center" label="Period">
                    <template #default="scope">
                      {{ formatDate(scope.row.startAt.date, false) }} - {{ formatDate(scope.row.endAt.date, false) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="email" :min-width="120" prop="email" class-name="text-center" label="Customer"></el-table-column>

<!--                  <el-table-column key="actions" :min-width="80" class-name="text-center" :label="$t('common.actions')">-->
<!--                    <template #default="scope">-->

<!--                      <router-link :to="'/manager/handle_user/' + scope.row.id">-->
<!--                        <n-button native-type="button" type="primary" class="btn btn-sm btn-icon">-->
<!--                          <i class="now-ui-icons design-2_ruler-pencil"></i>-->
<!--                        </n-button>-->
<!--                      </router-link>-->

<!--                      <router-link :to="'/manager/handle_documents/' + scope.row.id">-->
<!--                        <n-button native-type="button" type="primary" class="btn btn-sm btn-icon ml-1">-->
<!--                          <i class="now-ui-icons files_box"></i>-->
<!--                        </n-button>-->
<!--                      </router-link>-->

<!--                    </template>-->
<!--                  </el-table-column>-->

                  <template #empty="">
                    <span>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
    import axios from 'axios';
    import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker } from "element-plus";


    export default {
        name: "RevenueReport",
        components: {
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElInput.name]: ElInput,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElDatePicker.name]: ElDatePicker,
        },
        data () {
            return {
                loading: false,
                saving: false,
                error: null,
                firstDate: (new Date).setDate((new Date).getDate() - 30),
                secondDate: new Date(),
                subscription: 1,
                listSubs: [],
                listInvoices: [],
                revenue: 0,
                revenueOutsideStripe: 0
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true)
            {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price)
            {
                return "€ " + (price/100).toFixed(2);
            },
            getReport() {
                this.loading = true;
                axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'manager/revenue_report',
                    {
                        firstDate: new Date(this.firstDate).toDateString(),
                        secondDate: new Date(this.secondDate).toDateString(),
                        subscription: this.subscription
                    })
                    .then(response => {
                        this.listInvoices = response.data.invoices;
                        this.revenue = response.data.revenue;
                        this.revenueOutsideStripe = response.data.revenueOutsideStripe;
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            csvExport() {

                let subName = "";
                for (let sub of this.listSubs)
                {
                    if(sub.id === this.subscription)
                        subName = sub.name;
                }

                let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
                csvContent += ["Start Date:;" + this.formatDate(this.firstDate, false) + ";\n"];
                csvContent += ["End Date:;" + this.formatDate(this.secondDate, false) + ";\n"];
                csvContent += ["Subscription:;" + subName + ";\n\n"];
                csvContent += ["Date;Amount;Outside Stripe;Subscription Period;Customer;\n"];

                for (let row of this.listInvoices)
                {
                    let date = this.formatDate(row.lastChangeOn.date, true);
                    let amount = this.formatPrice(row.total);
                    let outsideStripe = "";
                    if (row.paidOutsideStripeDate !== null) {
                        outsideStripe = this.formatDate(row.paidOutsideStripeDate.date, false);
                        if (row.paidOutsideStripeNotes !== null)
                            outsideStripe += " - " + row.paidOutsideStripeNotes;
                    }
                    let p1 = this.formatDate(row.startAt.date, false);
                    let p2 = this.formatDate(row.endAt.date, false);
                    let customer = row.email;

                    csvContent += [date + ";" + amount + ";" + outsideStripe + ";" + p1 + " - " + p2 + ";" + customer + ";\n"];
                }

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "export.csv");
                link.click();
            }
        },
        computed: {
        },
        beforeMount() {
            this.loading = true;
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions')
                .then(response => {
                    this.listSubs = response.data["hydra:member"];
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
    }
</script>

<style scoped>

</style>

<template>
  <Form ref="form" @submit="validate" :validation-schema="schema">
    <h5 class="info-text">
      {{ $t('company.basic_info') }}
    </h5>
    <div class="row justify-content-center">

      <div class="col-12 col-md-8 col-lg-6 m-auto">

        <Field
                name="companyName"
                type="text"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.company_name') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="companyName"
                  type="text"
                  v-model="form.companyName"
          >
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

      <div class="col-12 col-xl-10 my-4">
        <Field
                name="description"
                type="textarea"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.company_description') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="description"
                  maxlength="1000"
                  show-word-limit
                  rows="5"
                  type="textarea"
                  v-model="form.description">
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

      <div class="col-12 col-md-6 col-xl-4 m-auto">
        <Field
                name="numShareholders"
                type="number"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.company_shareholders') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="numShareholders"
                  type="number"
                  v-model="form.numShareholders">
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

      <div class="col-12 col-md-6 col-xl-4 m-auto">
        <Field
                name="shares"
                type="number"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.company_capital') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="shares"
                  type="number"
                  v-model="form.shares">
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

    </div>
  </Form>
</template>
<script>

import { Form, Field } from "vee-validate";
import { ElInput } from "element-plus";
import * as Yup from "yup";

export default {
  components: {
    Form,
    Field,
    [ElInput.name]: ElInput,
  },
  data() {
    const schema = Yup.object().shape({
      companyName: Yup.string().required(),
      numShareholders: Yup.number().typeError({key: "yup.invalid_number_required", values: {}})
              .positive().integer().max(20).required(),
      description: Yup.string().required().max(1000),
      shares: Yup.number().typeError({key: "yup.invalid_number_required", values: {}})
              .positive().integer().max(1000000).required()
    });

    return {
      schema,
      form: {
        companyName: "",
        description: "",
        numShareholders: null,
        shares: null,
      }
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", res);
        if(res.valid)
          this.$emit('on-new-data', this.form);
        return res;
      });
    },
  },
};
</script>
<style></style>

<template>
  <Form @submit="login" :validation-schema="schema">
    <div class="col-12 col-md-6 col-lg-4 m-auto text-center">
      <vnud-card class="card-login card-plain">
        <template v-slot:header>
          <div>
            <div class="logo-container" style="width: 100px; max-width: 100%">
              <img src="./../../assets/images/logo/logo_white.png" alt=""/>
            </div>
          </div>
        </template>

        <div>
          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <vee-input
            name="email"
            type="text"
            inputClasses="no-border form-control-lg"
            placeholder="Email"
            addon-left-icon="now-ui-icons ui-1_email-85"
          />

          <vee-input
            name="password"
            type="password"
            inputClasses="no-border form-control-lg"
            placeholder="Password"
            addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />
        </div>

        <template v-slot:footer>
          <div>
            <n-button native-type="submit" type="primary" round block :disabled="buttonDisabled">
              {{ $t('login.login') }}
            </n-button>
            <div class="pull-left">
              <h6><router-link class="link footer-link" to="/register">{{ $t('login.create_account') }}</router-link></h6>
            </div>

            <div class="pull-right">
              <h6>
<!--                <router-link class="link footer-link" to="/contact_us">-->
<!--                  Need Help?-->
<!--                </router-link>-->

                <a href="javascript:;" class="link footer-link" v-on:click="resetPassword">{{ $t('login.reset_pass') }}</a>

              </h6>
            </div>
          </div>
        </template>
      </vnud-card>
    </div>
  </Form>
</template>
<script>
import { VeeInput } from "src/components";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "login-page",
  components: {
    VeeInput,
    Form,
  },
  data() {
    return {
      error: null,
      buttonDisabled: false
    }
  },
  methods: {
    async recaptcha(actionName) {
      await this.$recaptchaLoaded();
      return await this.$recaptcha(actionName);
    },
    async login(values, actions) {
      this.buttonDisabled = true;
      this.error = null;

      let captchaToken = await this.recaptcha("login");

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + "login_check", {
            email: values.email,
            password: values.password,
            captchaToken: captchaToken
          })
      .then((response) => {

        this.$store.commit('auth_success', response.data.user);
        this.$router.push(this.$route.query.redirect || '/dashboard');

      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.violations &&
            error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t("error." + stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        console.log(error);
      }).finally(() => {this.buttonDisabled = false;});
    },
    resetPassword() {
      Swal.fire({
        title: this.$i18n.t('login.reset_pass'),
        input: "email",
        inputValue: "",
        inputPlaceholder: "E-mail",
        padding: "20px",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('login.reset'),
        showLoaderOnConfirm: true,
        preConfirm: async (email) => {
          let captchaToken = await this.recaptcha("reset_pass");
          return axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'reset_password_request/', {email: email, captchaToken: captchaToken})
            .then(response => {
              return undefined;
            }).catch(error => {
              Swal.showValidationMessage(this.$i18n.t(error.response.data.message));
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            icon: 'success',
            html: this.$i18n.t('login.reset_request_sent'),
          });
        }
      });
    }
  },
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    });

    return {
      schema,
    };
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>

<template>
  <div>

    <div class="row">

      <div class="col-12 col-xl-10 m-auto">
        <div class="card">
          <div class="card-header">
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title m-auto">
                  <i class="text-info now-ui-icons business_badge" style="font-size: 28px"></i> Upload Public Documents
                </h4>
                <p class="my-2 text-center text-danger">Please note that all the files listed here will be publicly available for all customers</p>
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-xl-4 mt-3">

                <el-upload action="" accept=".pdf,.doc,.docx,.csv,.txt"
                           drag
                           ref="uploadComp"
                           :auto-upload="false"
                           :multiple="true"
                           :show-file-list="true"
                           :limit="maxUpload"
                           :on-exceed="handleExceed"
                           :on-change="prepareDocument"
                           :on-remove="removeDocument"
                           >
                  <div class="el-upload__text">
                    <i class="now-ui-icons arrows-1_share-66 text-center my-3" style="font-size: 26px"></i>
                    Drop file here or <em>click to upload</em>
                    <br>Accepted: pdf, word files (doc, docx), csv
                  </div>

                </el-upload>

<!--                <p v-if="form.fileName" class="text-center font-weight-bold">{{form.fileName}}</p>-->

              </div>

              <div class="col-12 col-md-6 col-xl-4 mt-3">
                <label class="mb-0">Select folder:</label>
                <el-select v-model="form.folder" class="d-block">
                  <el-option v-for="folder in folders" :value="folder" :label="$t('documents_folders.' + folder)" :key="folder"></el-option>
                </el-select>

              </div>
              <div class="col-12 col-md-6 col-xl-4 mt-3">
                <label>Notes:</label>
                <el-input
                        v-model="form.description"
                        name="description"
                        maxlength="500"
                        show-word-limit
                        rows="6"
                        type="textarea">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-12 mt-3 mt-md-5 text-center">
                <n-button class="btn btn-primary m-auto" :disabled="loading || saving" v-on:click="uploadDocument">Add document</n-button>
              </div>

              <div class="col-12 mt-3 text-center" v-if="error || saving">
                <p v-if="saving" class="text-danger">{{ $t("common.saving") }}...</p>
                <p v-if="error" class="text-danger">{{ error }}</p>
              </div>

            </div>

          </div>
        </div>
      </div>



      <div class="col-12 col-xl-10 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-12">
                <h4 class="title">Available folders</h4>
                <n-button v-for="f in folders" :key="f"
                          class="m-auto"
                          :class="activeFolder === f? 'btn-outline-default' : 'btn-link'"
                          :disabled="loading || saving"
                          v-on:click="loadDocs(f, currentPageNumber, pageSize)">
                  {{ $t('documents_folders.' + f) }}
                </n-button>
              </div>

              <div class="col-12 text-center mt-3">
                <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
              </div>

              <div class="col-12 mt-5">
                <h4 class="title">List documents</h4>
                <el-table class="table-striped" :data="documents[activeFolder]" border style="width: 100%">

                  <el-table-column key="Date" :min-width="80" prop="regDate" label="Upload Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.uploadDate, true) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="name" :min-width="120" prop="name" label="File"></el-table-column>
                  <el-table-column key="description" :min-width="120" prop="description" label="Description"></el-table-column>

                  <el-table-column key="actions" :min-width="60" prop="id" label="Actions">
                    <template #default="scope">
                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                :disabled="loading || saving"
                                v-on:click="downloadDocument(scope.row.id, scope.row.name)">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="deleteDocument(scope.row.id)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>


              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElUpload, ElButton, ElPagination } from "element-plus";
  import NButton from "../../../components/Button";

  export default {
    name: "ManagePublicDocuments",
    components: {
      NButton,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElInput.name]: ElInput,
      [ElDatePicker.name]: ElDatePicker,
      [ElUpload.name]: ElUpload,
      [ElButton.name]: ElButton,
      [ElPagination.name]: ElPagination,
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        folders: ["agreement", "resolution"],
        documents: {
            agreement: [],
            resolution: [],
        },
        activeFolder: "agreement",
        form: {description: '', folder: "agreement", fileList: []},
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
        maxUpload: 10,
        listErrors: [],
        numFilesUploaded: 0,
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true)
      {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
      prepareDocument(file) {
        let f = new Blob([file.raw], {type: file.type});
        let fileName = file.name;
        this.form.fileList.push({file: f, fileName: fileName});
      },
      removeDocument(file, uploadFiles) {
        let list = [];
        for (let obj of uploadFiles) {
            let f = new Blob([obj.raw], {type: obj.type});
            let fileName = obj.name;
            list.push({file: f, fileName: fileName});
        }
          this.form.fileList = list;
      },
      handleExceed(files, uploadFiles) {
          ElMessage.warning(
              `The limit is ${this.maxUpload}, you selected ${files.length} files`
          )
      },
      uploadDocument() {
        this.saving = true;
        this.error = null;

        this.numFilesUploaded = this.form.fileList.length;

        for (let i=0; i<this.form.fileList.length; i++) {
            let data = new FormData();
            data.append('file', this.form.fileList[i].file, this.form.fileList[i].fileName);
            data.append('description', this.form.description);
            data.append('folder', this.form.folder);

            axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'public_documents', data)
            .then(response => {}).catch(error => {
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else
                    this.listErrors.push({message: this.form.fileList[i].fileName + ": " + error, indexFile: i});
            }).finally(() => {this.endOfSave();});
        }


            // let data = new FormData();
            // data.append('file', this.form.file, this.form.fileName);
            // data.append('description', this.form.description);
            // data.append('folder', this.form.folder);
            //
            // axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'public_documents', data)
            //     .then(response => {
            //         ElMessage.success("Document uploaded successfully!");
            //         if (this.activeFolder === this.form.folder)
            //             this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
            //         this.form.file = null;
            //         this.form.fileName = "";
            //         this.form.description = '';
            //         this.form.folder = "agreement";
            //     }).catch(error => {
            //     this.showError(error);
            // }).finally(() => {
            //     this.saving = false;
            // });
      },
        endOfSave() {
            if(this.numFilesUploaded === this.form.fileList.length) {
                let newFileList = [];
                for (let i=0; i<this.listErrors.length; i++)
                {
                    let f = this.form.fileList[this.listErrors[i].indexFile].file;
                    let fileName = this.form.fileList[this.listErrors[i].indexFile].fileName;
                    newFileList.push({file: f, fileName: fileName});
                }
                this.form.fileList = newFileList;

                if (this.form.fileList.length === 0)
                {
                  ElMessage.success("Document uploaded successfully!");
                  if (this.activeFolder === this.form.folder)
                      this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
                  this.form.description = '';
                  this.form.folder = "agreement";
                }
                else
                {
                    let error = {response: {data: {violations: this.listErrors}}};
                    this.showError(error);
                }
                this.saving = false;
                this.numFilesUploaded = 0;
                this.$refs.uploadComp.clearFiles();
            }
        },
      downloadDocument(docIri, nomeFile) {
        let id = docIri.split("/");
        id = id[id.length-1];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-public-document/' + id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = nomeFile;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      handlePageSizeChange () {
          this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
      },
      handlePageChange (val) {
          this.loadDocs(this.activeFolder, val, this.pageSize);
      },
      loadDocs(folder, page, pageSize) {
        this.loading = true;
        this.error = null;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'public_documents?folder=' + folder + '&itemsPerPage=' + pageSize + '&page=' + page)
        .then(response => {
            this.documents[folder] = response.data["hydra:member"];
            this.totalItems = response.data["hydra:totalItems"];
            this.activeFolder = folder;
            this.currentPageNumber = page;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      deleteDocument(id) {
        this.saving = true;
        axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'public_documents/' + id)
        .then(response => {
            this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
            ElMessage.success("successfully deleted.");
        }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      }
    },
    computed: {
    },
    beforeMount() {
        this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
    },
  }
</script>

<style>
</style>

<template>
  <div>
    <top-navbar></top-navbar>
    <div class="wrapper wrapper-full-page full-page-background customImageBackground" style="background-size: cover;
                background-position: center center; width: 100%; height: 100%">
<!--      <div class="full-page full-page-light">-->
      <div class="full-page">


        <div class="content">
          <div class="container-fluid">
            <router-view></router-view>
          </div>
        </div>
        <content-footer></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
// import PerfectScrollbar from "perfect-scrollbar";
// import "perfect-scrollbar/css/perfect-scrollbar.css";

// function hasElement(className) {
//   return document.getElementsByClassName(className).length > 0;
// }
//
// function initScrollbar(className) {
//   if (hasElement(className)) {
//     new PerfectScrollbar(`.${className}`);
//   } else {
//     // try to init it later in case this component is loaded async
//     setTimeout(() => {
//       initScrollbar(className);
//     }, 100);
//   }
// }

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import * as Yup from "yup";
import yupLocale from "../../yupSetLocaleConfig";
Yup.setLocale(yupLocale);

export default {
  components: {
    TopNavbar,
    ContentFooter,
  },
  methods: {
  },
  mounted() {
    // let docClasses = document.body.classList;
    // let isWindows = navigator.platform.startsWith("Win");
    // if (isWindows) {
    //   // if we are on windows OS we activate the perfectScrollbar function
    //   initScrollbar("sidebar");
    //   initScrollbar("sidebar-wrapper");
    //
    //   docClasses.add("perfect-scrollbar-on");
    // } else {
    //   docClasses.add("perfect-scrollbar-off");
    // }
  },
};
</script>
<style lang="scss" scoped>
    .full-page{
        &:after{
            background-color: rgba(0, 0, 0, 0.0) !important;
        }
    }

    .customImageBackground {
        background-image: url('./../../assets/images/bg_back.jpg');
        z-index: 1;
    }
</style>

<template>
  <div>
    <Form ref="form" @submit="validate" :validation-schema="schema">
    <h5 class="info-text">
      {{ $t('company.director_data') }}
    </h5>

      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-12 text-center">
              <Field
                      name="isPresent"
                      type="boolean"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.external_director') }}:</label> <br>
                <el-radio-group
                        v-bind="field"
                        :model-value="value"
                        name="isPresent"
                        type="text"
                        v-model="director.isPresent"
                        v-on:change="!this.director.isPresent ? this.$refs.form.resetForm() : null">
                  <el-radio :label="false" default>{{ $t('common.no') }}</el-radio>
                  <el-radio :label="true">{{ $t('common.yes') }}</el-radio>
                </el-radio-group>

                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="name"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.director_name') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="name"
                        type="text"
                        v-model="director.name"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="surname"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.director_surname') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="surname"
                        type="text"
                        v-model="director.surname"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="phone"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('contacts.phone_number') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="phone"
                        type="text"
                        v-model="director.phone"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>


            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="email"
                      type="email"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('contacts.email_address') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="email"
                        type="email"
                        v-model="director.email"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="birth"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.date_birth') }}</label>
                <el-date-picker
                        v-bind="field"
                        :model-value="value"
                        v-model="director.birth"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                        :disabled-date="disabledDate"
                        :default-value="new Date(yearsAgo16)"
                        :disabled="!director.isPresent">
                </el-date-picker>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="placeBirth"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.place_birth') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        v-model="director.placeBirth"
                        type="text"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="addressStreet"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.address') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        v-model="director.addressStreet"
                        type="text"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="addressCity"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.city') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        v-model="director.addressCity"
                        type="text"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div class="col-12 col-md-6 col-xl-4">
              <Field
                      name="addressCountry"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <label>{{ $t('company.country') }}</label>
                <el-input
                        v-bind="field"
                        :model-value="value"
                        v-model="director.addressCountry"
                        type="text"
                        :disabled="!director.isPresent">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>

            <div v-if="error" class="col-12 text-center text-danger" style="white-space: pre;"><br>{{ error }}</div>

          </div>
        </div>
      </div>
    </Form>
  </div>
</template>
<script>


import * as Yup from "yup";
import { ElInput, ElDatePicker, ElRadio, ElRadioGroup } from "element-plus";
import { Form, Field } from "vee-validate";

export default {
  components: {
    [ElInput.name]: ElInput,
    [ElDatePicker.name]: ElDatePicker,
    [ElRadio.name]: ElRadio,
    [ElRadioGroup.name]: ElRadioGroup,
    Form,
    Field
  },
  data() {
    const schema = Yup.object().shape({
      isPresent: Yup.boolean().default(false),
      name: Yup.string().when("isPresent", {
                is: (isPresent) => isPresent,
                then: Yup.string().required()
      }),
      surname: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required()
      }),
      phone: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required().min(10).max(14)
      }),
      email: Yup.string().email().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().email().required()
      }),
      birth: Yup.date().when("isPresent", {
            is: (isPresent) => isPresent,
            then: Yup.date().required()
        }),
      placeBirth: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required()
      }),
      addressStreet: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required()
      }),
      addressCity: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required()
      }),
      addressCountry: Yup.string().when("isPresent", {
          is: (isPresent) => isPresent,
          then: Yup.string().required()
      }),
    });

    return {
      schema,
      yearsAgo16: (new Date).setFullYear((new Date).getFullYear() - 16),
      director: {isPresent: false, name: "", surname: "", phone: null, email: "", birth: null, placeBirth: "", addressStreet: "", addressCity: "", addressCountry: ""},
      error: ""
    };
  },
  methods: {
    disabledDate(time) {
      return time.getTime() > this.yearsAgo16;
    },
    validate() {
      return this.$refs.form.validate().then((res) => {
          this.$emit("on-validated", res);
          if(res.valid)
            this.$emit('on-new-data', {director: this.director});
          return res;
      });
    },
  },
};
</script>
<style></style>

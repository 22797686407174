<template>
  <div>

      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-body">
                      <div class="row">
                          <div class="col-12">
                              <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                              <p v-if="userData" class="m-1 text-center font-weight-bold">
                                <span v-if="userData.company">{{ userData.company.name }}</span>
                                <span v-else>
                                  {{userData.email}}
                                  <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                                </span>
                              </p>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


    <div class="row">
      <div class="col-12 col-lg-10 m-auto">
        <div class="card">
          <div class="card-header">
            <h4>Active Subscriptions</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <el-table class="table-striped" :data="subs" border style="width: 100%">
                  <el-table-column key="name" :min-width="80" prop="name" label="Subscription">
                    <template #default="scope">
                      {{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column key="priceMonth" :min-width="80" prop="priceMonth" label="Price">
                    <template #default="scope">
                      {{ formatPrice(scope.row.priceMonth*100, scope.row.currency) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="period" :min-width="80" prop="period" label="Period">
                    <template #default="scope">
                      <span v-if="scope.row.startAt">{{ formatDate(scope.row.startAt.date, false) }} - {{ formatDate(scope.row.endAt.date, false) }}</span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>
                  <el-table-column key="status" :min-width="80" prop="status" label="Status">
                    <template #default="scope">
                      <span v-if="$te('subh_status.' + scope.row.status)">{{ $t("subh_status." + scope.row.status) }}</span>
                      <span v-else>{{scope.row.status}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="actions" width="170" prop="stripeId" label="Actions">
                    <template #default="scope">

                      <n-button v-if="scope.row.stripeId"
                                native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                @click="cancelSubscription(scope.row.stripeId, scope.$index)"
                                :disabled="loading || saving">
                        <i class="now-ui-icons ui-1_simple-remove"></i>
                      </n-button>
                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                    <span v-else>{{ $t('common.no_data') }}</span>
                  </template>
                </el-table>
                  <div v-if="saving"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</div>
              </div>
            </div>


            <div class="row">
              <div class="col-12 mt-5">
                <h5>Scheduled subscriptions</h5>
              </div>
              <div class="col-12">
                <el-table class="table-striped" :data="scheduled" border style="width: 100%">
                  <el-table-column key="name" :min-width="80" prop="name" label="Subscription">
                    <template #default="scope">
                      {{ scope.row.subscriptionRef.name }}
                    </template>
                  </el-table-column>
                  <el-table-column key="priceMonth" :min-width="80" prop="priceMonth" label="Price">
                    <template #default="scope">
                      {{ formatPrice(scope.row.subscriptionRef.priceMonth*100, scope.row.subscriptionRef.currency) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="startDate" :min-width="80" prop="startDate" label="start Date">
                    <template #default="scope">
                      <span v-if="scope.row.startDate.date">{{ formatDate(scope.row.startDate.date, false) }}</span>
                      <span v-else-if="scope.row.startDate">{{ formatDate(scope.row.startDate, false) }}</span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="status" :min-width="80" prop="status" label="status">
                    <template #default="scope">
                      <span v-if="scope.row.status === 'new'"><i class="now-ui-icons ui-2_time-alarm"></i> Waiting</span>
                      <span v-else-if="scope.row.status === 'failed'" class="text-danger"><i class="now-ui-icons ui-1_simple-remove"></i> Failed</span>
                      <span v-else>{{scope.row.status}}</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="actions" width="170" prop="id" label="Actions">
                    <template #default="scope">
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                @click="cancelSchedule(scope.row.id, scope.$index)"
                                :disabled="loading || saving || scope.row.status !== 'new'">
                        <i class="now-ui-icons ui-1_simple-delete"></i>
                      </n-button>
                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span v-if="loading" class="text-danger"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                    <span v-else>{{ $t('common.no_data') }}</span>
                  </template>
                </el-table>
                <div v-if="saving"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</div>
              </div>
            </div>


            <div class="row mt-4">
              <div class="col-12">
                <p class="m-0">Legenda: </p>

                <p class="m-0">
                  <n-button native-type="button" type="danger" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                    <i class="now-ui-icons ui-1_simple-remove"></i>
                  </n-button>
                  Cancel the subscription and all the pending payments. No further attempt will be made for any of the pending payments.
                </p>

                <p class="m-0">
                  <n-button native-type="button" type="danger" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                    <i class="now-ui-icons ui-1_simple-delete"></i>
                  </n-button>
                  Cancel the scheduled subscription.
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12 col-lg-10 m-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Schedule a new subscription</h4>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-md-6 col-xl-3 m-auto">
                <label class="mt-4">Subscription:</label>
                <el-select v-model="form.sub" class="d-block">
                  <el-option v-for="product in products" :value="product.id" :label="product.name" :key="product.id"></el-option>
                </el-select>
              </div>
              <div class="col-12 col-md-6 col-xl-3 m-auto">
                <label class="mt-4">Start date:</label>
                <el-date-picker
                        v-model="form.date"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="YYYY-MM-DD"
                        :disabled-date="disabledDate">
                </el-date-picker>
              </div>

              <div class="col-12 col-md-6 col-xl-4 text-center m-auto">
                <n-button class="btn btn-primary m-auto" :disabled="loading || saving" v-on:click="scheduleSub">Save</n-button>
              </div>

              <div class="col-12 text-center mb-4">
                <span v-if="saving" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</span>
                <div v-if="error" class="text-danger text-center col-12">{{ error }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ManageUserSubscriptions",
    components: {
      [ElSelect.name]: ElSelect,
      [ElInput.name]: ElInput,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElDatePicker.name]: ElDatePicker
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        userData: null,
        subs: [],
        products: [],
        scheduled: [],
        form: {
          sub: null,
          date: new Date()
        }
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
            return dateObj.toLocaleDateString();
        },
        formatPrice(price, currency) {
            return currency + " " + (price/100).toFixed(2);
        },
        disabledDate(time) {
            return time.getTime() < (new Date((new Date).toDateString())).getTime();
        },
        cancelSubscription(stripeId, index) {
          Swal.fire({
              title: this.$i18n.t("common.loading"),
              html: "<span></span>",
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              confirmButtonColor: '#FF3636',
              didOpen: () => {
                  Swal.showLoading();
                  axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/get_failed_payments/' + this.userData.id + "/" + stripeId)
                      .then(response => {
                          Swal.hideLoading();
                          Swal.getTitle().textContent = 'Cancel Subscription';
                          let text = "The customer " + this.userData.email + " has " + response.data.failed_payments.length +
                              " pending payments that will be cancelled too:<br><br>";
                          for(let i=0; i<response.data.failed_payments.length; i++)
                              text += this.formatDate(response.data.failed_payments[i].createdAt.date, false) + ": " +
                                  this.formatPrice(response.data.failed_payments[i].total) + "<br>";

                          Swal.getHtmlContainer().querySelector('span').outerHTML = text;
                      }).catch(error => {this.showError(error);}).finally(() => {});
              },
              allowOutsideClick: () => false
          }).then((result) => {
              if (result.isConfirmed) {
                Swal.showLoading();
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/stripe_cancel_subscription/' + this.userData.id + "/" + stripeId)
                  .then(response => {

                      let text = "The subscription has been successfully cancelled.<br>";
                      if (response.data.void_failed.length > 0) {
                          text += "However, the following payments have not been cancelled for an error:<br>";
                          for (let i = 0; i < response.data.void_failed.length; i++)
                              text += this.formatDate(response.data.failed_payments[i].createdAt.date, false) + ": " +
                                  this.formatPrice(response.data.failed_payments[i].total) + "<br>";
                          text += "Please, cancel them manually."
                      } else
                          text += "All the failed payments have been cancelled too.";
                      Swal.hideLoading();

                      Swal.fire({
                          icon: 'success',
                          title: 'Subscription canceled',
                          html: text
                      });
                      this.subs.splice(index, 1);

                  }).catch(error => {
                      this.showError(error);
                  }).finally(() => {});
              }
          });
        },
        scheduleSub() {
          this.error = null;
          if (this.form.sub === null) {
              this.error = "Please, choose a subscription.";
              return;
          }
          if (this.form.date === null) {
              this.error = "Please, select a valid date.";
              return;
          }
          this.saving = true;

          axios.post(process.env.VUE_APP_SERVER_BASE_URL + "manager/stripe_create_subscription",
          {
              userId: this.userData.id,
              subId: this.form.sub,
              startDate: this.form.date.toDateString()
          })
          .then((response) => {
              ElMessage.success(response.data.message);
              if(response.data.message === 'created')
                  this.subs.push(response.data.obj);
              else
                  this.scheduled.push(response.data.obj);
          }).catch((error) => {
              if (error.response && error.response.data && error.response.data.details)
                  Swal.fire("Error", error.response.data.details, "error");
              else
                this.showError(error);
          }).finally(() => {this.saving = false;});
        },
        cancelSchedule(id, index) {
            this.saving = true;
            axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "subscription_schedules/" + id,
                {status: "cancelled"}, {headers: {'Content-Type': 'application/merge-patch+json'}})
            .then((response) => {
                ElMessage.success("subscription schedule successfully cancelled");
                this.scheduled.splice(index, 1);
            }).catch((error) => {this.showError(error);}).finally(() => {this.saving = false;});
        }
    },
    computed: {
    },
    beforeMount() {
      this.loading = true;
      let fetchedId = this.$route.params.userid;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "subscriptions?recurringMonthInterval[gt]=0")
      .then((response) => {
          this.products = response.data["hydra:member"];
      }).catch((error) => {this.showError(error);}).finally(() => {this.loading = false; });

      let now = (new Date()).toDateString();
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "subscription_schedules?status=new&startDate[after]=" + now + "&userRef.id=" + fetchedId)
      .then((response) => {
          this.scheduled = response.data["hydra:member"];
      }).catch((error) => {this.showError(error);}).finally(() => {this.loading = false; });

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
      .then(response => {
          this.userData = response.data;
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/get_subs_summary/' + fetchedId)
      .then(response => {
        this.subs = response.data.subs;
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>

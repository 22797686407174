<template>
  <div>
    <navbar :show-navbar="showMenu" id="nav">
      <div class="navbar-wrapper">
        <div class="navbar-toggle" :class="{ toggled: showMenu }">
          <navbar-toggle-button @click="toggleNavbar" target="#nav" barColor="white">
          </navbar-toggle-button>
        </div>
        <a class="navbar-brand" href="https://ltd24ore.com/">
            <img src="./../assets/images/logo/logo_white_no_text.png" alt="" style="height:40px !important; width:auto !important;"/>
            {{ product_name }}
        </a>
      </div>

      <template v-slot:navbar-menu>
<!--        <router-link to="/dashboard" v-slot="{ href, isActive, isExactActive }" custom >-->
<!--          <li-->
<!--            class="nav-item"-->
<!--            :class="[-->
<!--              isActive && 'active router-link-active',-->
<!--              isExactActive && 'router-link-exact-active',-->
<!--            ]">-->
<!--            <a :href="href" class="nav-link">-->
<!--              <i class="now-ui-icons design_app"></i> Dashboard-->
<!--            </a>-->
<!--          </li>-->
<!--        </router-link>-->

        <router-link to="/book_consultancy" v-slot="{ href, isActive, isExactActive }" custom >
          <li class="nav-item"
              :class="[
              isActive && 'active router-link-active',
              isExactActive && 'router-link-exact-active',
            ]">
            <a :href="href" class="nav-link">
              <i class="now-ui-icons location_bookmark"></i> {{$t('book_consultancy.title')}}
            </a>
          </li>
        </router-link>

        <router-link to="/contact_us" v-slot="{ href, isActive, isExactActive }" custom>
          <li class="nav-item"
              :class="[
                  isActive && 'active router-link-active',
                  isExactActive && 'router-link-exact-active',
              ]">
            <a :href="href" class="nav-link">
              <i class="now-ui-icons ui-2_chat-round"></i> {{ $t("contact_us") }}
            </a>
          </li>
        </router-link>

        <drop-down tag="li" position="right" class="nav-item" :title="$i18n.locale.toUpperCase()" :img="'/img/flags/' + $i18n.locale.toUpperCase() + '.png'">
          <!--          <span class="d-block" v-model="$i18n.locale" style="padding-right: 3px">{{ $i18n.locale.toUpperCase() }}</span>-->
          <a class="dropdown-item" v-for="lang in langs" v-bind:key="lang" @click="changeLocale(lang)"
             :set="langImg = '/img/flags/' + lang.toUpperCase() + '.png'">
              <img v-bind:src="langImg" style="padding-right: 4px; height: 24px !important; width:auto !important;"> {{ lang.toUpperCase() }}
          </a>
        </drop-down>

<!--        <router-link-->
<!--          to="/login"-->
<!--          v-slot="{ href, isActive, isExactActive }"-->
<!--          custom-->
<!--        >-->
<!--          <li class="nav-item"-->
<!--              :class="[-->
<!--                  isActive && 'active router-link-active',-->
<!--                  isExactActive && 'router-link-exact-active',-->
<!--              ]">-->
<!--            <a :href="href" class="nav-link">-->
<!--              <font-awesome-icon icon="sign-in-alt" style="font-size: 16px" class="align-middle mr-1"></font-awesome-icon>-->
<!--              {{ $t("login.login") }}-->
<!--            </a>-->
<!--          </li>-->
<!--        </router-link>-->

<!--          <router-link to="/register" v-slot="{ href, isActive, isExactActive }" custom>-->
<!--              <li class="nav-item"-->
<!--                  :class="[-->
<!--                      isActive && 'active router-link-active',-->
<!--                      isExactActive && 'router-link-exact-active',-->
<!--                  ]">-->
<!--                <a :href="href" class="nav-link">-->
<!--                  <i class="now-ui-icons tech_mobile"></i> {{ $t("registration.register") }}-->
<!--                </a>-->
<!--              </li>-->
<!--          </router-link>-->

        <router-link to="/signup" v-slot="{ href, isActive, isExactActive }" custom>
          <li class="nav-item"
              :class="[
                      isActive && 'active router-link-active',
                      isExactActive && 'router-link-exact-active',
                  ]">
            <a :href="href" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" style="font-size: 16px" class="align-middle mr-1"></font-awesome-icon> {{ $t("login.login") }}
            </a>
          </li>
        </router-link>

      </template>
    </navbar>
    <div class="wrapper wrapper-full-page">
      <div
        class="full-page section-image"
        :class="pageClass"
        filter-color="black"
      >
        <div class="content">
          <div class="container">
            <router-view></router-view>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid text-center">
            <nav>
              <ul>
                <li>
                  <a :href="privacy_policy_url" target="_blank">Privacy Policy</a>
                </li>
                <li>
                  <a :href="cookie_policy_url" target="_blank">Cookie Policy</a>
                </li>
                <li>
                  <a :href="terms_url" target="_blank">{{$t('common.terms_conditions')}}</a>
                </li>
              </ul>
              <p class="m-0 mt-2" style="font-size: 10px">{{$t('footer.recaptcha')}}
                <a href="https://policies.google.com/privacy">Privacy Policy</a> {{$t('common.and')}}
                <a href="https://policies.google.com/terms">{{$t('footer.terms_service')}}</a> {{$t('footer.apply')}}.
              </p>
            </nav>
            <div class="copyright">
              &copy; {{ company_name }} {{ company_type }} {{ company_year }}<span v-if="year != company_year"> - {{ year }}</span>. {{ $t('common.right_reserved') }}
            </div>
          </div>
        </footer>

        <div class="full-page-background customImageBackground"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { Navbar, NavbarToggleButton } from "src/components";
import * as Yup from "yup";
import axios from "axios";

Yup.setLocale({

});

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: `${this.$route.name}-page`.toLowerCase(),
      product_name: process.env.VUE_APP_PRODUCT_NAME,
      company_name: process.env.VUE_APP_COMPANY_NAME,
      company_type: process.env.VUE_APP_COMPANY_TYPE,
      company_country: process.env.VUE_APP_COMPANY_COUNTRY,
      company_address: process.env.VUE_APP_COMPANY_ADDRESS,
      company_year: process.env.VUE_APP_COMPANY_YEAR,
      privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
      cookie_policy_url: process.env.VUE_APP_COOKIE_POLICY_URL,
      terms_url: process.env.VUE_APP_TERMS_URL,
      langs: ['en', 'es', 'it'],
    };
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setPageClass(to) {
      this.pageClass = `${to.name}-page`.toLowerCase();
    },
    async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('registration');
    },
    async changeLocale(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLang', lang);
      if(this.$store.getters.isLoggedIn) {
          let token = await this.recaptcha();
          axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "users/" + this.$store.getters.getUserId,
              {language: lang, captchaToken: token},
              {headers: {'Content-Type': 'application/merge-patch+json'}}
          ).then((response) => {}).catch((error) => {});
      }
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route(to) {
      this.setPageClass(to);
    },
  },
};
</script>
<style lang="scss" scoped>
  .customImageBackground {
    background-image: url('./../assets/images/background.jpg');
  }
</style>

<template>
  <Form @submit="reset" :validation-schema="schema">
    <div class="row">
      <div class="col-lg-5 m-auto">
        <vnud-card class="card-login card-plain text-center">

          <template v-slot:header>
            <h4 class="card-title">{{ $t('login.reset_pass') }}</h4>
          </template>

          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <vee-input
                  name="password"
                  type="password"
                  inputClasses="no-border form-control-lg"
                  placeholder="Password"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />

          <vee-input
                  name="confirm"
                  type="password"
                  inputClasses="no-border form-control-lg"
                  :placeholder="$t('registration.confirm_pass')"
                  addon-left-icon="now-ui-icons ui-1_lock-circle-open"
          />



          <div v-if="saving" class="col-12 mt-2"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>

          <template v-slot:footer>
            <n-button type="primary" native-type="submit" round :disabled="buttonDisabled">
              {{ $t('reset') }}
            </n-button>
          </template>
        </vnud-card>
      </div>
    </div>
  </Form>
</template>
<script>
  import { VeeInput } from "src/components";
  import { Form } from "vee-validate";
  import * as Yup from "yup";
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {
    name: "reset-password",
    components: {
      VeeInput,
      Form,
    },
    data() {
      return {
        error: null,
        buttonDisabled: false,
        saving: false,
      };
    },
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('contact');
      },
      async reset(values, actions) {
        this.error = "";
        this.buttonDisabled = true;

        let fetchedId = this.$route.params.userId;
        let fetchedRegistrationString = this.$route.params.registrationString;

        let captchaToken = await this.recaptcha();

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'reset_password/' + fetchedId + "/" + fetchedRegistrationString,
                {password: values.password, captchaToken: captchaToken})
        .then(response => {
          actions.resetForm({});
          Swal.fire({
              icon: 'success',
              title: this.$i18n.t("common.done") + "!",
              text: this.$i18n.t("login.pass_changed"),
              confirmButtonClass: "btn btn-info",
              buttonsStyling: false
          }).then((result) => {
            this.$router.push('/login');
          });

        }).catch(error => {
          if (error.response && error.response.data && error.response.data.violations &&
                  error.response.data.violations.length > 0) {
            for (let i = 0; i < error.response.data.violations.length; i++)
            {
              let stringErr = error.response.data.violations[i].message;
              if(stringErr.endsWith("."))
                stringErr = stringErr.slice(0, -1);
              this.error += this.$i18n.t(stringErr) + ". ";
            }
          } else if(error.response && error.response.data && error.response.data.message)
          {
            let stringErr = error.response.data.message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error = this.$i18n.t("error." + stringErr);
          }
          else this.error = this.$i18n.t("error.try_again");
          console.log(error);
        }).finally(() => {this.buttonDisabled = false; this.saving = false;});

      },
    },
    setup() {
      const schema = Yup.object().shape({
        password: Yup.string().required().min(8).label("The Password"),
        confirm: Yup.string().required().oneOf([Yup.ref("password")], {key: "yup.passwords_not_match", values: {}}),
      });

      return {
        schema,
      };
    },
  }
</script>
<style scoped>
</style>

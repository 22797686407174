<template>
  <div>
    <div class="row" v-if="invoiceRef">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                <p class="m-1 text-center font-weight-bold">
                  {{invoiceRef.invoiceNumber}} - {{invoiceRef.year}}: {{invoiceRef.customerName}} {{invoiceRef.customerContact}}
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Create new credit note</h4>
          </div>
          <div class="card-body">
            <div class="row" v-if="creditNoteToModify">
              <div class="col-12">
                <p class="m-1 text-center font-weight-bold">
                  Modify credit note n. {{creditNoteToModify.number}} - {{creditNoteToModify.year}}
                </p>
              </div>
            </div>


            <div class="row">
              <div v-if="error" class="text-danger text-center col-12">{{ error }}</div>

              <div class="col-12 text-right">
                <router-link :to="'/manager/download_invoices'">
                  <n-button native-type="button" type="primary" class="btn">
                    <i class="now-ui-icons ui-1_zoom-bold"></i> Search invoice
                  </n-button>
                </router-link>
              </div>

              <div class="col-12 text-center my-4">
                <el-select
                        class="d-block"
                        v-model="customerSelected"
                        value-key="id"
                        filterable
                        remote
                        clearable
                        placeholder="Search customer by email (enter at least 3 chars)"
                        :remote-method="searchCustomer"
                        :loading="loadingCustomers"
                        v-on:change="setCustomerData"
                        :disabled="invoiceRef"
                >
                  <el-option
                          v-for="item in customers"
                          :key="item"
                          :label="item.email"
                          :value="item"
                  ></el-option>
                </el-select>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3">Name:</label>
                <el-input
                        v-model="customerName"
                        type="text"
                        name="name"
                        :disabled="invoiceRef || (customerSelected !== null && customerSelected !== '')">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3">Address Line 1:</label>
                <el-input
                        v-model="customerAddressLine1"
                        type="text"
                        name="address"
                        maxlength="75"
                        show-word-limit
                        :disabled="invoiceRef || (customerSelected !== null && customerSelected !== '')">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3">Address Line 2:</label>
                <el-input
                        v-model="customerAddressLine2"
                        type="text"
                        name="city"
                        maxlength="75"
                        show-word-limit
                        :disabled="invoiceRef || (customerSelected !== null && customerSelected !== '')">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3">Contact (email or phone):</label>
                <el-input
                        v-model="customerContact"
                        type="text"
                        name="contact"
                        :disabled="invoiceRef || (customerSelected !== null && customerSelected !== '')">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3">Vat Number or Tax code:</label>
                <el-input
                        v-model="fiscalNumber"
                        type="text"
                        name="tax"
                        :disabled="invoiceRef || (customerSelected !== null && customerSelected !== '')">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mt-3 mb-0">Currency:</label><br>
                <el-select class="select-default d-block" v-model="currency" :disabled="invoiceRef">
                  <el-option class="select-default" key="EUR" label="€" value="€"></el-option>
                  <el-option class="select-default" key="GBP" label="£" value="£"></el-option>
                  <el-option class="select-default" key="DOLLAR" label="$" value="$"></el-option>
                </el-select>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <el-date-picker
                        v-model="creditNoteDate"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                        class="mt-2">
                </el-date-picker>
              </div>

            </div>
            <div class="row mt-5">
              <div class="col-12">
                <el-table class="table-striped" :data="items" border style="width: 100%">

                  <el-table-column key="title" :min-width="120" prop="title" label="Title">
                    <template #default="scope">
                      <el-input
                              v-model="scope.row.title"
                              type="text"
                              :name="'title_' + scope.$index"
                              placeholder="Insert title"
                              :disabled="invoiceRef">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column key="description" :min-width="160" prop="description" label="Description">
                    <template #default="scope">
                      <el-input
                              v-model="scope.row.description"
                              type="text"
                              :name="'description_' + scope.$index"
                              placeholder="Insert small description"
                              :disabled="invoiceRef">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column key="qty" :min-width="60" prop="qty" label="Quantity">
                    <template #default="scope">
                      <el-input
                              v-model="scope.row.qty"
                              type="number"
                              min="1"
                              :name="'qty_' + scope.$index"
                              placeholder="Quantity"
                              v-on:change="scope.row.total = totalRow(scope.row)"
                              :disabled="invoiceRef">
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column key="price" :min-width="80" prop="price" label="Price">
                    <template #default="scope">
                      <el-input
                              v-model="scope.row.price"
                              type="number"
                              min="0"
                              :name="'price_' + scope.$index"
                              placeholder="Price"
                              v-on:change="scope.row.total = totalRow(scope.row)"
                              :disabled="invoiceRef">
                        <template #prepend>-</template>
                      </el-input>
                    </template>
                  </el-table-column>

                  <el-table-column key="vat" :min-width="80" prop="vat" label="Vat">
                    <template #default="scope">
                      <el-input
                              v-model="scope.row.vat"
                              type="number"
                              min="0"
                              :name="'vat_' + scope.$index"
                              placeholder="optional"
                              v-on:change="scope.row.total = totalRow(scope.row)"
                              :disabled="invoiceRef">
                      </el-input>
                    </template>
                  </el-table-column>

                  <el-table-column key="total" :min-width="80" prop="total" label="Total">
                    <template #default="scope">
                      - {{ scope.row.total }}
                    </template>
                  </el-table-column>

                  <el-table-column key="actions" :min-width="80" prop="price" label="Actions">
                    <template #default="scope">
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving || invoiceRef"
                                v-on:click="items.splice(scope.$index, 1)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="col-12 text-right">
                <n-button native-type="button" type="primary" class="btn btn-sm"
                          @click="items.push({title: '', description: '', qty: 1, vat: false, price: 0, discount: false, total: 0})"
                          :disabled="loading || saving || invoiceRef">Add row</n-button>
              </div>

              <div class="col-12 text-center">
                <p class="font-weight-bold" style="font-size: medium">Total: - {{ currency }} {{ sumItems }}</p>
              </div>

            </div>

            <div class="row">
              <div class="col-12 col-md-6">
                <label class="mt-4">Additional notes:</label>
                <el-input
                        v-model="notes"
                        name="notes"
                        maxlength="500"
                        show-word-limit
                        rows="5"
                        type="textarea">
                </el-input>
              </div>

              <div class="col-12 col-md-6 m-auto text-center">
                <n-button native-type="button" type="primary" class="btn" @click="generateReceipt" :disabled="loading || saving">Generate</n-button>
                <p v-if="error" class="text-danger text-center">{{ error }}</p>
                <span v-if="loading || saving" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
    import axios from 'axios';
    import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker } from "element-plus";


    export default {
        name: "NewCreditNote",
        components: {
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElInput.name]: ElInput,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElDatePicker.name]: ElDatePicker,
        },
        data () {
            return {
                loading: false,
                saving: false,
                error: null,
                loadingCustomers: false,
                customers: [],
                invoiceYears: [],
                invoiceNumbers: [],
                customerName: "",
                customerAddressLine1: "",
                customerAddressLine2: "",
                customerContact: "",
                fiscalNumber: "",
                currency: "€",
                creditNoteDate: new Date(),
                items: [{title: "", description: "", qty: 1, vat: false, price: 0, discount: false, total: 0}],
                notes: null,
                customerSelected: null,
                invoiceYearSelected: null,
                invoiceNumberSelected: null,
                invoiceRef: null,
                creditNoteToModify: null
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true)
            {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price) {
                return "€ " + (price/100).toFixed(2);
            },

            searchCustomer(query) {
                if (query && query.length > 2) {
                    this.loadingCustomers = true;
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + "users/search/" + query)
                        .then((response) => {
                            this.customers = response.data['hydra:member'];
                        }).catch(error => {this.customers = []; this.showError(error);}).finally(() => {this.loadingCustomers = false;});
                } else {
                    this.customers = [];
                }
            },

            setCustomerData() {
                if (this.customerSelected) {
                    this.customerName = this.customerSelected.name ? this.customerSelected.name : this.customerSelected.firstName + this.customerSelected.lastName
                    this.customerAddressLine1 = this.customerSelected.address;
                    this.customerAddressLine2 = this.customerSelected.country;
                    this.customerContact = this.customerSelected.email;
                    if (this.customerSelected.vatNumber)
                        this.fiscalNumber = this.customerSelected.vatNumber;
                    else
                        this.fiscalNumber = this.customerSelected.taxCode;
                }
                else
                {
                    this.customerName = "";
                    this.customerAddressLine1 = "";
                    this.customerAddressLine2 = "";
                    this.customerContact = "";
                    this.fiscalNumber = "";
                }
            },

            totalRow(row) {
                let t = parseFloat(row.price) * parseFloat(row.qty);
                if (parseFloat(row.vat) > 0)
                    t = t + (t * row.vat / 100);
                return parseFloat(t.toFixed(2));
            },

            generateReceipt() {
                this.error = null;
                let data = {};
                if (this.customerName === null || this.customerName.trim() === "") {
                    this.error = "Name field required";
                    return;
                }
                this.customerName = this.customerName.trim();

                if (this.customerAddressLine1 === null || this.customerAddressLine1.trim() === "") {
                    this.error = "Address field required";
                    return;
                }
                this.customerAddressLine1 = this.customerAddressLine1.trim();

                if(this.items.length <= 0)
                {
                    this.error = "Insert at least one product in the table";
                    return;
                }

                let total = 0;
                for (let i = 0; i<this.items.length; i++) {

                    if (this.items[i].title === null || this.items[i].title.trim() === "") {
                        this.error = "The title of the product n. " + (i+1) + " cannot be empty";
                        return;
                    }
                    this.items[i].title = this.items[i].title.trim();

                    if (this.items[i].description === null || this.items[i].description.trim() === "") {
                        this.error = "The description of the product n. " + (i+1) + " cannot be empty";
                        return;
                    }
                    this.items[i].description = this.items[i].description.trim();

                    if (isNaN(parseFloat(this.items[i].qty)) || parseFloat(this.items[i].qty) <= 0)
                    {
                        this.error = "The quantity of the product n. " + (i+1) + " must be un integer greater than 0";
                        return;
                    }
                    if (isNaN(parseFloat(this.items[i].price)) || parseFloat(this.items[i].price) <= 0)
                    {
                        this.error = "The price of the product n. " + (i+1) + " must be a number greater than 0";
                        return;
                    }
                    if (isNaN(parseFloat(this.items[i].vat)) || parseFloat(this.items[i].vat) <= 0)
                        this.items[i].vat = false;

                    total += parseFloat(this.items[i].total);
                }

                if (this.customerSelected !== null && this.customerSelected !== "" && this.customerSelected.id)
                    data.userRef = "/api/users/" + this.customerSelected.id;

                data.customerName = this.customerName;
                data.customerAddressLine1 = this.customerAddressLine1;
                data.customerAddressLine2 = this.customerAddressLine2;
                data.customerContact = this.customerContact;
                data.fiscalNumber = this.fiscalNumber;
                data.currency = this.currency;
                data.items = this.items;
                data.total = total;
                if (this.notes !== null && this.notes.trim().length > 0)
                    data.notes = this.notes.trim();
                if (this.invoiceRef !== null)
                    data.invoiceRef = this.invoiceRef['@id'];
                data.createdAt = this.creditNoteDate.toDateString();
                this.saving = true;

                axios.post(process.env.VUE_APP_SERVER_BASE_URL + "credit_notes", data)
                    .then((response) => {
                        ElMessage.success("Credit note data saved! It will be generated in few minutes.");
                        this.customerName = "";
                        this.customerAddressLine1 = "";
                        this.customerAddressLine2 = "";
                        this.customerContact = "";
                        this.fiscalNumber = "";
                        this.customerSelected = null;
                        this.customers = [];
                        this.items = [{title: "", description: "", qty: 1, vat: false, price: 0, discount: false, total: 0}];
                        this.notes = "";
                        this.creditNoteDate = new Date();
                    }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
            }
        },
        computed: {
            sumItems() {
                let sum = 0;
                for (let i=0; i<this.items.length; i++)
                {
                    let val = parseFloat(this.items[i].total);
                    if (!isNaN(val))
                        sum += val;
                }
                return sum;
            }
        },
        beforeMount() {
            let fetchedId = this.$route.params.inv_id;
            if (fetchedId) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + "invoices/" + fetchedId)
                    .then((response) => {
                        this.invoiceRef = response.data;
                        this.customerName = this.invoiceRef.customerName;
                        this.customerAddressLine1 = this.invoiceRef.customerAddress;
                        this.customerAddressLine2 = this.invoiceRef.customerCity;
                        this.customerContact = this.invoiceRef.customerContact;
                        this.fiscalNumber = this.invoiceRef.fiscalNumber;
                        this.currency = this.invoiceRef.currency;
                        this.items = this.invoiceRef.items;
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            }
            let fetchedCreditNoteId = this.$route.query.credit_note_id;
            if (fetchedCreditNoteId) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + "credit_notes/" + fetchedCreditNoteId)
                .then((response) => {
                    this.creditNoteToModify = response.data;
                    //TODO: populate credit note and invoiceRef fields
                    this.customerName = response.data.customerName;
                    this.customerAddressLine1 = response.data.customerAddressLine1;
                    this.customerAddressLine2 = response.data.customerAddressLine2;
                    this.customerContact = response.data.customerContact;
                    this.fiscalNumber = response.data.fiscalNumber;
                    this.items = response.data.items;
                    if(response.data.notes)
                      this.notes = response.data.notes;
                    this.creditNoteDate = response.data.createdAt;
                    if (response.data.invoiceRef)
                      this.invoiceRef = response.data.invoiceRef;
                    // this.customerName = this.invoiceRef.customerName;
                    // this.customerAddressLine1 = this.invoiceRef.customerAddress;
                    // this.customerAddressLine2 = this.invoiceRef.customerCity;
                    // this.customerContact = this.invoiceRef.customerContact;
                    // this.fiscalNumber = this.invoiceRef.fiscalNumber;
                    // this.currency = this.invoiceRef.currency;
                    // this.items = this.invoiceRef.items;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            }
        },
    }
</script>

<style scoped>

</style>

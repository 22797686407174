<template>

  <div class="row">

    <div class="col-lg-7 m-auto order-1 order-lg-2">

      <vnud-card class="card-signup text-center mb-5">
        <template v-slot:header>
          <h4 class="card-title text-primary">{{ $t("book_consultancy.title") }}</h4>
          <p >{{ $t("book_consultancy.subtitle") }} {{ product_name }}</p>
          <p class="font-weight-bold">{{ $t("book_consultancy.price") }}</p>
        </template>

        <Form @submit="bookConsultancy" :validation-schema="schema">
          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="surname"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.surname')"
                      addon-left-icon="now-ui-icons business_badge"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="name"
                      type="text"
                      :placeholder="'* ' + $t('common.name')"
                      addon-left-icon="now-ui-icons users_single-02"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <vee-input
                      name="address"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.address')"
                      addon-left-icon="now-ui-icons location_pin"
              />
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="country"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.country')"
                      addon-left-icon="now-ui-icons location_pin"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="fiscalNumber"
                      type="text"
                      :placeholder="$t('book_consultancy.tax_code')"
                      addon-left-icon="now-ui-icons business_badge"
              />
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="phone"
                      type="text"
                      :placeholder="$t('contacts.phone_number')"
                      addon-left-icon="fa fa-phone"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="email"
                      type="email"
                      :placeholder="$t('contacts.email_address')"
                      addon-left-icon="now-ui-icons ui-1_email-85"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <Field
                      name="notes"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="notes"
                        maxlength="1000"
                        :placeholder="$t('book_consultancy.message')"
                        show-word-limit
                        type="textarea"
                        rows="5"
                        v-model="notes">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <vee-checkbox class="text-left" name="privacy">
                <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('book_consultancy.privacy') }}
                  <a :href="privacy_policy_url" target="_blank">Privacy Policy</a>.
                </p>
              </vee-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <n-button type="primary" native-type="submit" round :disabled="buttonDisabled" class="mt-4">
                {{ $t('book_consultancy.book') }}
              </n-button>
            </div>
          </div>

          <div v-if="saving" class="col-12 mt-2"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>

        </Form>
      </vnud-card>
    </div>
    <input type="hidden" name="lookup_key" :value="stripe_price_lookup_key"/>
  </div>

</template>

<script>
  import { VeeCheckbox, VeeInput } from "src/components";
  import { ElSelect, ElOption, ElInput } from "element-plus";
  import { Form, Field } from "vee-validate";
  import * as Yup from "yup";
  import axios from "axios";
  import Swal from "sweetalert2";

  export default {
    name: "ReserveConsultancy",
    components: {
      VeeInput,
      VeeCheckbox,
      Form,
      Field,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElInput.name]: ElInput,
    },
    data() {
      return {
        error: '',
        buttonDisabled: false,
        saving: false,
        notes: "",
        privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
        product_name: process.env.VUE_APP_PRODUCT_NAME,
        stripe_price_lookup_key: "",
        stripeSessionUrl: ""
      }
    },
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('book_consultancy');
      },
      async bookConsultancy(values, actions) {
        // actions.setFieldValue('message', this.message);
        this.saving = true;
        this.error = '';
        this.buttonDisabled = true;

        values.captchaToken = await this.recaptcha();

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'consultancies', values)
        .then(response => {
          if(response.data.stripeCheckoutSessionUrl) {
                // this.stripe_checkout_item.value.redirectToCheckout();
              window.open(response.data.stripeCheckoutSessionUrl, "_self");
          }
          else if (response.data.message)
            this.error = this.$i18n.t(response.data.message);
          else
            this.error = this.$i18n.t("error.try_again");

        }).catch(error => {
          if (error.response && error.response.data && error.response.data.violations &&
                  error.response.data.violations.length > 0) {
            for (let i = 0; i < error.response.data.violations.length; i++)
            {
              let stringErr = error.response.data.violations[i].message;
              if(stringErr.endsWith("."))
                stringErr = stringErr.slice(0, -1);
              this.error += this.$i18n.t("error." + stringErr) + ". ";
            }
          } else if (error.response && error.response.data && error.response.data.message)
          {
            let stringErr = error.response.data.message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error = this.$i18n.t("error." + stringErr);
          }
          else this.error = this.$i18n.t("error.try_again");
          console.log(error);
        }).finally(() => {this.buttonDisabled = false; this.saving = false;});

      },
    },
    setup() {
      const schema = Yup.object().shape({
        name: Yup.string().required(),
        surname: Yup.string().required(),
        address: Yup.string().required().max(75),
        country: Yup.string().required().max(75),
        fiscalNumber: Yup.string().nullable(),
        email: Yup.string().email().when("phone", {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().email().required(
                { key: "yup.at_least_one", values: {one: "email", two: "phone"} }),
        }),
        phone: Yup.string().when("email", {
            is: (email) => !email || email.length === 0,
            then: Yup.string().min(10).max(14).required(
                { key: "yup.at_least_one", values: {one: "email", two: "phone"} }),
        }),
        notes: Yup.string().max(1000),
        privacy: Yup.boolean().oneOf([true], ""),
      }, ['email', 'phone']);

      let stripeScript = document.createElement('script');
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(stripeScript);

      return {
        schema,
      };
    },
  }
</script>
<style>
</style>

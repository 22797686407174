import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {faEnvelopeOpen} from "@fortawesome/free-solid-svg-icons";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faFileSignature} from "@fortawesome/free-solid-svg-icons";
import {faSignature} from "@fortawesome/free-solid-svg-icons";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {faEye} from "@fortawesome/free-solid-svg-icons";

library.add(faPhoneAlt);
library.add(faSignInAlt);
library.add(faEnvelope);
library.add(faEnvelopeOpen);
library.add(faTrash);
library.add(faHistory);
library.add(faExclamationTriangle);
library.add(faGlobe);
library.add(faFileSignature);
library.add(faSignature);
library.add(faInfo);
library.add(faEye);

export default FontAwesomeIcon;

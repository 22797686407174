<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-6 m-auto">

        <vnud-card centered class="text-center p-5">
          <template v-slot:header>
            <font-awesome-icon :icon="icon" size="4x" class="text-primary"></font-awesome-icon>
            <h4 class="card-title text-center">{{ $t('registration.email_verification') }}</h4>
            <br />
          </template>

          <div class="row">
            <div class="col-12">
              <p style="font-size: 16px" v-html="$t('registration.please_verify_email')"></p>

            </div>
          </div>

        </vnud-card>

      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "email-verification",
  components: {
  },
  data() {
    return {
      error: null,
      buttonDisabled: false,
      saving: false,
      icon: "envelope"
    }
  },
  methods: {

  },
  setup() {
  },
  beforeMount() {
    setInterval(() => {
      if(this.icon === "envelope")
        this.icon = "envelope-open";
      else
        this.icon = "envelope";
    }, 500);
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>

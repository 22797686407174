<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                <p v-if="userData" class="m-1 text-center font-weight-bold">
                  <span v-if="userData.company">{{ userData.company.name }}</span>
                  <span v-else>
                    {{userData.email}} <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-12 col-xl-10 m-auto">
        <div class="card">
          <div class="card-header">
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title m-auto">
                  <i class="text-info now-ui-icons business_badge" style="font-size: 28px"></i> Upload documents
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6 col-xl-4 mt-3">

<!--                <el-upload action="" accept=".pdf,.txt,.doc,.docx,.csv,.xlsx"-->
<!--                           drag-->
<!--                           :auto-upload="false"-->
<!--                           :multiple="false"-->
<!--                           :show-file-list="false"-->
<!--                           :on-change="prepareDocument">-->

                <el-upload action="" accept=".pdf,.doc,.docx,.csv,.txt,image/*"
                           drag
                           ref="uploadComp"
                           :auto-upload="false"
                           :multiple="true"
                           :show-file-list="true"
                           :limit="maxUpload"
                           :on-exceed="handleExceed"
                           :on-change="prepareDocument"
                           :on-remove="removeDocument"
                >
                  <div class="el-upload__text">
                    <i class="now-ui-icons arrows-1_share-66 text-center my-3" style="font-size: 26px"></i>
                    Drop file here or <em>click to upload</em>
                    <br>Accepted: pdf, txt, word files (doc, docx), csv, excel files (xlsx), images
                  </div>

                </el-upload>

<!--                <p v-if="form.fileName" class="text-center font-weight-bold">{{form.fileName}}</p>-->

              </div>
              <div class="col-12 col-md-6 col-xl-4 mt-3">
                <label class="mb-0">Select folder:</label>
                <el-select v-model="form.folder" class="d-block" id="select_folder">
                  <el-option value="archive" :label="$t('documents_folders.archive')" key="archive"></el-option>
                  <el-option value="companyHouse" :label="$t('documents_folders.companyHouse')" key="companyHouse"></el-option>
                  <el-option value="shareholders" :label="$t('documents_folders.shareholders')" key="shareholders"></el-option>
                  <el-option value="resolutions" :label="$t('documents_folders.resolutions')" key="resolutions"></el-option>
                  <hr/>
                  <el-option value="invoiceSent" :label="$t('documents_folders.invoiceSent') + ' (customer folder)'" key="invoiceSent"></el-option>
                  <el-option value="invoiceReceived" :label="$t('documents_folders.invoiceReceived') + ' (customer folder)'" key="invoiceReceived"></el-option>
                  <el-option value="bankStatement" :label="$t('documents_folders.bankStatement') + ' (customer folder)'" key="bankStatement"></el-option>
                </el-select>


                <label class="mb-0">Visible to client:</label>
                <el-select v-model="form.visible" class="d-block">
                  <el-option value="true" :label="$t('common.yes')" key="true"></el-option>
                  <el-option value="false" :label="$t('common.no')" key="false"></el-option>
                </el-select>

              </div>
              <div class="col-12 col-md-6 col-xl-4 mt-3">
                <label>Notes:</label>
                <el-input
                        v-model="form.description"
                        name="description"
                        maxlength="500"
                        show-word-limit
                        rows="6"
                        type="textarea">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <div v-if="form.folder === 'companyHouse' || form.folder === 'shareholders' || form.folder === 'resolutions'">
                  <label class="mb-0 d-block mt-4">Needs a signature (Only PDF documents can be signed):</label>
                  <el-select v-model="form.signature" class="">
                    <el-option value="no" :label="$t('common.no')" key="false"></el-option>
                    <el-option value="yes" :label="$t('common.yes')" key="true"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="col-12 col-md-6 col-xl-4">
                <div v-if="categories && categories.length > 0 && form.folder === 'invoiceReceived'" class="mt-4">
                  <label class="mb-0">{{ $t('document.category') }}:</label>
                  <el-select class="select-default d-block" v-model="form.category" label="" value="" clearable :placeholder="$t('common.select')">
                    <el-option v-for="cat in categories" v-bind:key="cat.id" class="select-default" :value="cat.id" :label="$t('categories.name.' + cat.code)">
                      {{ $t('categories.name.' + cat.code) }}
                    </el-option>
                  </el-select>
                </div>
              </div>


              <div class="col-12 col-xl-4 mt-3 mt-md-5 text-center mx-auto">
                <n-button class="btn btn-primary m-auto" :disabled="loading || saving" v-on:click="uploadDocument">Add document</n-button>
              </div>

              <div class="col-12 mt-3 text-center" v-if="error || saving">
                <p v-if="saving" class="text-danger">{{ $t("common.saving") }}...</p>
                <p v-if="error" class="text-danger">{{ error }}</p>
              </div>

            </div>

          </div>
        </div>
      </div>



      <div class="col-12 col-xl-10 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title">Available folders</h4>
                <n-button v-for="f in folders" :key="f"
                          class="m-auto"
                          :class="activeFolder === f? 'btn-outline-default' : 'btn-link'"
                          :disabled="loading || saving"
                          v-on:click="loadDocs(f, currentPageNumber, pageSize)">
                  {{ $t('documents_folders.' + f) }}
                </n-button>


              </div>
              <div class="col-12 text-center mt-3">
                <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
              </div>

              <div class="col-12 col-lg-6">
                <div class="row mt-lg-5 mb-n2">
                  <div class="col-6 col-xl-5 text-center">
                    <n-button class="m-auto btn-default btn-sm"
                              :disabled="loading || saving || selectedDocuments.length === 0"
                              v-on:click="downloadSelectedDocuments"
                    >
                      <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      {{ $t('common.download') }} {{ selectedDocuments.length }} {{ $t('common.selected') }}
                    </n-button>
                  </div>

                  <div class="col-6 col-xl-5 text-center">
                    <n-button class="m-auto btn-default btn-sm"
                              :disabled="loading || saving || selectedDocuments.length === 0"
                              v-on:click="deleteSelectedDocuments"
                    >
                      <font-awesome-icon icon="trash"></font-awesome-icon>
                      {{ $t('common.delete') }} {{ selectedDocuments.length }} {{ $t('common.selected') }}
                    </n-button>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6 my-2">
                <label class="mt-3">{{ $t('document.search_doc') }}</label>
                <el-input
                        v-model="searchDocument"
                        type="text"
                        name="searchDocument"
                        v-on:change="handleSearchDocument"
                        v-on:clear="loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize)"
                        clearable
                        :disabled="loading"
                        :placeholder="$t('common.search_info')">
                  <template #prepend><i class="el-icon-search"></i></template>
                </el-input>
              </div>

              <div class="col-12 mt-5">

                <el-table class="table-striped"
                          :data="documents[activeFolder]"
                          @selection-change="documentSelection"
                          border style="width: 100%">

                  <el-table-column type="selection" width="55"></el-table-column>

                  <el-table-column key="Date" :min-width="120" prop="regDate" label="Upload Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.uploadDate, true) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="name" :min-width="160" prop="name" label="File"></el-table-column>
                  <el-table-column key="description" :min-width="160" prop="description" label="Description">
                    <template #default="scope">
                      <div class="row">
                        <div class="col-11">
                          <el-input
                                  v-model="scope.row.description"
                                  name="description"
                                  maxlength="500"
                                  show-word-limit
                                  rows="2"
                                  type="textarea"
                                  :placeholder="$t('document.notes')"
                                  v-on:change="saveNewNote(scope.$index)"
                          >
                          </el-input>
                        </div>
                        <div class="col-1 px-0 m-auto">
                              <span v-if="scope.row.savingNewNote">
                                <i class="fa fa-spinner fa-spin text-warning fa-lg"></i>
                              </span>
                          <span v-else-if="scope.row.savedNewNote">
                                <i class="fa fa-check text-success fa-lg"></i>
                              </span>
                          <span v-else-if="scope.row.errorSavingNewNote">
                                <i class="fa fa-times text-danger fa-lg"></i>
                              </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column key="visible" :min-width="100" prop="visible" label="Visible to client">
                    <template #default="scope">
                      <span v-if="scope.row.visible">Yes</span>
                      <span v-else>No</span>
                    </template>
                  </el-table-column>
                  <el-table-column key="signature" :min-width="100" prop="signature" label="Signature">
                    <template #default="scope">
                      <span v-if="scope.row.signature && scope.row.signature === 'toSign'">Required</span>
                      <span v-else-if="scope.row.signature && scope.row.signature === 'signed'">Signed</span>
                    </template>
                  </el-table-column>
                  <el-table-column key="actions" :min-width="80" prop="id" label="Actions">
                    <template #default="scope">
                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="preview(scope.row.id, false)">
                        <font-awesome-icon icon="eye"></font-awesome-icon>
                      </n-button>

                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="downloadDocument(scope.row.id, scope.row.name, false)">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="deleteDocument(scope.row.id, false)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>


              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>




      <div class="col-12 col-xl-10 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title">Uploaded by customer</h4>
                <h5 class="title">Available folders</h5>
                <n-button v-for="f in customerFolders" :key="f"
                          class="m-auto"
                          :class="customerDocumentActiveFolder === f? 'btn-outline-default' : 'btn-link'"
                          :disabled="loading || saving"
                          v-on:click="loadCustomerDocs(f, customerDocumentCurrentPageNumber, customerDocumentPageSize)">
                  {{ $tc('documents_folders.' + f, 2) }}
                </n-button>


              </div>
              <div class="col-12 text-center mt-3">
                <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
              </div>

              <div class="col-12 col-lg-6">
                <div class="row mt-lg-5 mb-n2">
                  <div class="col-6 text-center">
                    <n-button class="m-auto btn-default btn-sm"
                              :disabled="loading || saving || selectedCustomerDocuments.length === 0"
                              v-on:click="downloadSelectedCustomerDocuments"
                    >
                      <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      Download {{ selectedCustomerDocuments.length }} selected
                    </n-button>
                  </div>

                  <div class="col-6 col-xl-5 text-center">
                    <n-button class="m-auto btn-default btn-sm"
                              :disabled="loading || saving || selectedCustomerDocuments.length === 0"
                              v-on:click="deleteSelectedCustomerDocuments"
                    >
                      <font-awesome-icon icon="trash"></font-awesome-icon>
                      Delete {{ selectedCustomerDocuments.length }} selected
                    </n-button>
                  </div>
                </div>
              </div>

              <div class="col-12 col-lg-6 my-2">
                <label class="mt-3">{{ $t('document.search_doc') }}</label>
                <el-input
                        v-model="searchCustomerDocument"
                        type="text"
                        name="searchDocument"
                        v-on:change="handleSearchCustomerDocument"
                        v-on:clear="loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);"
                        clearable
                        :disabled="loading"
                        :placeholder="$t('common.search_info')">
                  <template #prepend><i class="el-icon-search"></i></template>
                </el-input>
              </div>

              <div class="col-12 mt-5">
                <el-table class="table-striped"
                          :data="customerDocuments[customerDocumentActiveFolder]"
                          @selection-change="customerDocumentSelection"
                          border style="width: 100%">

                  <el-table-column type="selection" width="55"></el-table-column>

                  <el-table-column key="Date" :min-width="120" prop="regDate" label="Upload Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.uploadDate, true) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="name" :min-width="120" prop="name" label="File"></el-table-column>
                  <el-table-column key="description" :min-width="120" prop="notes" label="Description">
                    <template #default="scope">
                      <div class="row">
                        <div class="col-11">
                          <el-input
                                  v-model="scope.row.notes"
                                  name="notes"
                                  maxlength="500"
                                  show-word-limit
                                  rows="2"
                                  type="textarea"
                                  :placeholder="$t('document.notes')"
                                  v-on:change="saveNewCustomerNote(scope.$index)"
                          >
                          </el-input>
                        </div>
                        <div class="col-1 px-0 m-auto">
                              <span v-if="scope.row.savingNewNote">
                                <i class="fa fa-spinner fa-spin text-warning fa-lg"></i>
                              </span>
                          <span v-else-if="scope.row.savedNewNote">
                                <i class="fa fa-check text-success fa-lg"></i>
                              </span>
                          <span v-else-if="scope.row.errorSavingNewNote">
                                <i class="fa fa-times text-danger fa-lg"></i>
                              </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column key="category" :min-width="120" prop="category" label="Category" v-if="customerDocumentActiveFolder === 'invoiceReceived'">
                    <template #default="scope">
<!--                      <span v-if="scope.row.category">{{ $t('categories.name.' + scope.row.category.code) }}</span>-->
                      <div class="row">
                        <div class="col-11">
                          <el-select class="d-block select-default" label="" clearable
                                     v-model="scope.row.category"
                                     value-key="id"
                                     :placeholder="$t('common.select')"
                                     v-on:change="saveNewCategory(scope.$index)"
                          >
                            <el-option v-for="cat in categories" v-bind:key="cat.id" class="select-default" :value="cat" :label="$t('categories.name.' + cat.code)">
                              {{ cat.code }} - {{ $t('categories.name.' + cat.code) }}
                            </el-option>
                          </el-select>
                        </div>
                        <div class="col-1 px-0 m-auto">
                          <span v-if="scope.row.category && scope.row.category.saving">
                            <i class="fa fa-spinner fa-spin text-warning fa-lg"></i>
                          </span>
                          <span v-else-if="scope.row.category && scope.row.category.saved">
                            <i class="fa fa-check text-success fa-lg"></i>
                          </span>
                          <span v-else-if="scope.row.category && scope.row.category.errorSaving">
                            <i class="fa fa-times text-danger fa-lg"></i>
                          </span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column key="actions" :min-width="60" prop="id" label="Actions">
                    <template #default="scope">
                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="preview(scope.row.id, true)">
                        <font-awesome-icon icon="eye"></font-awesome-icon>
                      </n-button>
                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="downloadDocument(scope.row.id, scope.row.name, true)">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                :disabled="loading || saving"
                                v-on:click="deleteDocument(scope.row.id, true)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>


              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="customerDocumentPageSize" value="" label=""
                               v-on:change="customerDocumentHandlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="customerDocumentCurrentPageNumber"
                            v-model:page-size="customerDocumentPageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="customerDocumentTotalItems"
                            @current-change="customerDocumentHandlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ customerDocumentTotalItems }}</p>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElUpload, ElButton, ElPagination, ElCheckbox } from "element-plus";
  import NButton from "../../../components/Button";
  import Swal from "sweetalert2";

  export default {
    name: "ManageDocuments",
    components: {
      NButton,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElInput.name]: ElInput,
      [ElDatePicker.name]: ElDatePicker,
      [ElUpload.name]: ElUpload,
      [ElButton.name]: ElButton,
      [ElPagination.name]: ElPagination,
      [ElCheckbox.name]: ElCheckbox
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        userData: null,
        folders: ["archive", "companyHouse", "shareholders", "resolutions"],
        customerFolders: ["invoiceSent", "invoiceReceived", "bankStatement", "companyHouse", "shareholders", "resolutions"],
        documents: {
          archive: [],
          companyHouse: [],
          shareholders: [],
          resolutions: []
        },
        customerDocuments: {
            companyHouse: [],
            shareholders: [],
            invoiceSent: [],
            invoiceReceived: [],
            bankStatement: [],
            resolutions: []
          },
        activeFolder: "archive",
        customerDocumentActiveFolder: "invoiceSent",
        // form: {file: null, fileName: "",description: '', folder: "archive", visible: 'true'},
        form: {fileList: [], description: '', folder: "", visible: 'true', signature: "no", category: null},
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
        maxUpload: 10,
        listErrors: [],
        numFilesUploaded: 0,
        customerDocumentTotalItems: 0,
        customerDocumentCurrentPageNumber: 1,
        customerDocumentPageSize: 20,
        categories: [],
        searchDocument: null,
        searchCustomerDocument: null,
        selectedDocuments: [],
        selectedCustomerDocuments: [],
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else if (error.response && error.response.data && error.response.data['hydra:description'])
            this.error = this.$i18n.t("error." + error.response.data['hydra:description']);
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true) {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
      prepareDocument(file) {
        // this.form.file = new Blob([file.raw], {type: file.type});
        // this.form.fileName = file.name;
          let f = new Blob([file.raw], {type: file.type});
          let fileName = file.name;
          this.form.fileList.push({file: f, fileName: fileName});
      },
      removeDocument(file, uploadFiles) {
          let list = [];
          for (let obj of uploadFiles) {
              let f = new Blob([obj.raw], {type: obj.type});
              let fileName = obj.name;
              list.push({file: f, fileName: fileName});
          }
          this.form.fileList = list;
      },
      handleExceed(files, uploadFiles) {
          ElMessage.warning(
              `The limit is ${this.maxUpload}, you selected ${files.length} files`
          )
      },
      uploadDocument() {
          // this.saving = true;
          this.error = null;

          this.numFilesUploaded = this.form.fileList.length;
          if(this.form.signature === "yes" &&
              (this.form.folder === 'companyHouse' || this.form.folder === 'shareholders' || this.form.folder === 'resolutions')) {
              for (let i=0; i<this.form.fileList.length; i++) {
                  let n = this.form.fileList[i].fileName.toLowerCase();
                  if (!n.endsWith(".pdf")) {
                      this.error = this.$t('document.error_pdf_only');
                      return;
                  }
              }
          }

          for (let i=0; i<this.form.fileList.length; i++) {

              // console.log("type: " + this.form.fileList[i].file.type);

            let data = new FormData();
            data.append('file', this.form.fileList[i].file, this.form.fileList[i].fileName);
            data.append('user', this.userData.id);
            data.append('description', this.form.description);
            data.append('folder', this.form.folder);
            data.append('visible', (this.form.visible === 'true'));

            if(this.form.signature === "yes" && (this.form.folder === 'companyHouse' || this.form.folder === 'shareholders' || this.form.folder === 'resolutions'))
                data.append('signature', "toSign");

            let url = "documents";
            if (this.form.folder === "invoiceSent" || this.form.folder ===  "invoiceReceived" || this.form.folder === "bankStatement") {
                url = "customer_documents";
                data.append('notes', this.form.description);
            }
            if (this.form.folder === 'invoiceReceived')
                data.append('category', this.form.category);

            axios.post(process.env.VUE_APP_SERVER_BASE_URL + url, data)
            .then(response => {}).catch(error => {
              if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                  this.$store.commit('logout');
                  this.$router.push('/login');
              }
              else
                  this.listErrors.push({message: this.form.fileList[i].fileName + ": " + error, indexFile: i});
            }).finally(() => {this.endOfSave();});
          }

      },
      endOfSave() {
          if(this.numFilesUploaded === this.form.fileList.length) {
              let newFileList = [];
              for (let i=0; i<this.listErrors.length; i++)
              {
                  let f = this.form.fileList[this.listErrors[i].indexFile].file;
                  let fileName = this.form.fileList[this.listErrors[i].indexFile].fileName;
                  newFileList.push({file: f, fileName: fileName});
              }
              this.form.fileList = newFileList;

              if (this.form.fileList.length === 0)
              {
                  ElMessage.success("Document successfully uploaded!");
                  if (this.activeFolder === this.form.folder)
                      this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
                  this.form.description = '';
              }
              else
              {
                  let error = {response: {data: {violations: this.listErrors}}};
                  this.showError(error);
              }
              this.saving = false;
              this.numFilesUploaded = 0;
              this.$refs.uploadComp.clearFiles();
          }
      },
      preview(docIri, isCustomer) {
          let id = docIri.split("/");
          id = id[id.length-1];
          let url = 'download-document/';
          if (isCustomer)
              url = 'download-customer_document/';
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + url + id, {responseType: 'blob'})
              .then(response => {
                  console.log(response.headers['content-type']);
                  const blob = new Blob([response.data], {type: response.headers['content-type']});
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.target = "_blank";
                  link.click();
                  URL.revokeObjectURL(link.href);
              }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      downloadDocument(docIri, nomeFile, isCustomer) {
        let id = docIri.split("/");
        id = id[id.length-1];
        let url = 'download-document/';
        if (isCustomer)
            url = 'download-customer_document/';
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + url + id, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = nomeFile;
            link.click();
            URL.revokeObjectURL(link.href);
          }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      handlePageSizeChange () {
          this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
      },
      handlePageChange (val) {
          this.loadDocs(this.activeFolder, val, this.pageSize);
      },
      loadDocs(folder, page, pageSize) {
        this.loading = true;
        this.error = null;

        let filter = "";
        if (this.searchDocument && this.searchDocument.length > 2)
            filter = "&name=" + encodeURIComponent(this.searchDocument);

          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'documents/user?userRef.id=' + this.userData.id + '&folder=' + folder + '&itemsPerPage=' + pageSize + '&page=' + page + filter)
          .then(response => {
            this.documents[folder] = response.data["hydra:member"];
              this.totalItems = response.data["hydra:totalItems"];
              this.activeFolder = folder;
              this.currentPageNumber = page;
          }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      deleteDocument(id, isCustomer) {
        this.saving = true;
          let url = 'documents/';
          if (isCustomer)
              url = 'customer_documents/';
        axios.delete(process.env.VUE_APP_SERVER_BASE_URL + url + id)
          .then(response => {
            if (isCustomer)
              this.loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);
            else
              this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
            ElMessage.success("successfully deleted.");
          }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      customerDocumentHandlePageSizeChange () {
            this.loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);
        },
      customerDocumentHandlePageChange (val) {
            this.loadCustomerDocs(this.customerDocumentActiveFolder, val, this.customerDocumentPageSize);
        },
      loadCustomerDocs(folder, page, pageSize) {
            this.loading = true;
            this.error = null;

          let filter = "";
          if (this.searchCustomerDocument && this.searchCustomerDocument.length > 2)
              filter = "&name=" + encodeURIComponent(this.searchCustomerDocument);

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/user?userRef.id=' + this.userData.id + '&folder=' + folder + '&itemsPerPage=' + pageSize + '&page=' + page + filter)
                .then(response => {
                    this.customerDocuments[folder] = response.data["hydra:member"];
                    this.customerDocumentTotalItems = response.data["hydra:totalItems"];
                    this.customerDocumentActiveFolder = folder;
                    this.customerDocumentCurrentPageNumber = page;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
      handleSearchDocument() {
          if (this.searchDocument && this.searchDocument.length > 2)
              this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
      },
      handleSearchCustomerDocument() {
          if (this.searchCustomerDocument && this.searchCustomerDocument.length > 2)
              this.loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);
      },
      saveNewCategory(index) {
          this.customerDocuments['invoiceReceived'][index].category.saving = true;
          this.customerDocuments['invoiceReceived'][index].category.saved = false;
          this.customerDocuments['invoiceReceived'][index].category.errorSaving = false;

          axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "customer_documents/" + this.customerDocuments['invoiceReceived'][index].id,
              {category: this.customerDocuments['invoiceReceived'][index].category['@id']},
              {headers: {'Content-Type': 'application/merge-patch+json'}
          }).then((response) => {
              this.customerDocuments['invoiceReceived'][index].category.saved = true;
          }).catch(error => {
              this.showError(error);
              this.customerDocuments['invoiceReceived'][index].category.errorSaving = true;
          }).finally(() => {
              this.customerDocuments['invoiceReceived'][index].category.saving = false;
          });
      },
      saveNewNote(index) {
          this.documents[this.activeFolder][index].savingNewNote = true;
          this.documents[this.activeFolder][index].savedNewNote = false;
          this.documents[this.activeFolder][index].errorSavingNewNote = false;

          axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "documents/" + this.documents[this.activeFolder][index].id,
              {description: this.documents[this.activeFolder][index].description},
              {headers: {'Content-Type': 'application/merge-patch+json'}
              }).then((response) => {
              this.documents[this.activeFolder][index].savedNewNote = true;
          }).catch(error => {
              this.showError(error);
              this.documents[this.activeFolder][index].errorSavingNewNote = true;
          }).finally(() => {
              this.documents[this.activeFolder][index].savingNewNote = false;
          });
      },
      saveNewCustomerNote(index) {
            this.customerDocuments[this.customerDocumentActiveFolder][index].savingNewNote = true;
            this.customerDocuments[this.customerDocumentActiveFolder][index].savedNewNote = false;
            this.customerDocuments[this.customerDocumentActiveFolder][index].errorSavingNewNote = false;

            axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "customer_documents/" + this.customerDocuments[this.customerDocumentActiveFolder][index].id,
                {notes: this.customerDocuments[this.customerDocumentActiveFolder][index].notes},
                {headers: {'Content-Type': 'application/merge-patch+json'}
                }).then((response) => {
                this.customerDocuments[this.customerDocumentActiveFolder][index].savedNewNote = true;
            }).catch(error => {
                this.showError(error);
                this.customerDocuments[this.customerDocumentActiveFolder][index].errorSavingNewNote = true;
            }).finally(() => {
                this.customerDocuments[this.customerDocumentActiveFolder][index].savingNewNote = false;
            });
        },
      documentSelection(items) {
          this.selectedDocuments = items;
      },
      downloadSelectedDocuments() {
          this.loading = true;

          let ids = "";
          for(let i=0; i<this.selectedDocuments.length; i++) {
              ids += this.selectedDocuments[i].id;
              if(i<this.selectedDocuments.length -1)
                  ids += ",";
          }

          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'documents/get_zip_selected?ids=' + ids, {responseType: 'blob'})
              .then(response => {
                  const blob = new Blob([response.data],{type:'application/zip'});
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = this.userData.id + "_documents.zip";
                  link.click();
                  URL.revokeObjectURL(link.href);
              }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      deleteSelectedDocuments() {
          Swal.fire({
              title: this.$i18n.t("document.delete_docs"),
              text: this.$i18n.t("document.going_to_delete") + this.selectedDocuments.length + " " +
                    this.$i18n.tc("document.document", this.selectedDocuments.length > 1? 2 : 1) +
                    this.$i18n.t("document.from_folder") + this.$i18n.t('documents_folders.' + this.activeFolder) +
                    ". " + this.$i18n.t("common.proceed") + "?",
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              confirmButtonColor: '#FF3636'
          }).then((result) => {
              if (result.isConfirmed) {
                  Swal.showLoading();
                  let count = this.selectedDocuments.length;

                  for(let document of this.selectedDocuments) {
                      axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'documents/' + document.id)
                      .then(response => {}).catch(error => {
                        this.showError(error);
                      }).finally(() => {
                          count = count - 1;
                          if (count === 0) {
                              Swal.hideLoading();
                              this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
                          }
                      });
                  }
              }
          });
      },
      customerDocumentSelection(items) {
          this.selectedCustomerDocuments = items;
      },
      downloadSelectedCustomerDocuments() {
          this.loading = true;

          let ids = "";
          for(let i=0; i<this.selectedCustomerDocuments.length; i++) {
              ids += this.selectedCustomerDocuments[i].id;
              if(i<this.selectedCustomerDocuments.length -1)
                  ids += ",";
          }

          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/get_zip_selected?ids=' + ids, {responseType: 'blob'})
              .then(response => {
                  const blob = new Blob([response.data],{type:'application/zip'});
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = this.userData.id + "_customer_documents.zip";
                  link.click();
                  URL.revokeObjectURL(link.href);
              }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      deleteSelectedCustomerDocuments() {
          Swal.fire({
              title: this.$i18n.t("document.delete_docs"),
              text: this.$i18n.t("document.going_to_delete") + this.selectedCustomerDocuments.length + " " +
                  this.$i18n.tc("document.document", this.selectedCustomerDocuments.length > 1? 2 : 1) +
                  this.$i18n.t("document.from_folder") + this.$i18n.t('documents_folders.' + this.customerDocumentActiveFolder) +
                  ". " + this.$i18n.t("common.proceed") + "?",
              showCancelButton: true,
              confirmButtonText: 'Proceed',
              confirmButtonColor: '#FF3636'
          }).then((result) => {
              if (result.isConfirmed) {
                  Swal.showLoading();
                  let count = this.selectedCustomerDocuments.length;

                  for(let document of this.selectedCustomerDocuments) {
                      axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/' + document.id)
                      .then(response => {}).catch(error => {
                          this.showError(error);
                      }).finally(() => {
                          count = count - 1;
                          if (count === 0) {
                              Swal.hideLoading();
                              this.loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);
                          }
                      });
                  }
              }
          });
      },
    },
    computed: {
    },
    beforeMount() {
      let fetchedId = this.$route.params.userid;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
      .then(response => {
        this.userData = response.data;
        this.loadDocs(this.activeFolder, this.currentPageNumber, this.pageSize);
        this.loadCustomerDocs(this.customerDocumentActiveFolder, this.customerDocumentCurrentPageNumber, this.customerDocumentPageSize);
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'categories')
      .then(response => {
          this.categories = response.data["hydra:member"];
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style>
  .el-upload-dragger {
    width: 100% !important;
    height: 150px;
  }
  .el-upload{
    width: 100% !important;
  }
</style>

<template>

  <div class="row">

    <div class="col-lg-7 m-auto order-1 order-lg-2">

      <vnud-card v-if="loading" class="card-signup text-center mb-5">
        <div class="col-12 my-2 font-weight-bold text-danger">
          <i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}
        </div>
      </vnud-card>

      <vnud-card v-else-if="product === null" class="card-signup text-center mb-5">
        No product found.
      </vnud-card>

      <vnud-card v-else class="card-signup text-center mb-5">
        <template v-slot:header>
          <h4 class="card-title text-primary">{{ product.name }}</h4>
          <p >{{ product.description }}</p>
          <p class="font-weight-bold">
            {{ $t("buy_product.price") }}
            <span v-if="product.currency">{{product.currency}}</span>
            <span v-else>€</span>
            {{ product.priceMonth }}
          </p>
        </template>

        <Form @submit="buyProduct" :validation-schema="schema">
          <div v-if="error" class="text-danger text-center">{{ error }}</div>

          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="surname"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.surname')"
                      addon-left-icon="now-ui-icons business_badge"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="name"
                      type="text"
                      :placeholder="'* ' + $t('common.name')"
                      addon-left-icon="now-ui-icons users_single-02"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <vee-input
                      name="address"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.address')"
                      addon-left-icon="now-ui-icons location_pin"
              />
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="country"
                      type="text"
                      :placeholder="'* ' + $t('book_consultancy.country')"
                      addon-left-icon="now-ui-icons location_pin"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="fiscalNumber"
                      type="text"
                      :placeholder="$t('book_consultancy.tax_code')"
                      addon-left-icon="now-ui-icons business_badge"
              />
            </div>
          </div>


          <div class="row">
            <div class="col-md-6">
              <vee-input
                      name="phone"
                      type="text"
                      :placeholder="$t('contacts.phone_number')"
                      addon-left-icon="fa fa-phone"
              />
            </div>

            <div class="col-md-6">
              <vee-input
                      name="email"
                      type="email"
                      :placeholder="'* ' + $t('contacts.email_address')"
                      addon-left-icon="now-ui-icons ui-1_email-85"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <Field
                      name="notes"
                      type="text"
                      v-slot="{ value, field, errorMessage }">
                <el-input
                        v-bind="field"
                        :model-value="value"
                        name="notes"
                        maxlength="1000"
                        :placeholder="$t('buy_product.message')"
                        show-word-limit
                        type="textarea"
                        rows="5"
                        v-model="notes">
                </el-input>
                <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
                  <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
                  <div v-else>{{ $t(errorMessage) }}</div>
                </div>
              </Field>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <vee-checkbox class="text-left" name="privacy">
                <p class="m-0 p-0"><b class="text-danger">*</b> {{ $t('book_consultancy.privacy') }}
                  <a :href="privacy_policy_url" target="_blank">Privacy Policy</a>.
                </p>
              </vee-checkbox>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <n-button type="primary" native-type="submit" round :disabled="buttonDisabled" class="mt-4">
                {{ $t('common.buy') }}
              </n-button>

              <p>{{$t('buy_product.data_for_invoice')}} {{$t('buy_product.email_for_invoice')}}</p>
            </div>
          </div>

          <div v-if="saving" class="col-12 mt-2"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>

        </Form>
      </vnud-card>
    </div>
    <input type="hidden" name="lookup_key" :value="stripe_price_lookup_key"/>
  </div>

</template>

<script>
  import { VeeCheckbox, VeeInput } from "src/components";
  import { ElSelect, ElOption, ElInput, ElMessage } from "element-plus";
  import { Form, Field } from "vee-validate";
  import * as Yup from "yup";
  import axios from "axios";

  export default {
    name: "BuyProduct",
    components: {
      VeeInput,
      VeeCheckbox,
      Form,
      Field,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElInput.name]: ElInput,
    },
    data() {
      return {
        product: null,
        error: '',
        buttonDisabled: false,
        loading:false,
        saving: false,
        notes: "",
        privacy_policy_url: process.env.VUE_APP_PRIVACY_POLICY_URL,
        stripe_price_lookup_key: "",
        stripeSessionUrl: ""
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        // NO LOGIN.
        if (error.response && error.response.data && error.response.data.violations &&
            error.response.data.violations.length > 0) {
            for (let i = 0; i < error.response.data.violations.length; i++)
            {
                let stringErr = error.response.data.violations[i].message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error += this.$i18n.t(stringErr) + ". ";
            }
        } else if(error.response && error.response.data && error.response.data.message)
        {
            let stringErr = error.response.data.message;
            if(stringErr.endsWith("."))
                stringErr = stringErr.slice(0, -1);
            this.error = this.$i18n.t("error." + stringErr);
        }
        else if(error.response && error.response.data && error.response.data["hydra:description"])
            this.error = error.response.data["hydra:description"];
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      async recaptcha() {
        await this.$recaptchaLoaded();
        return await this.$recaptcha('buy_product');
      },
      async buyProduct(values, actions) {
        // actions.setFieldValue('message', this.message);
        this.saving = true;
        this.error = '';
        this.buttonDisabled = true;

        values.captchaToken = await this.recaptcha();
        values.productRef = this.product["@id"];

        axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'external_purchases', values)
        .then(response => {
          if(response.data.stripeCheckoutSessionUrl) {
                // this.stripe_checkout_item.value.redirectToCheckout();
              window.open(response.data.stripeCheckoutSessionUrl, "_self");
          }
          else if (response.data.message)
              this.error = this.$i18n.t(response.data.message);
          else
              this.error = this.$i18n.t("error.try_again");
        }).catch(error => {
          this.showError(error);
        }).finally(() => {this.buttonDisabled = false; this.saving = false;});

      },
    },
    setup() {
      const schema = Yup.object().shape({
        name: Yup.string().required(),
        surname: Yup.string().required(),
        address: Yup.string().required().max(75),
        country: Yup.string().required().max(75),
        fiscalNumber: Yup.string().nullable(),
        email: Yup.string().email().required(),
        phone: Yup.string(),
        notes: Yup.string().max(1000),
        privacy: Yup.boolean().oneOf([true], ""),
      }, ['email', 'phone']);

      let stripeScript = document.createElement('script');
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.head.appendChild(stripeScript);

      return {
        schema,
      };
    },
    beforeMount() {
        this.loading = true;
        let fetchedId = this.$route.params.productId;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions/find_by_link/' + fetchedId)
        .then(response => {
            this.product = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    }
  }
</script>
<style>
</style>

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                <p v-if="userData" class="m-1 text-center font-weight-bold">
                  {{userData.email}} <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-6" style="border-right: 1px solid lightgray;">
                <h5>Connected Accounts:</h5>
                <p v-if="!loading && connections.length === 0">No bank account connected yet.</p>

                  <template v-for="(c, index) in connections" v-bind:key="c.id">

                    <div class="col-12 col-md-6 col-lg-12 col-xl-8 mx-auto p-2 my-1 text-center" style="border: 1px solid grey; border-radius: 8px">
                      <p class="m-0" style="font-size: 16px">{{c.bankName.toUpperCase()}}</p>
                      <p class="m-1" v-if="c.connectionStatus === 'expired'">
                        <span v-if="new Date(c.connectionExpirationDate) > new Date()">
                          <b class="text-danger">{{ $t('bank.connection_revoked') }}</b>
                        </span>
                        <span v-else>
                          {{ $t('bank.expired_on') }} <b class="text-danger">{{formatDate(c.connectionExpirationDate, false)}}</b>
                        </span>
                      </p>
                      <p class="m-1" v-else>
                        {{ $t('bank.expires_on') }} <b>{{formatDate(c.connectionExpirationDate, false)}}</b>
                      </p>
                      <p class="m-1">last transactions update on <b><span v-if="c.transactionsLastUpdate">{{formatDate(c.transactionsLastUpdate, false)}}</span><span v-else>-</span></b></p>
                      <n-button native-type="button" type="primary" class="btn btn-sm"
                                :disabled="loading || saving" v-on:click="deleteBankConnection(index)">
                        DELETE
                      </n-button>
                    </div>

                  </template>

              </div>

              <div class="col-12 col-lg-6 text-center">
                <div>
                  <br><br>
                  <h5>Last synchronization:</h5>
                  <p v-if="userData && userData.bankTransactionsLastUpdate">{{ formatDate(userData.bankTransactionsLastUpdate, true) }}</p>
                  <p v-else>Never</p>
                  <n-button native-type="button" type="info" class="btn btn-sm btn-link"
                            :disabled="true">
                    <!--                            @click="refreshData()"-->
                    <i class="now-ui-icons arrows-1_refresh-69"></i> Manually Sync last data
                  </n-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h5>Get transactions</h5>

                <div class="row">
                  <div class="col-6 col-md-4 col-lg-3">
                    <n-button native-type="button" type="default" class="btn btn-sm"
                              @click="updateYear(1)"
                              :disabled="loading || saving">
                      last year
                    </n-button>
                  </div>

                  <div class="col-6 col-md-4 col-lg-3">
                    <n-button native-type="button" type="default" class="btn btn-sm"
                              @click="updateMonths(6)"
                              :disabled="loading || saving">
                      last semester
                    </n-button>
                  </div>

                  <div class="col-6 col-md-4 col-lg-3">
                    <n-button native-type="button" type="default" class="btn btn-sm"
                              @click="updateMonths(3)"
                              :disabled="loading || saving">
                      last quarter
                    </n-button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <label>From:</label>
                    <br>
                    <el-date-picker
                            v-model="startDate"
                            type="date"
                            format="DD/MM/YYYY"
                            value-format="DD/MM/YYYY"
                            :disabled-date="startDisabledDate">
                    </el-date-picker>
                  </div>

                  <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <label>To:</label>
                    <br>
                    <el-date-picker
                            v-model="endDate"
                            type="date"
                            format="DD/MM/YYYY"
                            value-format="DD/MM/YYYY"
                            :disabled-date="disabledDate">
                    </el-date-picker>
                  </div>
                </div>


                <div class="row mt-5">
                  <div class="col-6 col-md-4 col-lg-3">
                    <label class="mb-0">Select institution:</label>
                    <el-select v-model="institution" class="select-default" value="" label="">
                      <el-option class="select-default" key="all" label="All" value="all"></el-option>
                      <el-option v-for="c in connections" v-bind:key="c.id"
                                 class="select-default"
                                 :label="c.bankName"
                                 :value="c.id"></el-option>
                    </el-select>
                  </div>

                  <div class="col-6 col-md-4 col-lg-3">
                    <label class="mb-0">Select currency:</label>
                    <el-select v-model="currency" class="select-default" value="" label="">
                      <el-option class="select-default" key="all" label="All" value="all"></el-option>
                      <el-option class="select-default" key="EUR" label="EUR" value="EUR"></el-option>
                      <el-option class="select-default" key="GBP" label="GBP" value="GBP"></el-option>
                      <el-option class="select-default" key="USD" label="USD" value="USD"></el-option>
                    </el-select>
                  </div>

                  <div class="col-6 col-md-4 col-lg-3 mt-3">
                    <el-radio-group v-model="incomeExpense" class="ml-4 ">
                      <el-radio label="all" size="large">All</el-radio>
                      <br>
                      <el-radio label="onlyIncomes" size="large">only incomes</el-radio>
                      <br>
                      <el-radio label="onlyExpenses" size="large">only expenses</el-radio>
                    </el-radio-group>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-12 text-center">
                    <n-button native-type="button" type="primary" class="btn"
                              @click="getCsv()"
                              :disabled="loading || saving || connections.length === 0 || (userData && !userData.bankTransactionsLastUpdate)">
                      Get csv
                    </n-button>
                    <p v-if="userData && !userData.bankTransactionsLastUpdate" class="text-danger">Transaction data have never been synchronized for this customer. Please manually syncronize transaction data and try again.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>



<!--    <div class="row">-->
<!--      <div class="col-12 col-lg-10 col-xl-8 m-auto">-->
<!--        <el-table class="table" v-if="!loading" :data="transactions" border style="width: 100%">-->

<!--          <el-table-column key="Date" prop="date" label="Booking Date">-->
<!--            <template #default="scope">-->
<!--              {{ formatDate(scope.row.bookingDate, false) }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column key="Date" prop="date" label="Value Date">-->
<!--            <template #default="scope">-->
<!--              {{ formatDate(scope.row.valueDate, false) }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column key="amount" prop="amount" label="Amount">-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.amount }} {{ scope.row.currency }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column key="beneficiaryName" :min-width="90" prop="beneficiaryName" label="Beneficiary"></el-table-column>-->
<!--          <el-table-column key="originalCurrency" :min-width="90" prop="originalCurrency" label="Original Amount">-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.originalAmount }} {{ scope.row.originalCurrency }}-->
<!--              <br>( rate: {{ scope.row.exchangeRate }} )-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column key="fee" prop="fee" label="Fee">-->
<!--            <template #default="scope">-->
<!--              {{ scope.row.fee }} {{ scope.row.currency }}-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          &lt;!&ndash;          <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">&ndash;&gt;-->
<!--&lt;!&ndash;            <template #default="scope">&ndash;&gt;-->
<!--&lt;!&ndash;              <n-button native-type="button" type="info" class="btn btn-sm btn-icon"&ndash;&gt;-->
<!--&lt;!&ndash;                        :disabled="loading || saving"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-on:click="downloadDocument(scope.row.id, scope.row.name)">&ndash;&gt;-->
<!--&lt;!&ndash;                <i class="now-ui-icons arrows-1_cloud-download-93"></i>&ndash;&gt;-->
<!--&lt;!&ndash;              </n-button>&ndash;&gt;-->
<!--&lt;!&ndash;            </template>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-table-column>&ndash;&gt;-->
<!--        </el-table>-->
<!--      </div>-->
<!--    </div>-->


  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElCheckbox, ElRadioGroup, ElRadio } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ManageCustomerBanks",
    components: {
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElDatePicker.name]: ElDatePicker,
        [ElCheckbox.name]: ElCheckbox,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElRadioGroup.name]: ElRadioGroup,
        [ElRadio.name]: ElRadio,
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        token: null,
        userData: null,
        connections: [],
        transactions: [],
        startDate: new Date(),
        endDate: new Date(),
        currency: "all",
        incomeExpense: "all",
        institution: "all",
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            return time.getTime() > this.endDate;
        },
        // updateDates(yearsAgo, monthsAgo, weeksAgo) {
        //   this.startDate = new Date();
        //   this.startDate.setFullYear(this.startDate.getFullYear() - yearsAgo);
        //   this.startDate.setMonth(this.startDate.getMonth() - monthsAgo);
        //   let daysAgo = weeksAgo * 7;
        //   this.startDate.setDate(this.startDate.getDate() - daysAgo);
        //   this.endDate = new Date();
        // },
        updateYear(yearsAgo) {
            this.startDate = (new Date());
            this.startDate.setFullYear(this.startDate.getFullYear() - yearsAgo);
            this.startDate.setMonth(0);
            this.startDate.setDate(1);
            this.endDate = new Date(this.startDate.getFullYear(), 12, 0);
        },
        updateMonths(monthsAgo) {
            this.startDate = (new Date());
            this.startDate.setMonth(this.startDate.getMonth() - monthsAgo);
            this.startDate.setDate(1);
            let d = new Date();
            d.setMonth(d.getMonth() - 1);
            this.endDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        },
        refreshData() {
            this.loading = true;
            Swal.fire({
                title: 'Please wait',
                text: "The synchronization process may take a while.",
                padding: "2em",
                // width: 600,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'nordigen/refresh_transactions_data/?customer=' + this.userData.id, {timeout: 300000})
            .then(response => {
                this.userData.bankTransactionsLastUpdate = response.data.lastSyncDate.date;
                for (let c of this.connections)
                    c.transactionsLastUpdate = response.data.lastSyncDate.date;
                ElMessage.success("Transactions data successfully updated");
            }).catch(error => {this.showError(error);}).finally(() => {this.loading = false; Swal.close();});
        },
        getCsv() {
            this.loading = true;
            let sDate = this.startDate.getFullYear() + "-" + (this.startDate.getMonth()+1) + "-" + this.startDate.getDate();
            let eDate = this.endDate.getFullYear() + "-" + (this.endDate.getMonth()+1) + "-" + this.endDate.getDate();
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'bank_transactions/filter/?customer=' + this.userData.id +
                      "&bank=" + this.institution + "&currency=" + this.currency + "&start_date=" + sDate +
                      "&end_date=" + eDate + "&income_expenses=" + this.incomeExpense)
            .then(response => {
                this.transactions = response.data["hydra:member"];

                if(this.transactions.length === 0)
                    ElMessage.warning({
                        message: "No transaction detected with the selected query",
                        duration: 4000,
                        "custom-class": "font-weight-bold"
                    });
                else {
                    var jsonObject = JSON.stringify(this.transactions);
                    let csvContent = this.convertToCSV(jsonObject);
                    var encodedUri = encodeURI(csvContent);
                    var link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "transactions.csv");
                    document.body.appendChild(link);
                    link.click();
                }
            }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
        convertToCSV(objArray) {
          var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
          var csv = "data:text/csv;charset=utf-8,";
          // let ignore = ["@id", "@type",	"id",	"bankRef"];


            for (var i = 0; i < array.length; i++) {
              if (i === 0) {
                  let titles = "";
                  for (let index in array[i]) {
                  //     if (!ignore.includes(index)) {
                  //         if (index === 'bankAccount') {
                  //             for (let index_sub in array[i][index])
                  //                 if (!ignore.includes(index_sub))
                  //                   titles += index_sub += ';';
                  //         } else
                              titles += index + ";";
                      // }
                  }
                  csv += titles + '\r\n';
              }

              var line = '';
              for (let index in array[i]) {
                if (array[i][index] === null)
                    line += ";";
                else {
                    if (index === "bookingDate" || index === "valueDate")
                        line += this.formatDate(array[i][index], false) + ';';
                    else if (index === "originalAmount")
                        line += Math.trunc(parseFloat(array[i][index]) * 100)/100 + ';';
                    else
                      line += array[i][index] + ';';
                }
              }
              csv += line + '\r\n';
          }

          return csv;
      },
      deleteBankConnection(connectionIndex) {
          Swal.fire({
              title: "Are you sure?",
              text: "You are going to delete the " + this.connections[connectionIndex].bankName + " account with all the associated transactions. Proceed?",
              confirmButtonClass: "btn btn-primary",
              showCancelButton: true,
              confirmButtonText: "Yes, proceed",
          }).then((result) => {
              if (result.isConfirmed) {
                  Swal.showLoading();
                  axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'bank_connections/' + this.connections[connectionIndex].id)
                  .then(response => {
                    ElMessage.success("Connection successfully removed.");
                    this.connections.splice(connectionIndex, 1);
                  }).catch(error => {this.showError(error);}).finally(() => {this.loading = false; Swal.hideLoading();});
              }
          });
      }
    },
    computed: {
    },
    beforeMount() {
        this.loading = true;
        let fetchedId = this.$route.params.userId;
        if (!fetchedId) {
            this.showError("no_customer_selected");
            return;
        }

        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
        .then(response => {
            this.userData = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "bank_connections/customer/" + fetchedId)
        .then(response => {
            this.connections = response.data["hydra:member"];
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

    },
  }
</script>

<style scoped>

</style>

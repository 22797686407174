<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Find Customer</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">

                <div class="row">
                  <div class="col-12 col-md-4">
                    <label>Find By:</label><br>
                    <el-select class="select-default" v-model="findBy" value=""  label="">
                      <el-option class="select-default" key="company" label="Company" value="company"></el-option>
                      <el-option class="select-default" key="email" label="E-mail" value="email"></el-option>
                      <el-option class="select-default" key="surname" label="Surname" value="surname"></el-option>
                    </el-select>
                  </div>

                  <div class="col-12 col-md-4">
                    <label>Search:</label>
                    <el-input type="text" name="findText" v-model="findText"></el-input>
                  </div>


                  <div class="col-12 col-md-4 m-auto text-center">
                    <n-button native-type="button" type="primary" class="btn" @click="findUser" :disabled="!findText">Find user</n-button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <h4 class="title">Users found: </h4>

                    <el-table class="table-striped" :data="listUsersFound" border style="width: 100%">

                      <el-table-column key="Date" :min-width="80" prop="regDate" :label="$t('managers.stats.date')">
                        <template #default="scope">
                          {{ scope.row.regDate }}
                        </template>
                      </el-table-column>
                      <el-table-column key="email" :min-width="120" prop="email" class-name="text-center" label="E-mail">
                      </el-table-column>

                      <el-table-column key="emailVerified" :min-width="80" prop="emailVerified"
                                       class-name="text-center" :label="$tc('managers.stats.verified_email', 1)">
                        <template #default="scope">
                          <p v-if="scope.row.emailVerified">{{ $t('common.yes') }}</p>
                          <p v-else>{{ $t('common.no') }}</p>
                        </template>
                      </el-table-column>

                      <el-table-column key="hasActiveSubscription" :min-width="80" prop="hasActiveSubscription"
                                       class-name="text-center" :label="$t('managers.stats.has_sub')">
                        <template #default="scope">
                          <p v-if="scope.row.hasActiveSubscription">{{ $t('common.yes') }}</p>
                          <p v-else>{{ $t('common.no') }}</p>
                        </template>
                      </el-table-column>

                      <el-table-column key="companies" :min-width="80" prop="name" class-name="text-center" label="Company">
                        <template #default="scope">
                          <p v-if="scope.row.name">{{scope.row.name}}</p>
                          <p v-else><el-tooltip content="No company data sent"><i class="text-danger fa fa-times"></i></el-tooltip></p>
                        </template>
                        <!--                        <template #default="scope">-->
                        <!--                          <p v-if="scope.row.numCompanies > 0">{{ $t('managers.stats.received') }}</p>-->
                        <!--                          <p v-else>{{ $t('managers.stats.not_sent') }}</p>-->
                        <!--                        </template>-->
                      </el-table-column>

                      <el-table-column key="actions" :min-width="80" class-name="text-center" :label="$t('common.actions')">
                        <template #default="scope">

                          <el-select class="select-default select-" placeholder="actions.." model-value="" size="small"
                                     :disabled="basicUserDataLoading">
                            <el-option class="select-default" key="1" label="" value="">
                              <router-link :to="'/manager/handle_user/' + scope.row.id" target="_blank">
                                <p> <i class="now-ui-icons design-2_ruler-pencil"></i> Customer page</p>
                              </router-link>
                            </el-option>

                            <el-option class="select-default" key="2" label="" value="">
                              <router-link :to="'/manager/handle_documents/' + scope.row.id" target="_blank">
                                <p> <i class="now-ui-icons files_box"></i> Documents</p>
                              </router-link>
                            </el-option>

                            <el-option class="select-default" key="3" label="" value="">
                              <router-link :to="'/manager/sub_history/' + scope.row.id" target="_blank">
                                <p> <font-awesome-icon icon="history"></font-awesome-icon> Subscription history</p>
                              </router-link>
                            </el-option>

                            <el-option class="select-default" key="4" label="" value="">
                              <router-link :to="'/manager/manage_user_subs/' + scope.row.id" target="_blank">
                                <p> <font-awesome-icon icon="file-signature"></font-awesome-icon> Manage Subscriptions</p>
                              </router-link>
                            </el-option>

                            <el-option class="select-default" key="5" label="" value="">
                              <router-link :to="'/manager/manage_customer_banks/' + scope.row.id" target="_blank">
                                <p> <i class="now-ui-icons business_bank" style="font-size: 16px"></i> Banks</p>
                              </router-link>
                            </el-option>

                            <el-option class="select-default" key="6" label="" value="">
                              <router-link :to="'/manager/company_royalties/' + scope.row.id" target="_blank">
                                <p> <i class="now-ui-icons business_chart-pie-36"></i> Royalties</p>
                              </router-link>
                            </el-option>
                          </el-select>

                        </template>
                      </el-table-column>

                      <template #empty="">
                        <span>{{ $t('common.no_data') }}</span>
                      </template>

                    </el-table>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">{{ $t('managers.stats.basic_data') }}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <el-table class="table-striped" :data="basicUserData" border style="width: 100%"
                          cell-class-name="p-0">
                  <el-table-column key="Date" :min-width="80" prop="regDate" :label="$t('managers.stats.date')">
                      <template #default="scope">
                          {{ scope.row.regDate }}
                      </template>
                  </el-table-column>
                  <el-table-column key="email" :min-width="120" prop="email"
                                   class-name="text-center" label="E-mail">
                  </el-table-column>

                  <el-table-column key="emailVerified" :min-width="80" prop="emailVerified"
                                   class-name="text-center" :label="$tc('managers.stats.verified_email', 1)">
                      <template #default="scope">
                          <p v-if="scope.row.emailVerified">{{ $t('common.yes') }}</p>
                          <p v-else>{{ $t('common.no') }}</p>
                      </template>
                  </el-table-column>

                  <el-table-column key="hasActiveSubscription" :min-width="80" prop="hasActiveSubscription"
                                   class-name="text-center" :label="$t('managers.stats.has_sub')">
                    <template #default="scope">
                      <p v-if="scope.row.hasActiveSubscription">{{ $t('common.yes') }}</p>
                      <p v-else>{{ $t('common.no') }}</p>
                    </template>
                  </el-table-column>

                  <el-table-column key="company" :min-width="80" prop="name" class-name="text-center" label="Company">
                    <template #default="scope">
                      <p v-if="scope.row.name">{{scope.row.name}}</p>
                      <p v-else><el-tooltip content="No company data sent"><i class="text-danger fa fa-times"></i></el-tooltip></p>
<!--                      <p v-if="scope.row.numCompanies > 0">{{ $t('managers.stats.received') }}</p>-->
<!--                      <p v-else>{{ $t('managers.stats.not_sent') }}</p>-->
                    </template>
                  </el-table-column>

                  <el-table-column key="actions" :min-width="80" class-name="text-center" :label="$t('common.actions')">
                    <template #default="scope">

                      <el-select class="select-default select-" placeholder="actions.." model-value="" size="small"
                                 :disabled="basicUserDataLoading">
                        <el-option class="select-default" key="1" label="" value="">
                          <router-link :to="'/manager/handle_user/' + scope.row.id" target="_blank">
                            <p> <i class="now-ui-icons design-2_ruler-pencil"></i> Customer page</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="2" label="" value="">
                          <router-link :to="'/manager/handle_documents/' + scope.row.id" target="_blank">
                            <p> <i class="now-ui-icons files_box"></i> Documents</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="3" label="" value="">
                          <router-link :to="'/manager/sub_history/' + scope.row.id" target="_blank">
                            <p> <font-awesome-icon icon="history"></font-awesome-icon> Subscription history</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="4" label="" value="">
                          <router-link :to="'/manager/manage_user_subs/' + scope.row.id" target="_blank">
                            <p> <font-awesome-icon icon="file-signature"></font-awesome-icon> Manage Subscriptions</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="5" label="" value="">
                          <router-link :to="'/manager/manage_customer_banks/' + scope.row.id" target="_blank">
                            <p> <i class="now-ui-icons business_bank" style="font-size: 16px"></i> Banks</p>
                          </router-link>
                        </el-option>

                        <el-option class="select-default" key="6" label="" value="">
                          <router-link :to="'/manager/company_royalties/' + scope.row.id" target="_blank">
                            <p> <i class="now-ui-icons business_chart-pie-36"></i> Royalties</p>
                          </router-link>
                        </el-option>
                      </el-select>

                    </template>
                  </el-table-column>

                  <template #empty="">
                      <span v-if="basicUserDataLoading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                      <span v-else>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>
                <div class="text-right"><p class="m-1">{{basicUserData.length}}/{{stats.total_users}}</p></div>

              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <label>Per Page:</label><br>
                <el-select class="select-default" v-model="basicUserDataLimit" value=""  label="">
                  <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                  <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                  <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                  <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                </el-select>
              </div>
              <div class="col-4">
                  <label>Page (Max  {{basicUserDataMaxPage}} ):</label>
                  <el-input type="number" name="page" v-model="basicUserDataPage"></el-input>

              </div>
              <div class="col-4 text-center">
                <n-button native-type="button" type="primary" class="btn btn-sm mt-4"
                          :disabled="basicUserDataLoading || (basicUserDataMaxPage === 1 && basicUserData.length === stats.total_users)"
                          @click="getBasicUserData()">{{ $t('common.change') }}</n-button>
              </div>
            </div>


            <div class="row">
              <div class="col-12">
                <p>Legenda: </p>

                <p><i class="now-ui-icons design-2_ruler-pencil text-primary" style="font-size: 16px;"></i> Customer page: Show and edit the data of a company</p>

                <p><i class="now-ui-icons files_box text-primary" style="font-size: 16px"></i> Documents: Manage the private documents of a customer</p>

                <p><font-awesome-icon icon="history" class="text-primary"></font-awesome-icon> Subscription history: Show the history of the subscriptions of a customer</p>

                <p><font-awesome-icon icon="file-signature" class="text-primary"></font-awesome-icon> Manage subscriptions: Create, delete or schedule a subscription</p>

                <p><i class="now-ui-icons business_bank text-primary" style="font-size: 16px"></i> Banks: Show and download the bank transactions of a customer</p>

                <p><i class="now-ui-icons business_chart-pie-36 text-primary" style="font-size: 16px"></i> Royalties: Show and download the royalties paid by the company</p>
              </div>
            </div>



          </div>
        </div>
      </div>
    </div>



    <div class="row">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12" v-if="stats.total_users">
            <stats-card type="info"
                        icon="now-ui-icons users_single-02"
                        :subTitle="'# ' + $t('managers.stats.users')"
                        :title="stats.total_users.toString()">

              <template v-slot:footer>
                <p class="text-center">
                  <i class="now-ui-icons arrows-1_refresh-69"></i> {{ $t('managers.stats.tot_registered') }}
                </p>
              </template>
            </stats-card>
          </div>

          <div class="col-12" v-if="stats.email_verified">
            <stats-card type="warning"
                        icon="now-ui-icons ui-1_email-85"
                        :subTitle="$tc('managers.stats.verified_email', 2)"
                        :title="stats.email_verified.toString()">

              <template v-slot:footer>
                <p class="text-center">
                  <i class="now-ui-icons arrows-1_refresh-69"></i> {{ $t('managers.stats.verified_emails_desc') }}
                </p>
              </template>
            </stats-card>
          </div>

          <div class="col-12" v-if="stats.active_subscriptions">
            <stats-card type="success"
                        icon="now-ui-icons shopping_credit-card"
                        :subTitle="$t('managers.stats.active_subs')"
                        :title="stats.active_subscriptions.toString()">

              <template v-slot:footer>
                <p class="text-center">
                  <i class="now-ui-icons arrows-1_refresh-69"></i> {{ $t('managers.stats.active_subs_desc') }}
                </p>
              </template>
            </stats-card>
          </div>

        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="title">{{ $t('managers.stats.last_users') }}</h4>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <el-table class="table-striped" :data="stats.users_per_day" border style="width: 100%"
                              cell-class-name="p-1">
                      <el-table-column key="Date" :min-width="120" prop="date" :label="$t('managers.stats.date')">
                        <template #default="scope">
                          {{ scope.row.date }}
                        </template>
                      </el-table-column>
                      <el-table-column key="Number of users" :min-width="80" prop="num_users"
                                       class-name="text-center" :label="'# ' + $t('managers.stats.users')">
                      </el-table-column>

                      <template #empty="">
                        <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                        <span v-else>{{ $t('common.no_data') }}</span>
                      </template>
                    </el-table>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import axios from 'axios';
  import  { StatsCard } from "src/components";
  import { ElSelect, ElOption, ElTable, ElTableColumn, ElTooltip } from "element-plus";


  export default {
    name: "UsersStats",
    components: {
      StatsCard,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElTooltip.name]: ElTooltip
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        stats: {},
        basicUserData: [],
        basicUserDataLimit: 10,
        basicUserDataOffset: 0,
        basicUserDataPage: 1,
        basicUserDataLoading: true,
        findBy: "company",
        findText: "",
        listUsersFound: []
      }
    },
    methods: {
        getBasicUserData() {
            this.basicUserDataLoading = true;

            if (this.basicUserDataPage < 1)
                this.basicUserDataPage = 1;

            if (this.basicUserDataPage > this.basicUserDataMaxPage)
                this.basicUserDataPage = this.basicUserDataMaxPage;

            this.basicUserDataOffset = this.basicUserDataLimit * (this.basicUserDataPage - 1);

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/basic_user_info/' + this.basicUserDataOffset + "/" + this.basicUserDataLimit)
                .then(response => {
                    this.basicUserData = response.data;
                    this.basicUserDataLoading = false;
                }).catch(error => {
                this.basicUserDataLoading = false;
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.$store.commit('logout');
                    this.$router.push('/login');
                } else {
                    console.log(error);
                }
            });
        },
      findUser() {
        this.loading = true;
        // necessary to remove the select and re-generate them with the new data
        this.listUsersFound = [];
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/find_user/' + this.findBy + "/" + this.findText)
        .then(response => {
          this.listUsersFound = response.data.users;
        }).catch(error => {
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.$store.commit('logout');
            this.$router.push('/login');
          } else {
            console.log(error);
          }
        }).finally(() => {this.loading = false;});
      }
    },
    computed: {
        basicUserDataMaxPage: function () {
            if (!this.stats.total_users)
                return 1;
            return Math.ceil(this.stats.total_users / this.basicUserDataLimit);
        }
    },
    beforeMount() {
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/users_stats')
      .then(response => {
        this.stats = response.data;
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
          this.$store.commit('logout');
          this.$router.push('/login');
        } else {
          console.log(error);
        }
      });

      this.getBasicUserData();
    },
  }
</script>

<style scoped>

</style>

<template>
  <div>


    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <h4 class="title">{{ $tc('document.document', 2) }} {{ $t('document.to_sign') }}</h4>
              </div>

              <div class="col-12 text-center mt-3">
                <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
              </div>

              <div class="col-12">
                <el-table class="table-striped" :data="documents" border style="width: 100%">

                  <el-table-column key="Date" :min-width="80" prop="regDate" :label="$t('document.upload_date')">
                    <template #default="scope">
                      {{ formatDate(scope.row.uploadDate, true) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="name" :min-width="120" prop="name" label="File"></el-table-column>
                  <el-table-column key="description" :min-width="120" prop="description" :label="$t('document.description')"></el-table-column>
                  <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">
                    <template #default="scope">
                      <router-link :to="'/documents/sign_document/' + scope.row.id">
                        <n-button native-type="button" type="warning" class="btn btn-sm">
                          <font-awesome-icon icon="signature"></font-awesome-icon> {{ $t('document.sign') }}
                        </n-button>
                      </router-link>
                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span>{{ $t('document.no_doc_to_sign') }}</span>
                  </template>

                </el-table>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>



            </div>

          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElSelect, ElOption, ElTable, ElTableColumn, ElPagination } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ToSignList",
    components: {
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn,
        [ElPagination.name]: ElPagination,
    },
    data () {
      return {
        loading: false,
        saving: false,
        error: null,
        documents: [],
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        handlePageSizeChange () {
            this.loadDocs(this.currentPageNumber, this.pageSize);
        },
        handlePageChange (val) {
            this.loadDocs(val, this.pageSize);
        },
        loadDocs(page, pageSize) {
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + "documents/toSign?itemsPerPage=" + pageSize + '&page=' + page)
          .then(response => {
              this.documents = response.data["hydra:member"];
              this.totalItems = response.data["hydra:totalItems"];
              this.currentPageNumber = page;
          }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        }
    },
    computed: {
    },
    beforeMount() {
        this.loadDocs(1, this.pageSize);

    },
  }
</script>

<style scoped>

</style>

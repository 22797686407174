<template>
  <div class="form-check" :class="[{ disabled: disabled }, inlineClass]">
    <label :for="name" class="form-check-label">
      <input
        :id="name"
        class="form-check-input"
        type="checkbox"
        :disabled="disabled"
        v-model="checked"
        :name="name"
        :value="checked"
        @input="handleChange"
      />
      <span class="form-check-sign"></span>
      <slot>
        <span v-if="inline">&nbsp;</span>
      </slot>

    </label>

    <slot name="helpBlock">
      <div
        class="text-danger invalid-feedback"
        style="display: block"
        v-show="errorMessage"
        v-if="errorMessage">
        <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
        <div v-else>{{ $t(errorMessage) }}</div>
      </div>
    </slot>

  </div>
</template>
<script>

import { useField } from 'vee-validate';

export default {
  name: "vee-checkbox",
  props: {
    disabled: Boolean,
    inline: Boolean,
    value: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // cbId: "",
      touched: false,
    };
  },
  computed: {
    // model: {
    //   get() {
    //     return this.modelValue;
    //   },
    //   set(check) {
    //     if (!this.touched) {
    //       this.touched = true;
    //     }
    //     this.$emit("update:modelValue", check);
    //   },
    // },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return ``;
    },
  },
  created() {
    // this.cbId = Math.random().toString(16).slice(2);
  },
  setup(props) {
    const { value: checked, errorMessage, handleChange } = useField(props.name, undefined, {
      // 👇 These are important
      type: 'checkbox',
      //checkedValue === initialValue in VeeInput
      checkedValue: true,
      uncheckedValue: false
    });
    // select/unselect the input
    handleChange(props.value);
    return {
      // checked === inputValue in VeeInput
      checked, // readonly
      handleChange,
      errorMessage,
    };
  },
};
</script>

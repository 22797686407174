<template>
  <div>

    <div class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">


        <!-- List products -->
        <div class="row">

          <div class="col-12">
            <el-table class="table-striped" :data="products" border style="width: 100%" cell-class-name="p-1">

              <el-table-column key="name" :min-width="160" prop="name" class-name="text-center" label="Name">
                <template #default="scope">
                  {{scope.row.name.toUpperCase()}}
                </template>
              </el-table-column>

              <el-table-column key="description" :min-width="240" prop="description" class-name="text-center" label="Description">
              </el-table-column>

              <el-table-column key="priceMonth" :min-width="90" prop="priceMonth" class-name="text-center" label="Price">
                <template #default="scope">
                  {{ scope.row.priceMonth }} {{ scope.row.currency }}<span v-if="scope.row.recurringMonthInterval > 0">/{{scope.row.recurringMonthInterval}} month</span>
                </template>
              </el-table-column>


<!--              <el-table-column key="isActive" :min-width="50" prop="isActive" class-name="text-center"  label="Visible">-->
<!--                <template #default="scope">-->
<!--                  <span class="text-success" v-if="scope.row.isActive">-->
<!--                    <i class="now-ui-icons ui-1_check align-middle"></i>-->
<!--                  </span>-->
<!--                  <span class="text-danger" v-else>-->
<!--                    <i class="now-ui-icons ui-1_simple-remove align-middle"></i>-->
<!--                  </span>-->
<!--                </template>-->
<!--              </el-table-column>-->


              <el-table-column key="actions" :min-width="110" class-name="text-center" :label="$t('common.actions')">
                <template #default="scope">

                  <n-button class="btn btn-sm" type="primary"
                            v-if="scope.row.stripePaymentUrl"
                            v-on:click="copyLinkStripe(scope.row.stripePaymentUrl)">
                    stripe
                  </n-button>

                  <n-button class="btn btn-sm ml-1" type="primary"
                            v-if="scope.row.publicLinkName"
                            v-on:click="copyLink(scope.row.publicLinkName)">
                    <font-awesome-icon icon="globe"></font-awesome-icon>
                  </n-button>

                </template>
              </el-table-column>

              <template #empty="">
                <span>{{ $t('common.no_data') }}</span>
              </template>

            </el-table>
          </div>


            <div class="col-6 col-lg-3">
              <label class="m-0">{{ $t('document.page_size') }}:</label><br>
              <el-select class="select-default" v-model="pageSize" value="" label=""
                         v-on:change="handlePageSizeChange">
                <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                <el-option class="select-default" key="100" label="100" :value="100"></el-option>
              </el-select>
            </div>

            <div class="col-lg-6 pt-4 text-center">
              <el-pagination
                      class="pt-3"
                      v-model:currentPage="currentPageNumber"
                      v-model:page-size="pageSize"
                      :page-sizes="[10,20,50,100]"
                      :pager-count="11"
                      :small="true"
                      :disabled="loading || saving"
                      layout="prev, pager, next"
                      :total="totalItems"
                      @current-change="handlePageChange"
              >
              </el-pagination>
            </div>

            <div class="col-6 col-lg-3 mt-4 pt-3 text-right">
              <p>{{ $t('document.total') }}: {{ totalItems }}</p>
            </div>




<!--          <div v-for="product in products" :key="product.id" class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">-->
<!--            <vnud-card class="card-stats">-->
<!--              <div class="statistics statistics-horizontal">-->
<!--                <div class="info info-horizontal">-->
<!--                  <div class="row">-->
<!--                    <div class="col-12">-->
<!--                      <slot>-->
<!--                        <h5 class="info-title mt-0">{{ product.name }}</h5>-->
<!--                        <h6 class="stats-title">{{ product.priceMonth }} €<span v-if="product.recurringMonthInterval > 0">/{{product.recurringMonthInterval}} month</span></h6>-->
<!--                        <p class="description p-0">{{ product.description }}</p>-->
<!--                      </slot>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="stats">-->
<!--                <div class="row">-->
<!--                  <div class="col-6">-->
<!--                    <p class="text-center mb-0">-->
<!--                      <span class="text-success" v-if="product.isActive">-->
<!--                        <i class="now-ui-icons ui-1_check align-middle"></i> Visible-->
<!--                      </span>-->
<!--                      <span class="text-danger" v-else>-->
<!--                        <i class="now-ui-icons ui-1_simple-remove align-middle"></i> Not visible-->
<!--                      </span>-->
<!--                    </p>-->
<!--                  </div>-->

<!--                  <div class="col-6">-->
<!--                    <p class="text-center mb-0">-->
<!--                      <span v-if="product.stripePaymentUrl">-->
<!--                        <n-button class="btn btn-sm btn-primary m-0"-->
<!--                                  v-on:click="copyLinkStripe(product.stripePaymentUrl)">-->
<!--                          stripe-->
<!--                        </n-button>-->
<!--                      </span>-->
<!--                      <span v-if="product.publicLinkName">-->
<!--                        <n-button class="btn btn-sm btn-primary m-0 mx-1"-->
<!--                                  v-on:click="copyLink(product.publicLinkName)">-->
<!--                          <font-awesome-icon icon="globe"></font-awesome-icon>-->
<!--                        </n-button>-->
<!--                      </span>-->
<!--                    </p>-->
<!--                  </div>-->
<!--                </div>-->

<!--              </div>-->
<!--            </vnud-card>-->
<!--          </div>-->

          <div class="col-12">
            <p>Legenda:</p>

            <p class="m-0">
              <n-button class="btn btn-sm btn-primary mb-1" disabled>stripe</n-button>
              Copy the link for the payment throw stripe. No invoice will be generated.
            </p>
            <p class="m-0">
              <n-button class="btn btn-sm btn-primary mb-1" disabled>
                <font-awesome-icon icon="globe"></font-awesome-icon>
              </n-button>
              Copy the link for the payment throw the platform. An invoice will be automatically generated, once the payment has been succesfully completed.
            </p>
          </div>

        </div>
          </div>
        </div>
        <!-- End List products -->




        <!-- Add new product -->
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="title">Add New Product (Subscription)</h4>
              </div>
              <div class="card-body">

                <div class="row">
                  <div class="col-12 m-auto text-center">
                    <span v-if="loading || saving" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</span>
                  </div>
<!--                  <div v-if="error" class="text-danger text-center col-12">{{ error }}</div>-->
                </div>


                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label>Name:</label>
                    <el-input
                            v-model="subscription.name"
                            type="text"
                            name="name">
                    </el-input>
                  </div>
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label>Price:</label>
                    <el-input
                            v-model="subscription.priceMonth"
                            type="number"
                            name="price" min="1">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label>Currency:</label>
                    <el-select v-model="subscription.currencyIso" class="d-block">
                      <el-option key="eur" value="eur" label="EUR (€)"></el-option>
                      <el-option key="gbp" value="gbp" label="GBP (£)"></el-option>
                    </el-select>
                  </div>

                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label>Recurring every (put 0 if it is not a subscription):</label>
                    <el-input
                            v-model="subscription.recurringMonthInterval"
                            type="number"
                            name="recurring" min="0" step="1">
                      <template #append>month</template>
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-xl-4 m-auto">
                    <label class="mt-4">Description (used for invoices):</label>
                    <el-input
                            v-model="subscription.description"
                            name="description"
                            maxlength="200"
                            show-word-limit
                            rows="5"
                            type="textarea">
                    </el-input>
                  </div>


                  <div class="col-12 col-md-6 col-xl-3 m-auto">
                    <label class="mt-4">Public link name (visible to customer):</label>
                    <el-input
                            v-model="subscription.publicLinkName"
                            name="publicLinkName"
                            v-on:input="formatLinkName"
                            show-word-limit
                            maxlength="30"
                            type="text"
                            placeholder="Leave this blank if it is a recurring sub">
                    </el-input>
                  </div>


                  <div class="col-12 col-md-6 col-xl-4 text-center m-auto">
                    <n-button class="btn btn-primary m-auto" :disabled="loading || saving" v-on:click="saveProduct">Add new Product</n-button>
                  </div>

                  <div class="col-12 text-center">
                    <span v-if="loading || saving" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</span>
                    <div v-if="error" class="text-danger text-center col-12">{{ error }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Add new product -->




        <!-- Users per product -->
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-header">
                <h4 class="title">Get customers with Subscription</h4>
              </div>
              <div class="card-body">
                <div class="row">

                  <div class="col-md-6 col-lg-4 m-auto">
                    <el-select v-model="findSubscriptionId" class="d-block">
                      <el-option v-for="sub in products" :key="sub.id" :value="sub.id" :label="sub.name"></el-option>
                    </el-select>
                  </div>

                  <div class="col-md-4 m-auto text-center">
                    <n-button native-type="button" type="primary" class="btn" @click="getReport" :disabled="loading || saving">Filter</n-button>

                    <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                  </div>



                  <div class="col-12 mt-5">

                    <h4 class="title text-center">Total Users: {{ listUsers.length }}</h4>
                    <div class="row mb-3">
                      <div class="col-12 text-center">
                        <n-button native-type="button" type="primary" class="btn" @click="csvExport"
                                  :disabled="loading || saving || listUsers.length === 0">Export Data</n-button>
                      </div>
                    </div>

                    <el-table class="table-striped" :data="listUsers" border style="width: 100%" cell-class-name="p-1">

                      <el-table-column key="email" :min-width="120" prop="email" class-name="text-center" label="Customer"></el-table-column>

                      <el-table-column key="End Date" :min-width="80" prop="endAt" :label="$t('managers.stats.date')">
                        <template #default="scope">
                          {{ formatDate(scope.row.endAt.date, false) }}
                        </template>
                      </el-table-column>


                      <el-table-column key="actions" :min-width="80" class-name="text-center" :label="$t('common.actions')">
                        <template #default="scope">

                          <router-link :to="'/manager/handle_user/' + scope.row.id">
                            <n-button native-type="button" type="primary" class="btn btn-sm btn-icon"
                                      :disabled="loading">
                              <i class="now-ui-icons design-2_ruler-pencil"></i>
                            </n-button>
                          </router-link>

                          <router-link :to="'/manager/sub_history/' + scope.row.id">
                            <n-button native-type="button" type="primary" class="btn btn-sm btn-icon ml-1"
                                      :disabled="loading">
                              <font-awesome-icon icon="history"></font-awesome-icon>
                            </n-button>
                          </router-link>

                        </template>
                      </el-table-column>

                      <template #empty="">
                        <span>{{ $t('common.no_data') }}</span>
                      </template>

                    </el-table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Users per product -->

      </div>
    </div>



  </div>
</template>

<script>
    import axios from 'axios';
    import { ElInput, ElMessage, ElTable, ElTableColumn, ElSelect, ElOption, ElPagination } from "element-plus";
    import NButton from "src/components/Button";

    export default {
        name: "ManageProducts",
        components: {
            NButton,
            [ElInput.name]: ElInput,
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElPagination.name]: ElPagination
        },
        data () {
            return {
                loading: false,
                saving: false,
                error: null,
                products: [],
                totalItems: 0,
                currentPageNumber: 1,
                pageSize: 20,
                subscription: {name: "", priceMonth: 1, description: "", recurringMonthInterval: 1, publicLinkName: "", currencyIso: "eur"},
                findSubscriptionId: 1,
                listUsers: [],
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true) {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price) {
                return "€ " + (price/100).toFixed(2);
            },
            loadProducts(page, pageSize) {
                this.loading = true;
                this.error = null;

                let config = {
                    method: 'get',
                    url: process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions' + '?itemsPerPage=' + pageSize + '&page=' + page
                };

                axios(config).then(response => {
                    this.products = response.data["hydra:member"];
                    this.totalItems = response.data["hydra:totalItems"];
                    this.currentPageNumber = page;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            handlePageSizeChange () {
                this.loadProducts(this.currentPageNumber, this.pageSize);
            },
            handlePageChange (val) {
                this.loadProducts(val, this.pageSize);
            },
            copyLinkStripe(url) {
                navigator.clipboard.writeText(url).then(function() {
                    ElMessage.success("Copied!");
                }, function() {
                    ElMessage.error("Not copied. Please, try again.");
                });
            },
            copyLink(url) {
                let link = process.env.VUE_APP_BASE_URL + "buy/" + url;
                navigator.clipboard.writeText(link).then(function() {
                    ElMessage.success("Copied!");
                }, function() {
                    ElMessage.error("Not copied. Please, try again.");
                });
            },
            formatLinkName() {
                let t = this.subscription.publicLinkName;
                t = t.replace(/[^\w]+/g, "");
                this.subscription.publicLinkName = t;
            },
            saveProduct() {
                this.error = null;
                this.subscription.priceMonth = parseFloat(this.subscription.priceMonth);
                this.subscription.recurringMonthInterval = parseInt(this.subscription.recurringMonthInterval);
                if (this.subscription.name === "" || this.subscription.description === "")
                {
                    this.error = "Please insert all the necessary information";
                    return;
                }
                if (this.subscription.priceMonth < 1)
                {
                    this.error = "The price must be greater than 0";
                    return;
                }
                if (this.subscription.recurringMonthInterval < 0) {
                    this.error = "The recurring every month field cannot be lower than 0";
                    return;
                }

                if (this.subscription.recurringMonthInterval === 0 && this.subscription.publicLinkName.length <= 0) {
                    this.error = "Please add a name for the link, that will be used to share it with customers";
                    return;
                }
                if (this.subscription.currencyIso === null || this.subscription.currencyIso === "") {
                    this.error = "Please select the currency that the customer will use to pay this subscription.";
                    return;
                }
                this.saving = true;

                axios.post(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions', this.subscription)
                .then(response => {
                    ElMessage.success("New product successfully created!");
                    this.products.push(response.data);
                    this.subscription.name = "";
                    this.subscription.priceMonth = 1;
                    this.subscription.description = "";
                    this.subscription.recurringMonthInterval = 1;
                    this.subscription.publicLinkName = "";
                }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
            },
            getReport() {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/filter_user_by_subscription/' + this.findSubscriptionId)
                .then(response => {
                    this.listUsers = response.data.users;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            csvExport() {
                let subName = "";
                for (let sub of this.listSubs)
                {
                    if(sub.id === this.subscription)
                        subName = sub.name;
                }

                let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
                csvContent += ["Subscription:;" + subName + ";\n\n"];
                csvContent += ["Customer;End Date;\n"];

                for (let row of this.listUsers)
                {
                    let customer = row.email;
                    let date = this.formatDate(row.endAt.date, false);

                    csvContent += [customer + ";" + date + ";\n"];
                }

                const data = encodeURI(csvContent);
                const link = document.createElement("a");
                link.setAttribute("href", data);
                link.setAttribute("download", "export.csv");
                link.click();
            }
        },
        computed: {
        },
        beforeMount() {
            this.loadProducts(this.currentPageNumber, this.pageSize);
        },
    }
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="row" v-if="docsToSign">
      <div class="col-12">
        <div class="card">
<!--          <div class="card-header">-->
<!--            <h4 class="title">Download Invoices</h4>-->
<!--          </div>-->
          <div class="card-body">
            <p class="mt-2 text-center" style="font-size: 18px"> {{ $t('dashboard.new_doc_to_sign_1') }}
              <router-link to="/documents/to_sign">
                <n-button native-type="button" type="warning" class="btn btn-sm">
                  <font-awesome-icon icon="signature"></font-awesome-icon> {{ $t('common.click_here') }}
                </n-button>
              </router-link> {{ $t('dashboard.new_doc_to_sign_2') }}
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="row d-flex justify-content-center">

      <div class="col-12 col-lg-6 m-auto">
        <vnud-card centered class="text-center p-5" v-if="!company">
          <template v-slot:header>
            <div>
              <div class="logo-container m-auto" style="width: 100px; max-width: 100%">
                <img src="./../../../assets/images/logo/logo.png" alt=""/>
              </div>
            </div>
          </template>

          <div class="row mt-4">

            <div v-if="loading" class="col-12 text-danger text-center">
              <p style="font-size: 18px" class="font-weight-bold"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.loading') }}...</p>
            </div>

            <div v-else class="col-12">
              <h5 class="text-primary">{{$t('dashboard.no_company_found')}}</h5>
              <p>{{$t('dashboard.no_company_data_message')}}</p>
            </div>
          </div>

        </vnud-card>

<!--        <company-->
<!--                v-if="company"-->
<!--                type="primary"-->
<!--                :title="company.name"-->
<!--                :subTitle="$t('dashboard.share_capital') + ': ' + company.shareCapital + ' £'"-->
<!--                icon="now-ui-icons business_bank"-->
<!--                :bodyText="company.description"-->
<!--                :address="company.address"-->
<!--                :country="company.country"-->
<!--                :status="company.status"-->
<!--        >-->
<!--        </company>-->
        <vnud-card  v-if="company" class="card-stats">
          <div class="statistics statistics-horizontal">
            <div class="info info-horizontal">
              <div class="row">

                <div class="col-12 text-center">
                  <p class="mb-0" style="font-size: 16px">{{ $t('dashboard.your_company') }}</p>
                  <div class="icon icon-circle mr-3 icon-primary" style="line-height: 2.6; float: none;">
                    <slot name="icon">
                      <i class="now-ui-icons business_bank" style="line-height: 2.7"></i>
                    </slot>
                  </div>
                  <h5 class="info-title d-inline-block">{{ company.name }}</h5>
                </div>
              </div>

              <div>
                <div class="row mt-2">
                  <div class="col-10 m-auto">
                    <p class="text-center mb-1">{{ $t('dashboard.share_capital') + ': ' + company.shareCapital + ' £' }}</p>
                  </div>
                </div>
              </div>


              <div v-if="company.address">
                <div class="row">
                  <div class="col-10 m-auto">
                    <p class="text-center mb-1"><i class="now-ui-icons location_pin"></i>{{ company.address }}</p>
                  </div>
                </div>
              </div>


              <div v-if="company.country">
                <div class="row">
                  <div class="col-10 m-auto">
                    <p class="text-center"><i class="now-ui-icons location_pin"></i>{{ company.country }}</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="stats">
            <p class="text-center">
              {{ $t('dashboard.company_status') }}:
              <span class="text-warning" v-if="!company.status || company.status === 0">
            <i class="now-ui-icons arrows-1_refresh-69 align-middle"></i> {{ $t('dashboard.not_incorporated') }}
          </span>
              <span class="text-success" v-else>
            <i class="now-ui-icons ui-1_check align-middle"></i> {{ $t('dashboard.incorporated') }}
          </span>
            </p>
          </div>





          <div class="row mt-5">

            <div v-for="shareholder in company.shareholders" :key="shareholder.id" class="col-12 col-md-6 col-lg-4 m-auto text-center p-3" style="border: 1px solid lightgray; border-radius: 10px">
              <h5 class="text-primary mb-0">{{ shareholder.name }} {{ shareholder.surname }}</h5>
              <p class="m-0">{{ shareholder.email }}</p>
              <p class="m-0">{{ $t('dashboard.perc_shares') }}: {{ shareholder.shareOwnedPerc }}%
                <span v-if="shareholder.shareOwnedPerc === 0">({{$t('dashboard.external_director')}})</span>
              </p>

              <p v-if="shareholder.identityVerificationStatus !== null" class="m-0">{{ $t('dashboard.aml_status') }}:
                <span v-if="shareholder.identityVerificationStatus === 1" class="text-success">
                  <i class="now-ui-icons sport_trophy"></i> {{ $t('common.completed') }}
                </span>
                <span v-else-if="shareholder.identityVerificationStatus === 0" class="text-warning">
                  <i class="now-ui-icons ui-2_time-alarm"></i> {{ $t('common.pending') }}
                </span>
                <span v-else class="text-danger">
                  <i class="now-ui-icons ui-1_simple-remove"></i> {{ $t('common.failed') }}
                </span>
              </p>

              <span v-if="shareholder.isDirector" class="text-white p-1" style="background-color: lightseagreen; border-radius: 8px;">{{ $t('dashboard.director') }}</span>
            </div>
          </div>

        </vnud-card>

      </div>

      <div class="col-12 col-lg-6 m-auto">
        <div class="row">
          <div class="col-12">
            <vnud-card centered class="text-center">
              <template v-slot:header>
                <div>
                  <h5 class="mb-0"><i class="now-ui-icons files_box"></i> {{ $t('dashboard.management_company_documents') }}</h5>
                </div>
              </template>

              <div class="row">
                <div class="col-12">

                  <router-link to="/documents/archive" class="d-block">
                    <i class="now-ui-icons files_box"></i> {{ $t('documents_folders.archive') }}
                  </router-link>

                  <router-link to="/documents/resolutions" class="d-block">
                    <i class="now-ui-icons files_paper"></i> {{ $t('documents_folders.resolutions') }}
                  </router-link>

                  <router-link to="/documents/companyHouse" class="d-block">
                    <i class="now-ui-icons business_bank"></i> {{ $t('documents_folders.companyHouse') }}
                  </router-link>

                  <router-link to="/documents/shareholders" class="d-block">
                    <i class="now-ui-icons business_badge"></i> {{ $t('documents_folders.shareholders') }}
                  </router-link>

                  <router-link to="/documents/agreement" class="d-block">
                    <i class="now-ui-icons education_paper"></i> {{ $t('documents_folders.agreement') }}
                  </router-link>

                  <router-link to="/documents/resolution" class="d-block">
                    <i class="now-ui-icons education_paper"></i> {{ $t('documents_folders.resolution') }}
                  </router-link>
                </div>
              </div>

            </vnud-card>
          </div>

          <div class="col-12">
            <vnud-card centered class="text-center">
              <template v-slot:header>
                <div>
                    <h5 class="mb-0"> <i class="now-ui-icons business_chart-pie-36"></i> Royalties</h5>
                </div>
              </template>

              <div class="row">
                <div class="col-12">
                  <p>{{ $t('dashboard.royalties_desc') }}</p>
                  <router-link to="/royalties">
                    <n-button native-type="button" type="primary" class="btn btn-sm ml-1">
                      <i class="now-ui-icons arrows-1_minimal-right"></i> {{ $t('dashboard.service_login') }}
                    </n-button>
                  </router-link>
                </div>
              </div>

            </vnud-card>
          </div>
        </div>
      </div>
    </div>

<!--    <div class="row" v-if="company">-->
<!--      <div v-for="shareholder in company.shareholders" :key="shareholder.id" class="col-12 col-md-6 col-lg-5 col-xl-4 m-auto">-->
<!--        <shareholder :name="shareholder.name"-->
<!--                     :surname="shareholder.surname"-->
<!--                     :email="shareholder.email"-->
<!--                     :isDirector="shareholder.isDirector"-->
<!--                     :shareOwnedPerc="shareholder.shareOwnedPerc"-->
<!--                     :id-verification="shareholder.identityVerificationStatus"-->
<!--        ></shareholder>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>

  import axios from "axios";
  // import Shareholder from "src/components/Shareholder";
  // import Company from "src/components/Company";
  import { ElMessage } from "element-plus";
  import VnudCard from "src/components/Cards/Card";

export default {
  name: "dashboard-content",
  components: {
    // Shareholder,
    // Company,
    VnudCard
  },
  data() {
    return {
      error: null,
      buttonDisabled: false,
      loading: true,
      company: null,
      docsToSign: false,
    }
  },
  methods: {
      showError(error) {
          this.error = "";
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.error = this.$i18n.t("error.login_expired");
              this.$store.commit('logout');
              this.$router.push('/login');
          }
          else if (error.response && error.response.data && error.response.data.violations &&
              error.response.data.violations.length > 0) {
              for (let i = 0; i < error.response.data.violations.length; i++)
              {
                  let stringErr = error.response.data.violations[i].message;
                  if(stringErr.endsWith("."))
                      stringErr = stringErr.slice(0, -1);
                  this.error += this.$i18n.t(stringErr) + ". ";
              }
          } else if(error.response && error.response.data && error.response.data.message)
          {
              let stringErr = error.response.data.message;
              if(stringErr.endsWith("."))
                  stringErr = stringErr.slice(0, -1);
              this.error = this.$i18n.t("error." + stringErr);
          }
          else this.error = this.$i18n.t("error.try_again");
          ElMessage.error(this.error);
          console.log(error);
      },
  },
  setup() {
  },
  beforeMount() {
    axios.get(process.env.VUE_APP_SERVER_BASE_URL + "companies?compiler=" + this.$store.getters.getUserId)
    .then((response) => {
      this.company = response.data["hydra:member"][0];
    }).catch((error) => {
        this.showError(error);
    }).finally(() => {this.loading = false;});


      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "documents/toSign")
      .then((response) => {
        // response.data["hydra:member"];
        if (parseInt(response.data["hydra:totalItems"]) > 0)
          this.docsToSign = true;
      }).catch((error) => {
        console.log(error);
      });
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>

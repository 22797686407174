<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Download Invoices</h4>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-md-4 col-xl-3 m-auto">
                <label>Search by </label>
                <el-select class="select-default ml-2" v-model="filterBy" value="" label="" v-on:change="updateFilterSettings">
                  <el-option class="select-default" key="year" label="year" value="year"></el-option>
                  <el-option class="select-default" key="invoiceNumber" label="invoice number" value="invoiceNumber"></el-option>
                  <el-option class="select-default" key="customerName" label="company name" value="customerName"></el-option>
                  <el-option class="select-default" key="customerContact" label="customer Email" value="customerContact"></el-option>
                </el-select>
              </div>


              <div class="col-12 col-md-4 col-xl-3 m-auto">
                <el-input
                        ref="filterValue"
                        :placeholder="filterValue.placeholder"
                        v-model="filterValue.value"
                        name="filterValue"
                        :type="filterValue.type"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-4 col-xl-3 m-auto" :class="filterBy === 'invoiceNumber' ? 'd-block' : 'd-none'">
                <el-input
                        ref="filterValue2"
                        placeholder="insert year"
                        v-model="filterValue2.value"
                        name="filterValue2"
                        type="number"
                        clearable>
                </el-input>
              </div>


<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3">-->
<!--                <el-input-->
<!--                        placeholder="filter by year"-->
<!--                        v-model="filter.year"-->
<!--                        name="filter_year"-->
<!--                        maxlength="4"-->
<!--                        type="number"-->
<!--                        clearable>-->
<!--                </el-input>-->
<!--              </div>-->

<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3">-->
<!--                <el-input-->
<!--                        placeholder="filter by name"-->
<!--                        v-model="filter.customerName"-->
<!--                        name="filter_name"-->
<!--                        type="text"-->
<!--                        clearable>-->
<!--                </el-input>-->
<!--              </div>-->

<!--              <div class="col-12 col-md-6 col-lg-4 col-xl-3">-->
<!--                <el-input-->
<!--                        placeholder="filter by email"-->
<!--                        v-model="filter.customerContact"-->
<!--                        name="filter_email"-->
<!--                        type="email"-->
<!--                        clearable>-->
<!--                </el-input>-->
<!--              </div>-->

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center m-auto">
                <n-button native-type="button" type="primary" class="btn mt-0" @click="loadInvoices(pageSize, 1)" :disabled="loading || saving">Apply Filter</n-button>
                <n-button native-type="button" type="primary" class="btn mt-0" @click="clearFilter" :disabled="loading || saving">Clear Filter</n-button>
              </div>

              <div class="col-12 m-auto pt-4 text-center">
                <n-button native-type="button" type="primary" class="btn" @click="downloadSelected" :disabled="loading || saving || listSelection.length === 0">Download Selected</n-button>
                <n-button native-type="button" type="primary" class="btn" @click="downloadInvoices" :disabled="loading || saving">Download All</n-button>
                <n-button native-type="button" type="primary" class="btn" @click="getCsvInvoices" :disabled="loading || saving">Get csv</n-button>

                <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
              </div>



              <div class="col-12 mt-5">


                <el-table class="table-striped" :data="invoices" border style="width: 100%" @selection-change="invoiceSelection">

                  <el-table-column type="selection" width="55"></el-table-column>

                  <el-table-column key="number" :min-width="100" prop="invoiceNumber" label="Invoice N.">
                    <template #default="scope">
                      <span v-if="scope.row.invoiceNumber">
                        {{ scope.row.invoiceNumber }} - {{ scope.row.year }}
                      </span>
                      <span v-else>Pending..</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="Date" :min-width="100" prop="createdAt" label="Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.createdAt, true) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="customerName" :min-width="160" prop="customerName" label="To">
                    <template #default="scope">
                      {{ scope.row.customerName }} - {{ scope.row.customerContact }}
                    </template>
                  </el-table-column>

                  <el-table-column key="total" :min-width="90" prop="total" label="Total"></el-table-column>

                  <el-table-column key="notes" :min-width="90" prop="total" label="Notes">
                    <template #default="scope">
                      <span v-if="scope.row.subscription && scope.row.subscription.stripeInvoices">
                        <span v-for="inv in scope.row.subscription.stripeInvoices" v-bind:key="inv">
                          <span v-if="inv.paidOutsideStripeDate">
                            <span>Paid outside Stripe on {{formatDate(inv.paidOutsideStripeDate, false)}}</span>
                            <span v-if="inv.paidOutsideStripeNotes"> - {{ inv.paidOutsideStripeNotes }}</span>
                          </span>
                        </span>
                      </span>
                    </template>
                  </el-table-column>


                  <el-table-column key="actions" :min-width="120" class-name="text-center" :label="$t('common.actions')">
                    <template #default="scope">

                      <n-button native-type="button" type="primary" class="btn btn-sm btn-icon"
                                @click="downloadInvoice(scope.row.id)"
                                :disabled="!scope.row.invoiceNumber">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>

                      <router-link :to="'/manager/new_invoice/' + scope.row.id">
                        <n-button native-type="button" type="warning" class="btn btn-sm btn-icon ml-1">
                          <i class="now-ui-icons arrows-1_refresh-69"></i>
                        </n-button>
                      </router-link>

                      <span v-if="scope.row.creditNote">
                        <n-button native-type="button" type="primary" class="btn btn-sm ml-1"
                                  @click="downloadCreditNote(scope.row.creditNote.id)">
                        CN {{ scope.row.creditNote.number }} {{ scope.row.creditNote.year }}
                        </n-button>
                      </span>
                      <span v-else>
                        <router-link :to="'/manager/new_credit_note/' + scope.row.id">
                          <n-button native-type="button" type="primary" class="btn btn-sm btn-icon ml-1">
                            <i class="now-ui-icons shopping_tag-content"></i>
                          </n-button>
                        </router-link>
                      </span>

                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>

              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                      <el-option class="select-default" key="250" label="250" :value="250"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100,250]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>


                <div class="col-12 mt-4">
                  <p class="m-0">Legenda: </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </n-button>
                    Download the invoice
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="warning" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons arrows-1_refresh-69"></i>
                    </n-button>
                    Update the customer data and regenerate the invoice
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons shopping_tag-content"></i>
                    </n-button>
                    Create a credit note for the specific invoice
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm m-0 mb-1" disabled>
                      CN ..
                    </n-button>
                    Download the credit note that refers to the invoice
                  </p>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>




    <div class="row mt-5">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Download Credit notes</h4>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by year"
                        v-model="creditNoteFilter.year"
                        name="filter_year"
                        maxlength="4"
                        type="number"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by name"
                        v-model="creditNoteFilter.customerName"
                        name="filter_name"
                        type="text"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by email"
                        v-model="creditNoteFilter.customerContact"
                        name="filter_email"
                        type="email"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
                <n-button native-type="button" type="primary" class="btn mt-0" @click="loadCreditNotes(pageSize, 1)" :disabled="loading || saving">Apply Filters</n-button>
              </div>

              <div class="col-12 m-auto pt-4 text-center">
                <n-button native-type="button" type="primary" class="btn" @click="downloadCreditNotes" :disabled="loading || saving">Download All</n-button>
                <n-button native-type="button" type="primary" class="btn" @click="getCsvCreditNotes" :disabled="loading || saving">Get CSV</n-button>

                <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
              </div>



              <div class="col-12 mt-5">


                <el-table class="table-striped" :data="creditNotes" border style="width: 100%">

                  <el-table-column key="number" :min-width="100" prop="number" label="n.">
                    <template #default="scope">
                      <span v-if="scope.row.number">
                        {{ scope.row.number }} - {{ scope.row.year }}
                      </span>
                      <span v-else>Pending..</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="Date" :min-width="100" prop="createdAt" label="Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.createdAt, true) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="customerName" :min-width="160" prop="customerName" label="To">
                    <template #default="scope">
                      {{ scope.row.customerName }} - {{ scope.row.customerContact }}
                    </template>
                  </el-table-column>

                  <el-table-column key="total" :min-width="90" prop="total" label="Total"></el-table-column>


                  <el-table-column key="actions" :min-width="80" class-name="text-center" :label="$t('common.actions')">
                    <template #default="scope">

                      <n-button native-type="button" type="primary" class="btn btn-sm btn-icon"
                                @click="downloadCreditNote(scope.row.id)"
                                :disabled="!scope.row.number">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>

              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handleCreditNotePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="creditNoteCurrentPageNumber"
                            v-model:page-size="creditNotePageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="creditNoteTotalItems"
                            @current-change="handleCreditNotePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ creditNoteTotalItems }}</p>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import axios from 'axios';
    import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElPagination, ElCheckbox } from "element-plus";


    export default {
        name: "DownloadInvoices",
        components: {
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElInput.name]: ElInput,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElDatePicker.name]: ElDatePicker,
            [ElPagination.name]: ElPagination,
            [ElCheckbox.name]: ElCheckbox
        },
        data () {
            return {
                loading: false,
                saving: false,
                error: null,
                invoices: [],
                creditNotes: [],
                totalItems: 0,
                creditNoteTotalItems: 0,
                currentPageNumber: 1,
                creditNoteCurrentPageNumber: 1,
                pageSize: 20,
                creditNotePageSize: 20,
                // filter: {year: null, customerName: null, customerContact: null},
                creditNoteFilter: {year: null, customerName: null, customerContact: null},
                filterBy: 'year',
                filterValue: {type: "number", value: null, placeholder: "Insert year to search"},
                filterValue2: {value: null},
                listSelection: [],
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true) {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price) {
                return "€ " + (price/100).toFixed(2);
            },
            handlePageSizeChange () {
                this.loadInvoices(this.pageSize, this.currentPageNumber);
            },
            handleCreditNotePageSizeChange () {
                this.loadCreditNotes(this.creditNotePageSize, this.creditNoteCurrentPageNumber);
            },
            handlePageChange (val) {
                this.loadInvoices(this.pageSize, val);
            },
            handleCreditNotePageChange (val) {
                this.loadCreditNotes(this.creditNotePageSize, val);
            },
            updateFilterSettings() {
                this.filterValue2.value = null;
                if (this.filterBy === 'invoiceNumber') {
                    this.filterValue.placeholder = "insert invoice number to search";
                    this.filterValue.type = "number";
                }
                else if (this.filterBy === 'year') {
                    this.filterValue.placeholder = "insert year to search";
                    this.filterValue.type = "number";
                }
                else if (this.filterBy === 'customerName') {
                    this.filterValue.placeholder = "insert company name to search";
                    this.filterValue.type = "text";
                }
                else if (this.filterBy === 'customerContact') {
                    this.filterValue.placeholder = "insert email address to search";
                    this.filterValue.type = "text";
                }

            },
            clearFilter() {
                this.filterValue.value = null;
                this.filterValue2.value = null;
                this.loadInvoices(this.pageSize, 1);
            },
            loadInvoices(pageSize, page) {
                this.loading = true;
                this.error = null;
                let filters = "";
                // if (this.filter.year !== null && this.filter.year !== "")
                //     filters += "&year=" + this.filter.year;
                // if (this.filter.customerName && this.filter.customerName !== "")
                //     filters += "&customerName=" + encodeURIComponent(this.filter.customerName);
                // if (this.filter.customerContact && this.filter.customerContact !== "")
                //     filters += "&customerContact=" + this.filter.customerContact;

                if(this.filterValue.value !== null && this.filterValue.value !== "") {
                    filters += "&" + this.filterBy + "=" + encodeURIComponent(this.filterValue.value.trim());
                    if (this.filterBy === 'invoiceNumber' && this.filterValue2.value !== null && this.filterValue.value !== "")
                        filters += "&year=" + this.filterValue2.value;
                }

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'invoices?itemsPerPage=' + pageSize + '&page=' + page + filters)
                    .then(response => {
                        this.invoices = response.data["hydra:member"];
                        this.totalItems = response.data["hydra:totalItems"];
                        this.currentPageNumber = page;
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadInvoices() {
                this.loading = true;
                // let filters = "year=" + this.filter.year + "&customerName=" + this.filter.customerName +
                //     "&customerContact=" + this.filter.customerContact;

                let filters = "";
                if(this.filterValue.value !== null && this.filterValue.value !== "") {
                    filters += "&" + this.filterBy + "=" + encodeURIComponent(this.filterValue.value.trim());
                    if (this.filterBy === 'invoiceNumber' && this.filterValue2.value !== null && this.filterValue.value !== "")
                        filters += "&year=" + this.filterValue2.value;
                }

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'invoices/get_zip?' + filters, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/zip'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "invoices.zip";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadSelected() {
                this.loading = true;

                let ids = "";
                for(let i=0; i<this.listSelection.length; i++) {
                    ids += this.listSelection[i].id;
                    if(i<this.listSelection.length -1)
                        ids += ",";
                }

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'invoices/get_zip_selected?ids=' + ids, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/zip'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "invoices.zip";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadInvoice(id) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-invoice/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/pdf'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "invoice.pdf";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            getCsvInvoices() {
                this.loading = true;
                // let filters = "year=" + this.filter.year + "&customerName=" + this.filter.customerName +
                //     "&customerContact=" + this.filter.customerContact;
                let filters = "";
                if(this.filterValue.value !== null && this.filterValue.value !== "") {
                    filters += "&" + this.filterBy + "=" + encodeURIComponent(this.filterValue.value.trim());
                    if (this.filterBy === 'invoiceNumber' && this.filterValue2.value !== null && this.filterValue.value !== "")
                        filters += "&year=" + this.filterValue2.value;
                }

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'invoices/get_csv?' + filters, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/csv'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "invoices.csv";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            invoiceSelection(items) {
                this.listSelection = items;
            },
            loadCreditNotes(pageSize, page) {
                this.loading = true;
                this.error = null;
                let filters = "";
                if (this.creditNoteFilter.year !== null && this.creditNoteFilter.year !== "")
                    filters += "&year=" + this.creditNoteFilter.year;
                if (this.creditNoteFilter.customerName && this.creditNoteFilter.customerName !== "")
                    filters += "&customerName=" + encodeURIComponent(this.creditNoteFilter.customerName);
                if (this.creditNoteFilter.customerContact && this.creditNoteFilter.customerContact !== "")
                    filters += "&customerContact=" + this.creditNoteFilter.customerContact;

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'credit_notes?itemsPerPage=' + pageSize + '&page=' + page + filters)
                    .then(response => {
                        this.creditNotes = response.data["hydra:member"];
                        this.creditNoteTotalItems = response.data["hydra:totalItems"];
                        this.creditNoteCurrentPageNumber = page;
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadCreditNotes() {
                this.loading = true;
                let filters = "year=" + this.creditNoteFilter.year + "&customerName=" + this.creditNoteFilter.customerName +
                    "&customerContact=" + this.creditNoteFilter.customerContact;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'credit_notes/get_zip?' + filters, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/zip'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "credit_notes.zip";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadCreditNote(id) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-credit_note/' + id, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/pdf'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "credit_note.pdf";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            getCsvCreditNotes() {
                this.loading = true;
                let filters = "year=" + this.creditNoteFilter.year + "&customerName=" + this.creditNoteFilter.customerName +
                    "&customerContact=" + this.creditNoteFilter.customerContact;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'credit_notes/get_csv?' + filters, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/csv'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "credit_notes.csv";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
        },
        computed: {
        },
        beforeMount() {
            this.loadInvoices(this.pageSize, this.currentPageNumber);
            this.loadCreditNotes(this.creditNotePageSize, this.creditNoteCurrentPageNumber);
        },
    }
</script>

<style scoped>

</style>

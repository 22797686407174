<template>
  <div>

    <div class="row">
      <div class="col-12 col-lg-10 col-xl-8 m-auto">
        <div class="card">
          <div class="card-header">
            <h4>List of companies not incorporated</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <el-table class="table-striped table-sm" :data="companies" border style="width: 100%" cell-class-name="p-1">
                  <el-table-column key="name" :min-width="80" prop="name" label="Name"></el-table-column>

                  <el-table-column key="lastChangeAt" :min-width="80" prop="lastChangeAt" label="Date">
                    <template #default="scope">
                      <span v-if="scope.row.lastChangeAt">
                        {{ formatDate(scope.row.lastChangeAt, true) }}
                      </span>
                      <span v-else>-</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="actions" width="170" prop="id" label="Actions">
                    <template #default="scope">

                      <router-link :to="'/manager/handle_user/' + scope.row.id">
                        <n-button native-type="button" type="primary" class="btn btn-sm btn-icon"
                                  :disabled="loading">
                          <i class="now-ui-icons design-2_ruler-pencil"></i>
                        </n-button>
                      </router-link>
                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                    <span v-else>{{ $t('common.no_data') }}</span>
                  </template>
                </el-table>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-12">
                <p class="m-0">Legenda: </p>

                <p class="m-0">
                  <n-button native-type="button" type="primary" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                    <i class="now-ui-icons design-2_ruler-pencil"></i>
                  </n-button>
                  Show and edit the data of a company
                </p>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ListUnincorporated",
    components: {
      [ElSelect.name]: ElSelect,
      [ElInput.name]: ElInput,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElDatePicker.name]: ElDatePicker
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        companies: [],
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
            return dateObj.toLocaleDateString();
        },
        formatPrice(price) {
            return "€ " + (price/100).toFixed(2);
        },
        disabledDate(time) {
            return time.getTime() < (new Date((new Date).toDateString())).getTime();
        },
    },
    computed: {
    },
    beforeMount() {
      this.loading = true;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + "companies?status=0&order[lastChangeAt]")
      .then((response) => {
          this.companies = response.data["hydra:member"];
      }).catch((error) => {this.showError(error);}).finally(() => {this.loading = false; });
    },
  }
</script>

<style scoped>

</style>

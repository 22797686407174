<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-6 m-auto">

        <vnud-card centered class="text-center p-5">
          <template v-slot:header>
            <span v-if="intervalNumber > 0" class="d-block" style="font-size: 32px;">
              <i class="fa fa-spinner fa-spin text-primary"></i> {{ $t('payments_check.checking_payment') }}...
            </span>
            <span v-else class="d-block" style="font-size: 32px;">
              <font-awesome-icon icon="exclamation-triangle" class="text-warning"></font-awesome-icon>
              {{ $t('payments_check.not_found') }}
            </span>
            <br />
          </template>

          <div class="row">
            <div class="col-12">
              <p v-if="intervalNumber > 0" style="font-size: 16px">{{ $t('payments_check.checking_message') }}</p>
              <p v-else style="font-size: 16px">{{ $t('payments_check.not_found_message') }}</p>

            </div>
          </div>

        </vnud-card>

      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import { ElMessage } from "element-plus";

export default {
  name: "waiting-subscription-payment",
  components: {
  },
  data() {
      return {
          error: null,
          buttonDisabled: false,
          loading: false,
          saving: false,
          interval: null,
          intervalNumber: 10,
          userId: this.$store.getters.getUserId
      }
  },
  methods: {
    checkSubscription()
    {
        this.loading = true;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.userId + '/get_last_subscriptions')
        .then(response => {
            // TODO: we are assuming that if you are in this page, you are buying your first subscription.
            if(response.data["hydra:totalItems"] > 0)
            {
                clearInterval(this.interval);
                this.$store.commit('setHasSubscription', true);
                this.$router.push('/complete_registration/email_verification');
            }
            else {
                this.intervalNumber--;
                if (this.intervalNumber <= 0)
                    clearInterval(this.interval);
            }
        }).catch(error => {
            this.intervalNumber--;
            if(this.intervalNumber <= 0)
                clearInterval(this.interval);
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        }).finally(() => {this.loading = false;});
    }
  },
  setup() {
  },
  beforeMount() {
      this.checkSubscription();
      this.interval = setInterval(this.checkSubscription, 10000);
  }
};
</script>
<style>
</style>

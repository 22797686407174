const yupLocale = {
  mixed: {
    default: () => ({ key: 'yup.field_invalid', values: {  } }),
    required: () => ({ key: "yup.field_required", values: {  } })
  },
  string: {
    // email: "yup.invalid_email",
    email: () => ({ key: 'yup.invalid_email', values: {  } }),
    min: ({ min }) => ({ key: 'yup.field_too_short', values: { minVal: min } }),
    max: ({ max }) => ({ key: 'yup.field_too_long', values: { maxVal: max } }),
  },
  date: {
  },
  number: {
    min: ({ min }) => ({ key: 'yup.number_min', values: { minVal: min } }),
    max: ({ max }) => ({ key: 'yup.number_max', values: { maxVal: max } }),
    positive: () => ({ key: 'yup.positive_number', values: { } }),
    integer: () => ({ key: 'yup.integer_number', values: { } })
  }
};

export default yupLocale;
<template>
  <div>

    <div class="row">
      <div class="col-12 col-lg-8 col-xl-6 m-auto">
        <div class="card">
          <div class="card-header">
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-4 text-center d-flex">

                <h4 class="title m-auto">
                  <i class="text-info now-ui-icons business_badge d-none d-md-block" style="font-size: 50px"></i><br>Compiler Data
                </h4>
              </div>
              <div class="col-12 col-md-8">

                <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>

                <p>Name: <span v-if="userData" class="font-weight-bold">{{ userData.firstName ? userData.firstName : '-' }}</span></p>
                <p>Surname: <span v-if="userData" class="font-weight-bold">{{ userData.lastName ? userData.lastName : '-' }}</span></p>
                <p class="mb-1">E-mail: <span v-if="userData" class="font-weight-bold">{{ userData.email }}
                  <n-button class="btn btn-sm m-1 btn-icon" type="link" v-on:click="changeEmail()">
                    <i class="now-ui-icons design-2_ruler-pencil" style="font-size: medium"></i>
                  </n-button></span>
                </p>
                <p class="mb-1">E-mail verified: <span v-if="userData" class="font-weight-bold">{{ userData.emailVerified ? 'Yes': 'No' }}
                  <n-button class="btn btn-sm m-1 btn-icon" type="link" v-on:click="changeEmailVerified()">
                    <i class="now-ui-icons design-2_ruler-pencil" style="font-size: medium"></i>
                  </n-button></span>
                </p>
                <p>Registration Date: <span v-if="userData" class="font-weight-bold">{{ new Date(userData.registeredAt).toLocaleString() }}</span></p>
                <p>Last Login Date: <span v-if="userData" class="font-weight-bold">{{ userData.lastLogin ? new Date(userData.lastLogin).toLocaleString() : '-' }}</span></p>
                <p>Has Active Subscription:
                  <span v-if="userData" class="font-weight-bold">{{ userData.hasActiveSubscription ? 'Yes': 'No' }}
                    <n-button class="btn btn-sm m-1" type="primary"
                              v-if="!userData.hasActiveSubscription"
                              v-on:click="changeHasSubscription()">
                      <i class="now-ui-icons design-2_ruler-pencil" style="font-size: medium"></i>
                      Skip subscription payment
                    </n-button>
                  </span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-4 col-xl-2 m-auto">
        <div class="row">
          <div class="col-12 text-center m-auto">
            <router-link :to="'/manager/handle_documents/' + userData.id" v-if="userData">
              <n-button native-type="button" type="primary" class="btn">
                <i class="now-ui-icons files_box"></i> Documents
              </n-button>
            </router-link>
          </div>

          <div class="col-12 text-center m-auto">
            <router-link :to="'/manager/handle_company_house/' + userData.id" v-if="userData">
              <n-button native-type="button" type="primary" class="btn">
                <i class="now-ui-icons business_bank"></i> Financial Statement Page
              </n-button>
            </router-link>
          </div>

          <div class="col-12 text-center m-auto">
            <router-link :to="'/manager/company_royalties/' + userData.id" v-if="userData">
              <n-button native-type="button" type="primary" class="btn">
                <i class="now-ui-icons business_chart-pie-36"></i> Royalties
              </n-button>
            </router-link>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-12 m-auto">

        <vnud-card class="card-stats">
          <div class="statistics statistics-horizontal">
            <div class="info info-horizontal">
              <div class="row">
                <div class="col-12">
                  <div class="icon icon-circle mr-3 icon-primary">
                      <i class="now-ui-icons business_bank"></i>
                  </div>
                  <h4 class="mt-4">Company Data</h4>
                </div>
              </div>

              <Form ref="companyForm" @submit="updateCompany">
                <div class="row">

                  <div class="col-12 col-md-8 col-lg-6 m-auto">
                    <label>Company name:</label>
                    <el-input type="text" name="companyName" v-model="company.name">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-8 col-lg-6 m-auto">
                    <label>Company description:</label>
                    <el-input
                            v-model="company.description"
                            type="textarea"
                            name="companyDescription"
                            :rows="5">
                    </el-input>
                  </div>
                </div>


                <div class="row">
                  <div class="col-12 col-md-6 col-xl-3 m-auto">
                    <label class="mt-3">Company Address Line 1:</label>
                    <el-input
                            v-model="company.address"
                            type="textarea"
                            name="companyAddress"
                            maxlength="75"
                            show-word-limit
                            :rows="3">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3 m-auto">
                    <label class="mt-3">Company Adddress Line 2:</label>
                    <el-input
                            v-model="company.country"
                            type="textarea"
                            name="companyCountry"
                            maxlength="75"
                            show-word-limit
                            :rows="3">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3 m-auto">
                    <label class="mt-3">Company Number:</label>
                    <el-input
                            v-model="company.companyNumber"
                            type="text"
                            name="companyNumber">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-xl-3 m-auto">
                    <label class="mt-3">Vat Number:</label>
                    <el-input
                            v-model="company.vatNumber"
                            type="text"
                            name="vatNumber">
                    </el-input>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label class="mt-3">Tax Code:</label>
                    <el-input
                            v-model="company.taxCode"
                            type="text"
                            name="taxCode">
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label class="mt-3">Capital Share:</label>
                    <el-input
                            v-model="company.shareCapital"
                            type="number"
                            name="companyShare">
                      <template #append>£</template>
                    </el-input>
                  </div>

                  <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                    <label class="mt-3">Company status:</label>

                    <el-select
                            class="d-block"
                            v-model="company.status"
                            name="companyStatus">
                      <el-option :value="0" :label="$t('dashboard.not_incorporated')" :key="0"></el-option>
                      <el-option :value="1" :label="$t('dashboard.incorporated')" :key="1"></el-option>
                    </el-select>

                    <p v-if="company.status !== 1" class="text-danger" style="font-size: small">Until not incorporated, no invoice will be generated</p>
                  </div>
                </div>




                <div class="row mb-3">
                  <div class="col-12 m-auto text-center">
                    <n-button native-type="submit" type="primary" round :disabled="loading || saving">
                      {{ $t('common.save') }}
                    </n-button>

                    <div v-if="errorCompany" class="text-danger">
                      {{ errorCompany }}
                    </div>
                  </div>

                  <span v-if="saving" class="col-12 text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</span>
                </div>
              </Form>

              <hr class="my-5"/>



                <div class="row mt-5">
                  <div class="col-12 mt-5">
                    <label>Shareholders:</label>
                    <el-table class="table-striped" :data="company.shareholders" border style="width: 100%"
                              :header-cell-style="{'font-size': '14px', 'font-weight': 'bold'}">

                      <el-table-column type="expand">
                        <template #default="props">
                          <div class="row">
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>Shares (put 0 if this is an external director):</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].shareOwnedPerc" type="number" max="100" min="0">
                                <template #append>%</template>
                              </el-input>
                            </div>
                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>Date of Birth:</label>
                              <br>
                              <el-date-picker
                                      v-model="company.shareholders[props.$index].dateOfBirth"
                                      type="date"
                                      format="DD/MM/YYYY"
                                      value-format="DD/MM/YYYY"
                                      :disabled-date="disabledDate"
                                      :default-value="new Date(yearsAgo16)">
                              </el-date-picker>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>Place of Birth:</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].placeOfBirth" type="text"></el-input>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>{{$t('company.address')}}:</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].addressStreet" type="text"></el-input>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>{{$t('company.city')}}:</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].addressCity" type="text"></el-input>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>{{$t('company.country')}}:</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].addressCountry" type="text"></el-input>
                            </div>

                            <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                              <label>{{$t('contacts.phone_number')}}:</label>
                              <br>
                              <el-input v-model="company.shareholders[props.$index].phoneNumber" type="text"></el-input>
                            </div>
                          </div>

                        </template>
                      </el-table-column>

                      <el-table-column key="name" :min-width="110" prop="name" label="Name">
                        <template #default="scope">
                          <el-input v-model="company.shareholders[scope.$index].name" type="text"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column key="surname" :min-width="110" prop="surname" label="Surname">
                        <template #default="scope">
                          <el-input v-model="company.shareholders[scope.$index].surname" type="text"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column key="email" :min-width="110" prop="email" label="Email">
                        <template #default="scope">
                          <el-input v-model="company.shareholders[scope.$index].email" type="email"></el-input>
                        </template>
                      </el-table-column>
                      <el-table-column key="isDirector" width="120" prop="isDirector" label="Is Director">
                        <template #default="scope">
                          <el-select v-model="company.shareholders[scope.$index].isDirector">
                            <el-option :value="false" :label="$t('common.no')" key="false"></el-option>
                            <el-option :value="true" :label="$t('common.yes')" key="true"></el-option>
                          </el-select>
                        </template>
                      </el-table-column>

                      <el-table-column key="identityVerificationStatus" width="150" prop="identityVerificationStatus" label="ID check">
                        <template #default="scope">
                          <el-select v-model="company.shareholders[scope.$index].identityVerificationStatus">
                            <el-option :value="0" :label="$t('common.pending')" key="0"></el-option>
                            <el-option :value="1" :label="$t('common.completed')" key="1"></el-option>
                            <el-option :value="2" :label="$t('common.failed')" key="2"></el-option>
                          </el-select>
                        </template>
                      </el-table-column>
                      <el-table-column key="actions" width="100" prop="id" label="Actions">
                        <template #default="scope">
                          <n-button native-type="button" type="success" class="btn btn-sm btn-icon"
                                    @click="updateShareholder(scope.$index)"
                                    :disabled="loading || saving">
                            <i class="now-ui-icons ui-1_check"></i>
                          </n-button>
                          <n-button native-type="button" type="danger" class="btn btn-sm btn-icon"
                                    @click="deleteShareholder(scope.$index)"
                                    :disabled="loading || saving">
                            <i class="now-ui-icons ui-1_simple-remove"></i>
                          </n-button>
                        </template>
                      </el-table-column>

                    </el-table>
                  </div>
                </div>

                <div class="row m-3">
                  <div class="col-12 text-center" v-if="sumShares !== 100">
                    <p class="text-danger"> {{ $t('company.wrong_sum_shares', [sumShares]) }}</p>
                  </div>
                  <div class="col-12 text-center">
                    <n-button native-type="button" type="primary" class="btn"
                              @click="addShareholder()"
                              :disabled="loading || saving">
                      <i class="now-ui-icons ui-1_simple-add"></i> Add shareholder
                    </n-button>

                    <div v-if="errorShareholder" class="text-danger">
                      {{ errorShareholder }}
                    </div>
                  </div>
                </div>


            </div>
          </div>
        </vnud-card>

      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElUpload, ElButton } from "element-plus";
  import { Form } from "vee-validate";
  import Swal from "sweetalert2";

  export default {
    name: "ManageUser",
    components: {
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      Form,
      [ElInput.name]: ElInput,
      [ElDatePicker.name]: ElDatePicker,
      [ElUpload.name]: ElUpload,
      [ElButton.name]: ElButton,
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        errorCompany: null,
        errorShareholder: null,
        userData: null,
        company: {
          name: null
        },
        yearsAgo16: (new Date).setFullYear((new Date).getFullYear() - 16)
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                 error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t("error." + stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      disabledDate(time) {
        return time.getTime() > this.yearsAgo16;
      },
      updateCompany() {
        this.errorCompany = null;

        //check required fields
        this.company.name = this.company.name.trim();
        if(this.company.name === "") {
            this.errorCompany = "The name of the company cannot be empty.";
            return;
        }

        this.company.description = this.company.description.trim();
        if(this.company.description === "")
        {
            this.errorCompany = "The description of the company cannot be empty.";
            return;
        }

        let shareCapitalInt = parseInt(this.company.shareCapital);
        if(isNaN(shareCapitalInt) || shareCapitalInt < 0) {
            this.errorCompany = "The Share Capital must be a number greater than 0.";
            return;
        }

        // status = 1 solo se tutti i campi sono stati messi (address) perchè se no le fatture sono incomplete
        if (this.company.status === 1 && (!this.company.address || this.company.address.trim() === "")) {
            this.errorCompany = "The company can be set to incorporated only if the address line 1 field is filled (necessary for invoicing).";
            return;
        }

        this.saving = true;

        let config = {
          data: {
            name: this.company.name,
            description: this.company.description,
            shareCapital: shareCapitalInt,
            status: this.company.status,
            compiler: "/api/users/" + this.userData.id,
            termsAccepted: true,
            address: this.company.address,
            country: this.company.country,
            companyNumber: this.company.companyNumber,
            vatNumber: this.company.vatNumber,
            taxCode: this.company.taxCode,
          }
        };
        if(this.company.id) {
          config.method = "patch";
          config.url = process.env.VUE_APP_SERVER_BASE_URL + 'companies/' + this.company.id;
          config.headers = {'Content-Type': 'application/merge-patch+json'};
        }
        else {
          config.method = "post";
          config.url = process.env.VUE_APP_SERVER_BASE_URL + 'companies';
        }

        axios(config).then(response => {
          ElMessage.success('Company data successfully saved!');
          this.company = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      updateShareholder(index) {
        this.errorShareholder = null;

        this.company.shareholders[index].name = this.company.shareholders[index].name.trim();
        if (this.company.shareholders[index].name === "") {
            this.errorShareholder = "The name of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].surname = this.company.shareholders[index].surname.trim();
        if (this.company.shareholders[index].surname === "") {
            this.errorShareholder = "The surname of the shareholder cannot be empty";
            return;
        }

        let sharePercFloat = parseFloat(this.company.shareholders[index].shareOwnedPerc);
        if (sharePercFloat < 0 || sharePercFloat > 100) {
            this.errorShareholder = "The percentage of share owned by the shareholder must be a number grater o equal to 0 and lower or equal to 100";
            return;
        }

        this.company.shareholders[index].email = this.company.shareholders[index].email.trim().toLowerCase();
        if (this.company.shareholders[index].email === "")
        {
            this.errorShareholder = "The email of the shareholder cannot be empty";
            return;
        }

        if (this.company.shareholders[index].dateOfBirth === null) {
            this.errorShareholder = "The date of Birth of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].placeOfBirth = this.company.shareholders[index].placeOfBirth.trim();
        if (this.company.shareholders[index].placeOfBirth === "") {
            this.errorShareholder = "The place of Birth of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].addressStreet = this.company.shareholders[index].addressStreet.trim();
        if (this.company.shareholders[index].addressStreet === "") {
            this.errorShareholder = "The address of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].addressCity = this.company.shareholders[index].addressCity.trim();
        if (this.company.shareholders[index].addressCity === "") {
            this.errorShareholder = "The city of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].addressCountry = this.company.shareholders[index].addressCountry.trim();
        if (this.company.shareholders[index].addressCountry === "") {
            this.errorShareholder = "The country of the shareholder cannot be empty";
            return;
        }

        this.company.shareholders[index].phoneNumber = this.company.shareholders[index].phoneNumber.trim();
        if (this.company.shareholders[index].phoneNumber === "") {
            this.errorShareholder = "The Phone Number of the shareholder cannot be empty";
            return;
        }

        this.saving = true;

        let data = {
          name: this.company.shareholders[index].name,
          surname: this.company.shareholders[index].surname,
          shareOwnedPerc: sharePercFloat,
          isDirector: this.company.shareholders[index].isDirector,
          email: this.company.shareholders[index].email,
          identityVerificationStatus: this.company.shareholders[index].identityVerificationStatus,
          isShareholder: this.company.shareholders[index].shareOwnedPerc > 0,
          dateOfBirth: new Date(this.company.shareholders[index].dateOfBirth).toDateString(),
          placeOfBirth: this.company.shareholders[index].placeOfBirth,
          addressStreet: this.company.shareholders[index].addressStreet,
          addressCity: this.company.shareholders[index].addressCity,
          addressCountry: this.company.shareholders[index].addressCountry,
          phoneNumber: this.company.shareholders[index].phoneNumber,
        };
        let config = {};

        if(this.company.shareholders[index].id) {
          config = {
            method: 'patch',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'shareholders/' + this.company.shareholders[index].id,
            data: data,
            headers: {'Content-Type': 'application/merge-patch+json'}
          };
        }
        else
        {
          data['company'] = this.company['@id'];
          config = {
            method: 'post',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'shareholders',
            data: data
          };
        }

        axios(config).then(response => {
          if(!this.company.shareholders[index].id)
          {
            this.company.shareholders[index].id = response.data.id;
          }
          ElMessage.success('Shareholder data successfully saved!');
        }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
      },
      deleteShareholder(index) {
        if(!this.company.shareholders[index].id)
        {
          // shareholder not yet persisted on DB.
          this.company.shareholders.splice(index, 1);
        }
        else {
          Swal.fire({
            title: 'Delete shareholder',
            text: this.company.shareholders[index].email + " will be deleted. Do you confirm?",
            showCancelButton: true,
            confirmButtonText: 'Delete',
            confirmButtonColor: '#FF3636',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.saving = true;
              return axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'shareholders/' + this.company.shareholders[index].id)
                      .then(response => {
                        this.company.shareholders.splice(index, 1);
                        ElMessage.success('Shareholder successfully deleted!');
                      }).catch(error => {
                        this.showError(error);
                      }).finally(() => {
                        this.saving = false;
                      });
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // if (result.isConfirmed) {
            // }
          });
        }
      },
      addShareholder() {
        this.company.shareholders.push({name: "", surname: "", shareOwnedPerc: 0, isDirector: false, email: "",
          identityVerificationStatus: 0, isShareholder: false, dateOfBirth: "", placeOfBirth: "",
          addressStreet: "", addressCity: "", addressCountry: "", phoneNumber: ""});
      },
      async recaptcha() {
          await this.$recaptchaLoaded();
          return await this.$recaptcha('manage_user');
      },
      changeEmail() {
          Swal.fire({
              title: 'Change e-mail',
              text: "Insert the new e-mail address",
              input: "email",
              inputValue: this.userData.email,
              showCancelButton: true,
              confirmButtonText: 'Change',
              showLoaderOnConfirm: true,
              preConfirm: async (newEmail) => {
                  this.saving = true;
                  let captchaToken = await this.recaptcha();
                  return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.userData.id + "/change_email",
                      {email: newEmail, captchaToken: captchaToken},
                      {headers: {'Content-Type': 'application/merge-patch+json'}})
                  .then(response => {
                      this.userData.email = newEmail;
                      ElMessage.success('E-mail address successfully changed!');
                  }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
              },
              allowOutsideClick: () => !Swal.isLoading(),
              backdrop: true
          }).then((result) => {
              // if (result.isConfirmed) {
              // }
          });
      },
      changeEmailVerified() {
          Swal.fire({
              title: 'Is the e-mail verified?',
              text: "Please be sure that the e-mail address is correct. We use it to sent invoices and reminders.",
              input: 'radio',
              inputOptions: {true: "Yes", false: "No"},
              inputValidator: (value) => {
                  if (!value) {
                      return 'You need to choose something!'
                  }
              },
              showCancelButton: true,
              confirmButtonText: 'Change',
              showLoaderOnConfirm: true,
              preConfirm: async (newValue) => {
                  this.saving = true;
                  let captchaToken = await this.recaptcha();
                  console.log("newValue: " + newValue);
                  return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.userData.id,
                      {emailVerified: (newValue === "true"), captchaToken: captchaToken},
                      {headers: {'Content-Type': 'application/merge-patch+json'}})
                      .then(response => {
                          this.userData.emailVerified = (newValue === "true");
                          ElMessage.success('E-mail verification status successfully changed!');
                      }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
              },
              allowOutsideClick: () => !Swal.isLoading(),
              backdrop: true
          }).then((result) => {
              // if (result.isConfirmed) {
              // }
          });
      },
      changeHasSubscription() {
          // This change does not affect any payment or scheduled payment. This can be used only to let customers proceed without buying any subscription.
          Swal.fire({
              title: 'Skip subscription payment',
              html: "This customer does not have an active subscription. <br> " +
                  "Do you want to let the customer proceed without buying any subscription? <br><br>" +
                  "Please remember that this change does not affect any payment or scheduled payment. " +
                  "This can be used only to let customers proceed with filling company info without first buying any subscription.",
              showCancelButton: true,
              confirmButtonText: 'Change',
              showLoaderOnConfirm: true,
              allowOutsideClick: () => !Swal.isLoading(),
              backdrop: true,
              preConfirm: async (newValue) => {
                  Swal.showLoading();
                  this.saving = true;
                  let captchaToken = await this.recaptcha();
                  return axios.patch(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.userData.id + "/restricted",
                      {hasActiveSubscription: !this.userData.hasActiveSubscription, captchaToken: captchaToken},
                      {headers: {'Content-Type': 'application/merge-patch+json'}})
                      .then(response => {
                          this.userData.hasActiveSubscription = !this.userData.hasActiveSubscription;
                          ElMessage.success('Subscription status successfully changed');
                      }).catch(error => {this.showError(error);}).finally(() => {this.saving = false; Swal.hideLoading();});
              },
          }).then((result) => {
              // if (result.isConfirmed) {
              // }
          });
      },
    },
    computed: {
      sumShares() {
        let sum = 0;
        if(this.company.shareholders) {
          for (let i = 0; i < this.company.shareholders.length; i++) {
            let val = parseFloat(this.company.shareholders[i].shareOwnedPerc);
            if (!isNaN(val))
              sum += val;
          }
        }
        return sum;
      }
    },
    beforeMount() {
      let fetchedId = this.$route.params.id;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId)
      .then(response => {
        this.userData = response.data;
        if(response.data.company)
        {
          let companyID = response.data.company.split("/");
          companyID = companyID[companyID.length-1];
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'companies/' + companyID)
          .then(response => {
            this.company = response.data;
          }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        }
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>
</style>

<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-4 mx-auto">
        <div class="card mt-5">
          <div class="card-body text-center">
            <h5 class="">Aggiorna metodo di pagamento</h5>
            <n-button native-type="button" type="primary" class="btn"
                      :disabled="loading || saving"
                      v-on:click="stripePortal()">
              <i class="now-ui-icons shopping_credit-card"></i> Aggiorna
            </n-button>

          </div>
        </div>
      </div>

      <div class="col-12 col-lg-8 mx-auto">
        <div class="row">
          <div class="col-12 m-auto">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 text-center">
                    <p v-if="loading" class="text-danger mt-3">{{ $t("common.loading") }}...</p>
                  </div>

                  <div class="col-12">
                    <h4 class="title mb-0">{{ $t('invoices.invoices') }}</h4>
                    <p>For older invoices, please contact us.</p>
                    <el-table class="table-striped" :data="invoices" border style="width: 100%">

                      <el-table-column key="Date" :min-width="110" prop="createdAt" :label="$t('invoices.date')">
                        <template #default="scope">
                          {{ formatDate(scope.row.createdAt, true) }}
                        </template>
                      </el-table-column>
                      <el-table-column key="number" :min-width="100" prop="invoiceNumber" :label="$t('invoices.number')">
                        <template #default="scope">
                          {{ scope.row.invoiceNumber }} - {{ scope.row.year }}
                        </template>
                      </el-table-column>
                      <el-table-column key="total" :min-width="100" prop="total" :label="$t('invoices.amount')">
                        <template #default="scope">
                          {{ formatPrice(scope.row.total) }}
                        </template>
                      </el-table-column>

                      <el-table-column key="notes" :min-width="100" prop="total" label="Notes">
                        <template #default="scope">
                          <span v-if="scope.row.subscription && scope.row.subscription.stripeInvoices">
                            <span v-for="inv in scope.row.subscription.stripeInvoices" v-bind:key="inv">
                              <span v-if="inv.paidOutsideStripeDate">
                                <span class="text-break">{{ $t('subh_status.paid_outside_stripe') }} {{ $t('common.on') }} {{formatDate(inv.paidOutsideStripeDate, false)}}</span>
                              </span>
                            </span>
                          </span>
                        </template>
                      </el-table-column>

                      <el-table-column key="actions" :min-width="70" prop="id" :label="$t('invoices.download')">
                        <template #default="scope">
                          <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                    :disabled="loading || saving"
                                    v-on:click="downloadInvoice(scope.row.id)">
                            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                          </n-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>

                  <div class="col-12">
                    <div class="row">
                      <div class="order-0 order-lg-0 col-6 col-lg-3">
                        <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                        <el-select class="select-default" v-model="pageSize" value="" label=""
                          v-on:change="handlePageSizeChange">
                          <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                          <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                          <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                          <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                        </el-select>
                      </div>

                      <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                        <el-pagination
                                class="pt-3"
                                v-model:currentPage="currentPageNumber"
                                v-model:page-size="pageSize"
                                :page-sizes="[10,20,50,100]"
                                :pager-count="11"
                                :small="true"
                                :disabled="loading || saving"
                                layout="prev, pager, next"
                                :total="totalItems"
                                @current-change="handlePageChange"
                        >
                        </el-pagination>
                      </div>

                      <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                        <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="col-12 m-auto" v-if="creditNotes.length > 0">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-12">
                <h4 class="title">{{ $t('invoices.credit_notes') }}</h4>

                <el-table class="table-striped" :data="creditNotes" border style="width: 100%">

                  <el-table-column key="Date" :min-width="110" prop="createdAt" :label="$t('invoices.date')">
                    <template #default="scope">
                      {{ formatDate(scope.row.createdAt, true) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="number" :min-width="100" prop="number" :label="$t('invoices.credit_note_number')">
                    <template #default="scope">
                      {{ scope.row.number }} - {{ scope.row.year }}
                    </template>
                  </el-table-column>
                  <el-table-column key="total" :min-width="110" prop="total" :label="$t('invoices.amount')">
                    <template #default="scope">
                      - {{ formatPrice(scope.row.total) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="actions" :min-width="70" prop="id" :label="$t('invoices.download')">
                    <template #default="scope">
                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                :disabled="loading || saving"
                                v-on:click="downloadCreditNote(scope.row.id)">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="creditNotePageSize" value="" label=""
                               v-on:change="creditNoteHandlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="creditNoteCurrentPageNumber"
                            v-model:page-size="creditNotePageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="creditNoteTotalItems"
                            @current-change="creditNoteHandlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ creditNoteTotalItems }}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElUpload, ElButton, ElPagination } from "element-plus";
  import NButton from "src/components/Button";
  import Swal from "sweetalert2";

  export default {
    name: "ListInvoices",
    components: {
      NButton,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElInput.name]: ElInput,
      [ElDatePicker.name]: ElDatePicker,
      [ElUpload.name]: ElUpload,
      [ElButton.name]: ElButton,
      [ElPagination.name]: ElPagination,
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        invoices: [],
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
        creditNotes: [],
        creditNoteTotalItems: 0,
        creditNoteCurrentPageNumber: 1,
        creditNotePageSize: 20
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true) {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
      formatPrice(price) {
          return "€ " + (price).toFixed(2);
      },
      handlePageSizeChange () {
        this.loadInvoices(this.currentPageNumber, this.pageSize);
      },
      handlePageChange (val) {
        this.loadInvoices(val, this.pageSize);
      },
      loadInvoices(page, pageSize) {
        this.loading = true;
        this.error = null;
        let config = {
            method: 'get',
            url: process.env.VUE_APP_SERVER_BASE_URL + 'invoices/get_my_invoices?itemsPerPage=' + pageSize + '&page=' + page
        };

        axios(config).then(response => {
            this.invoices = response.data["hydra:member"];
            this.totalItems = response.data["hydra:totalItems"];
            this.currentPageNumber = page;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      downloadInvoice(id) {
          this.loading = true;
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-invoice/' + id, {responseType: 'blob'})
              .then(response => {
                  const blob = new Blob([response.data],{type:'application/pdf'});
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = "invoice.pdf";
                  link.click();
                  URL.revokeObjectURL(link.href);
              }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      creditNoteHandlePageSizeChange () {
          this.loadCreditNotes(this.creditNoteCurrentPageNumber, this.creditNotePageSize);
      },
      creditNoteHandlePageChange (val) {
          this.loadCreditNotes(val, this.creditNotePageSize);
      },
      loadCreditNotes(page, pageSize) {
          this.loading = true;
          this.error = null;
          let config = {
              method: 'get',
              url: process.env.VUE_APP_SERVER_BASE_URL + 'credit_notes/get_my_credit_notes?itemsPerPage=' + pageSize + '&page=' + page
          };

          axios(config).then(response => {
              this.creditNotes = response.data["hydra:member"];
              this.creditNoteTotalItems = response.data["hydra:totalItems"];
              this.creditNoteCurrentPageNumber = page;
          }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      downloadCreditNote(id) {
          this.loading = true;
          axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-credit_note/' + id, {responseType: 'blob'})
          .then(response => {
              const blob = new Blob([response.data],{type:'application/pdf'});
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = "credit_note.pdf";
              link.click();
              URL.revokeObjectURL(link.href);
          }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
      },
      stripePortal() {
        Swal.fire({
          title: this.$i18n.t("common.loading"),
            didOpen: () => {
                Swal.showLoading();
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_stripe/customer_portal')
                    .then(response => {
                        window.location.href = response.data;
                    }).catch(error => {

                    if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                        if (error.response.data.message) {
                            ElMessage.error(this.$i18n.t("error.login_expired"));
                            this.$store.commit('logout');
                            this.$router.push('/login');
                        }
                    } else {
                        ElMessage.error(this.$i18n.t('err_loading_page'));
                        console.log(error);
                        Swal.close();
                    }
                }).finally(() => {});
            },
            allowOutsideClick: () => false
        });

      }
    },
    computed: {
    },
    beforeMount() {
      this.loadInvoices(this.currentPageNumber, this.pageSize);
      this.loadCreditNotes(this.creditNoteCurrentPageNumber, this.creditNotePageSize);
    },
  }
</script>

<style scoped>
</style>

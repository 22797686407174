<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-12"><p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p></div>
      <div class="col-md-10 m-auto">
        <simple-wizard>
          <template v-slot:header>
            <h3 class="card-title">{{ $t('company.build') }}</h3>
            <h3 class="description">

            </h3>
          </template>

          <wizard-tab
                  :before-change="() => validateStep('step1')"
                  :label="$t('company.company')"
                  icon="now-ui-icons shopping_shop">
            <form-first-step
                    ref="step1"
                    @on-validated="onStepValidated"
                    @on-new-data="saveData">
            </form-first-step>
          </wizard-tab>

          <wizard-tab
                  :before-change="() => validateStep('step2')"
                  :label="$tc('company.shareholder', 2)"
                  icon="now-ui-icons business_badge">
            <form-second-step
                    ref="step2"
                    :num-shareholders="data.numShareholders"
                    @on-validated="onStepValidated"
                    @on-new-data="saveData">
            </form-second-step>
          </wizard-tab>

          <wizard-tab
                  :before-change="() => validateStep('step3')"
                  :label="$t('company.director')"
                  icon="now-ui-icons business_briefcase-24">
            <form-third-step
                    ref="step3"
                    @on-validated="onStepValidated"
                    @on-new-data="saveData">
            </form-third-step>
          </wizard-tab>

          <wizard-tab
                  :before-change="() => validateStep('step4')"
                  :label="$t('company.last_step')"
                  icon="now-ui-icons sport_trophy">
            <form-fourth-step ref="step4"
                    @on-validated="wizardComplete"
                    @on-new-data="saveData">
            </form-fourth-step>
            <div v-if="saving" class="col-12 text-center text-primary mt-4"><i class="fa fa-spin fa-spinner"></i> {{ $t('common.saving') }}</div>
            <div v-if="error" class="col-12 text-danger text-center mt-4"> {{ error }}</div>
          </wizard-tab>
        </simple-wizard>
      </div>
    </div>
  </div>
</template>

<script>


import axios from "axios";
import Swal from "sweetalert2";
import { SimpleWizard, WizardTab } from "src/components";
import FormFirstStep from "src/pages/Backend/completeRegistration/FormFirstStep";
import FormSecondStep from "src/pages/Backend/completeRegistration/FormSecondStep";
import FormThirdStep from "src/pages/Backend/completeRegistration/FormThirdStep";
import FormFourthStep from "src/pages/Backend/completeRegistration/FormFourthStep";
import { ElMessage } from "element-plus";


export default {
  name: "registration-form",
  components: {
    FormThirdStep,
    SimpleWizard,
    WizardTab,
    FormFirstStep,
    FormSecondStep,
    FormFourthStep,
  },
  data() {
    return {
      error: null,
      buttonDisabled: false,
      saving: false,
      loading: false,
      data: {
        numShareholders: 1
      },
      userId: this.$store.getters.getUserId
    }
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated, model) {
      this.wizardModel = { ...this.wizardModel, ...model };
    },
    saveData(data) {
      for(let key in data) {
        this.data[key] = data[key];
      }
    },
    wizardComplete(validated) {
      if (!validated.valid)
        return false;
      this.saving = true;
      this.error = null;
      let data = {
        name: this.data.companyName,
        description: this.data.description,
        shareCapital: parseInt(this.data.shares),
        termsAccepted: this.data.compiler.termsAccepted,
        compilerName: this.data.compiler.name,
        compilerSurname: this.data.compiler.surname,
        shareholders: []
      };

      for(let i=0; i<this.data.shareholders.length; i++)
      {
        let s = {
          name: this.data.shareholders[i].name,
          surname: this.data.shareholders[i].surname,
          dateOfBirth: new Date(this.data.shareholders[i].birth).toDateString(),
          placeOfBirth: this.data.shareholders[i].placeBirth,
          addressStreet: this.data.shareholders[i].addressStreet,
          addressCity: this.data.shareholders[i].addressCity,
          addressCountry: this.data.shareholders[i].addressCountry,
          shareOwnedPerc: parseInt(this.data.shareholders[i].shareOwned),
          isDirector: this.data.shareholders[i].isDirector === 'true',
          phoneNumber: this.data.shareholders[i].phone,
          email: this.data.shareholders[i].email,
          isShareholder: true
        };
        data.shareholders.push(s);
      }

      if(this.data.director.isPresent)
      {
        let d = { name: this.data.director.name,
                  surname: this.data.director.surname,
                  dateOfBirth: new Date(this.data.director.birth).toDateString(),
                  placeOfBirth: this.data.director.placeBirth,
                  addressStreet: this.data.director.addressStreet,
                  addressCity: this.data.director.addressCity,
                  addressCountry: this.data.director.addressCountry,
                  email: this.data.director.email,
                  phoneNumber: this.data.director.phone,
                  shareOwnedPerc: 0,
                  isShareholder: false,
                  isDirector: true,
        };
        data.shareholders.push(d);
      }

      axios.post(process.env.VUE_APP_SERVER_BASE_URL + "companies", data)
      .then((response) => {
        this.$store.commit("setCompany", response.data);
        Swal.fire({
          title: this.$i18n.t('common.done'),
          text: this.$i18n.t('company.company_created'),
          icon: 'success',
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
        }).then((result) => {
          this.$router.push('/dashboard');
        });
      }).catch((error) => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message)
        {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        console.log(error);
      }).finally(() => {this.saving = false; });
    },
  },
  setup() {
  },
  beforeMount() {
      // Se hai già company, redirect to dashboard
      this.loading = true;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + this.userId + '/get_company')
      .then(response => {
          if(response.data.company)
          {
              this.$store.commit("setCompany", response.data);
              this.$router.push('/dashboard');
          }
      }).catch(error => {
          this.error = "";
          if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
              this.error = this.$i18n.t("error.login_expired");
              this.$store.commit('logout');
              this.$router.push('/login');
          }
          else if (error.response && error.response.data && error.response.data.violations &&
              error.response.data.violations.length > 0) {
              for (let i = 0; i < error.response.data.violations.length; i++)
              {
                  let stringErr = error.response.data.violations[i].message;
                  if(stringErr.endsWith("."))
                      stringErr = stringErr.slice(0, -1);
                  this.error += this.$i18n.t(stringErr) + ". ";
              }
          } else if(error.response && error.response.data && error.response.data.message)
          {
              let stringErr = error.response.data.message;
              if(stringErr.endsWith("."))
                  stringErr = stringErr.slice(0, -1);
              this.error = this.$i18n.t("error." + stringErr);
          }
          else this.error = this.$i18n.t("error.try_again");
          ElMessage.error(this.error);
          console.log(error);
      }).finally(() => {this.loading = false;});
  }
};
</script>

<style>
  .navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
  }
</style>

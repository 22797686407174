<template>
  <div>


    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <h4 class="title">{{ $tc('royalty.royalty', 2) }}
                <n-button native-type="button" type="outline-primary" class="px-2 btn-icon" v-on:click="showInfoBox" style="font-size: 14px">
                  <font-awesome-icon icon="info"></font-awesome-icon>
                </n-button>
                </h4>

                <p class="text-justify">{{ $t('royalty.what_is_2') }} <b v-if="royaltiesDeadline !== null">{{ formatDate(royaltiesDeadline, false) }}</b>.</p>
                <p v-if="balanceStart !== null && balanceEnd !== null">{{ $t('document.financial_statement_dates') }} <b>{{ formatDate(balanceStart, false) }} - {{ formatDate(balanceEnd, false) }}</b></p>
                <p v-if="loading" class="m-1 text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{$t('common.loading')}}...</p>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mb-2">{{ $t('common.date') }}:</label>
                <br>
                <el-date-picker
                        v-model="date"
                        type="date"
                        format="DD/MM/YYYY"
                        value-format="DD/MM/YYYY"
                        :disabled-date="startDisabledDate"
                        :disabled="disableActions">
                </el-date-picker>
              </div>


              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mb-2">{{ $t('common.amount') }}:</label>
                <br>
                <el-input
                        v-model="amount"
                        name="amount"
                        type="number"
                        :disabled="disableActions">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mb-0">{{ $t('royalty.select_currency') }}:</label>
                <el-select class="select-default" v-model="currency" value-key="currencyCode" filterable
                           :placeholder="$t('common.type_to_search')"
                           :disabled="disableActions">
                  <el-option v-for="c in listCurrencies" v-bind:key="c"
                             class="select-default"
                             :label="c.currencySymbol + ' - ' + c.currencyCode"
                             :value="c"></el-option>
                </el-select>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mb-2">{{ $t('royalty.beneficiary_name') }}:</label>
                <br>
                <el-input
                        v-model="beneficiaryName"
                        name="beneficiaryName"
                        type="text"
                        :disabled="disableActions">
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
                <label class="mb-0">{{ $t('royalty.select_country') }}:</label>
                <el-select class="select-default" v-model="beneficiaryCountry" filterable
                           :placeholder="$t('common.type_to_search')"
                           :disabled="disableActions">
                  <el-option v-for="c in listCountries" v-bind:key="c"
                             class="select-default"
                             :label="c.name"
                             :value="c.name"></el-option>
                </el-select>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto pt-4">
                <n-button native-type="button" type="primary" class="d-block px-2"
                          v-on:click="addToTable"
                          :disabled="disableActions">
                  {{ $t('royalty.add_to_table') }}
                </n-button>
              </div>


              <div v-if="saving" class="col-12 text-center mt-3">
                <p class="text-center text-danger"><i class="fa fa-spinner fa-spin"></i> {{$t('common.saving')}}...</p>
              </div>

              <div class="col-12 text-center mt-3" v-if="form_error">
                <p class="text-center text-danger"> {{ form_error }}</p>
              </div>

            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">

                <el-table class="table-striped" :data="conversions" border style="width: 100%">

                  <el-table-column key="royaltyDate" :min-width="80" prop="royaltyDate" :label="$t('common.date')">
                    <template #default="scope">
                      {{ formatDate(scope.row.royaltyDate, false) }}
                    </template>
                  </el-table-column>
                  <el-table-column key="amount" :min-width="120" prop="amount" :label="$t('common.amount')">
                    <template #default="scope">
                      {{ scope.row.amount }} {{ scope.row.hmrcRateRef.currencyCode }}
                    </template>
                  </el-table-column>
                  <el-table-column key="rate" :min-width="120" prop="rate" :label="$t('royalty.rate')">
                    <template #default="scope">
                      {{ scope.row.hmrcRateRef.rate }}
                    </template>
                  </el-table-column>
                  <el-table-column key="amountInPounds" :min-width="120" prop="amountInPounds" :label="$t('royalty.poundsAmount')">
                    <template #default="scope">
                      {{ scope.row.amountInPounds }} £
                    </template>
                  </el-table-column>
                  <el-table-column key="beneficiaryName" :min-width="120" prop="beneficiaryName" :label="$t('royalty.beneficiary')">
                    <template #default="scope">
                      {{ scope.row.beneficiaryName }} - {{ scope.row.beneficiaryCountry }}
                    </template>
                  </el-table-column>
                  <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">
                    <template #default="scope">
                      <n-button native-type="button" type="danger" class="btn btn-sm btn-icon"
                                :disabled="loading || saving"
                                v-on:click="deleteRoyalty(scope.$index)">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </n-button>
                    </template>
                  </el-table-column>
                </el-table>

              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-right">
                <p class="font-weight-bold">{{ $t('royalty.sum') }}: {{ sumConversions }} £</p>
              </div>
            </div>


            <div class="row">
              <div class="col-12 col-md-6 m-auto text-center pt-3">
                <n-button native-type="button" type="primary" class="d-block m-auto px-2"
                          v-on:click="exportTable"
                          :disabled="conversions.length === 0">
                  {{ $t('royalty.csv_export') }}
                </n-button>
              </div>

              <div class="col-12 col-md-6 m-auto text-center pt-3">
                <n-button native-type="button" type="primary" class="d-block m-auto px-2"
                          v-on:click="deliverTable"
                          :disabled="conversions.length === 0 || disableActions">
                  {{ $t('royalty.deliver_royalties') }}
                </n-button>
              </div>
            </div>


            <div class="row">
              <div class="col-12 m-auto text-center pt-3">
                <p class="text-justify">{{ $t('royalty.exchange_rate_note') }} <a href="https://www.gov.uk/government/collections/exchange-rates-for-customs-and-vat">HMRC</a>.</p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElDatePicker, ElInput, ElSelect, ElOption, ElTable, ElTableColumn } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "RoyaltiesCalculator",
    components: {
        [ElDatePicker.name]: ElDatePicker,
        [ElInput.name]: ElInput,
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        [ElTable.name]: ElTable,
        [ElTableColumn.name]: ElTableColumn
    },
    data () {
      return {
        loading: false,
        saving: false,
        error: null,
        form_error: null,
        conversions: [],
        date: null,
        amount: null,
        currency: null,
        listCurrencies: [{currencyCode: "GBP", currencySymbol: "£"}, {currencyCode: "EUR", currencySymbol: "€"}, {currencyCode: "USD", currencySymbol: "$"}],
        beneficiaryName: null,
        beneficiaryCountry: null,
        listCountries: [
            {name: 'Afghanistan', code: 'AF'},
            {name: 'Åland Islands', code: 'AX'},
            {name: 'Albania', code: 'AL'},
            {name: 'Algeria', code: 'DZ'},
            {name: 'American Samoa', code: 'AS'},
            {name: 'AndorrA', code: 'AD'},
            {name: 'Angola', code: 'AO'},
            {name: 'Anguilla', code: 'AI'},
            {name: 'Antarctica', code: 'AQ'},
            {name: 'Antigua and Barbuda', code: 'AG'},
            {name: 'Argentina', code: 'AR'},
            {name: 'Armenia', code: 'AM'},
            {name: 'Aruba', code: 'AW'},
            {name: 'Australia', code: 'AU'},
            {name: 'Austria', code: 'AT'},
            {name: 'Azerbaijan', code: 'AZ'},
            {name: 'Bahamas', code: 'BS'},
            {name: 'Bahrain', code: 'BH'},
            {name: 'Bangladesh', code: 'BD'},
            {name: 'Barbados', code: 'BB'},
            {name: 'Belarus', code: 'BY'},
            {name: 'Belgium', code: 'BE'},
            {name: 'Belize', code: 'BZ'},
            {name: 'Benin', code: 'BJ'},
            {name: 'Bermuda', code: 'BM'},
            {name: 'Bhutan', code: 'BT'},
            {name: 'Bolivia', code: 'BO'},
            {name: 'Bosnia and Herzegovina', code: 'BA'},
            {name: 'Botswana', code: 'BW'},
            {name: 'Bouvet Island', code: 'BV'},
            {name: 'Brazil', code: 'BR'},
            {name: 'British Indian Ocean Territory', code: 'IO'},
            {name: 'Brunei Darussalam', code: 'BN'},
            {name: 'Bulgaria', code: 'BG'},
            {name: 'Burkina Faso', code: 'BF'},
            {name: 'Burundi', code: 'BI'},
            {name: 'Cambodia', code: 'KH'},
            {name: 'Cameroon', code: 'CM'},
            {name: 'Canada', code: 'CA'},
            {name: 'Cape Verde', code: 'CV'},
            {name: 'Cayman Islands', code: 'KY'},
            {name: 'Central African Republic', code: 'CF'},
            {name: 'Chad', code: 'TD'},
            {name: 'Chile', code: 'CL'},
            {name: 'China', code: 'CN'},
            {name: 'Christmas Island', code: 'CX'},
            {name: 'Cocos (Keeling) Islands', code: 'CC'},
            {name: 'Colombia', code: 'CO'},
            {name: 'Comoros', code: 'KM'},
            {name: 'Congo', code: 'CG'},
            {name: 'Congo, The Democratic Republic of the', code: 'CD'},
            {name: 'Cook Islands', code: 'CK'},
            {name: 'Costa Rica', code: 'CR'},
            {name: 'Cote D\'Ivoire', code: 'CI'},
            {name: 'Croatia', code: 'HR'},
            {name: 'Cuba', code: 'CU'},
            {name: 'Cyprus', code: 'CY'},
            {name: 'Czech Republic', code: 'CZ'},
            {name: 'Denmark', code: 'DK'},
            {name: 'Djibouti', code: 'DJ'},
            {name: 'Dominica', code: 'DM'},
            {name: 'Dominican Republic', code: 'DO'},
            {name: 'Ecuador', code: 'EC'},
            {name: 'Egypt', code: 'EG'},
            {name: 'El Salvador', code: 'SV'},
            {name: 'Equatorial Guinea', code: 'GQ'},
            {name: 'Eritrea', code: 'ER'},
            {name: 'Estonia', code: 'EE'},
            {name: 'Ethiopia', code: 'ET'},
            {name: 'Falkland Islands (Malvinas)', code: 'FK'},
            {name: 'Faroe Islands', code: 'FO'},
            {name: 'Fiji', code: 'FJ'},
            {name: 'Finland', code: 'FI'},
            {name: 'France', code: 'FR'},
            {name: 'French Guiana', code: 'GF'},
            {name: 'French Polynesia', code: 'PF'},
            {name: 'French Southern Territories', code: 'TF'},
            {name: 'Gabon', code: 'GA'},
            {name: 'Gambia', code: 'GM'},
            {name: 'Georgia', code: 'GE'},
            {name: 'Germany', code: 'DE'},
            {name: 'Ghana', code: 'GH'},
            {name: 'Gibraltar', code: 'GI'},
            {name: 'Greece', code: 'GR'},
            {name: 'Greenland', code: 'GL'},
            {name: 'Grenada', code: 'GD'},
            {name: 'Guadeloupe', code: 'GP'},
            {name: 'Guam', code: 'GU'},
            {name: 'Guatemala', code: 'GT'},
            {name: 'Guernsey', code: 'GG'},
            {name: 'Guinea', code: 'GN'},
            {name: 'Guinea-Bissau', code: 'GW'},
            {name: 'Guyana', code: 'GY'},
            {name: 'Haiti', code: 'HT'},
            {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
            {name: 'Holy See (Vatican City State)', code: 'VA'},
            {name: 'Honduras', code: 'HN'},
            {name: 'Hong Kong', code: 'HK'},
            {name: 'Hungary', code: 'HU'},
            {name: 'Iceland', code: 'IS'},
            {name: 'India', code: 'IN'},
            {name: 'Indonesia', code: 'ID'},
            {name: 'Iran, Islamic Republic Of', code: 'IR'},
            {name: 'Iraq', code: 'IQ'},
            {name: 'Ireland', code: 'IE'},
            {name: 'Isle of Man', code: 'IM'},
            {name: 'Israel', code: 'IL'},
            {name: 'Italy', code: 'IT'},
            {name: 'Jamaica', code: 'JM'},
            {name: 'Japan', code: 'JP'},
            {name: 'Jersey', code: 'JE'},
            {name: 'Jordan', code: 'JO'},
            {name: 'Kazakhstan', code: 'KZ'},
            {name: 'Kenya', code: 'KE'},
            {name: 'Kiribati', code: 'KI'},
            {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
            {name: 'Korea, Republic of', code: 'KR'},
            {name: 'Kuwait', code: 'KW'},
            {name: 'Kyrgyzstan', code: 'KG'},
            {name: 'Lao People\'S Democratic Republic', code: 'LA'},
            {name: 'Latvia', code: 'LV'},
            {name: 'Lebanon', code: 'LB'},
            {name: 'Lesotho', code: 'LS'},
            {name: 'Liberia', code: 'LR'},
            {name: 'Libyan Arab Jamahiriya', code: 'LY'},
            {name: 'Liechtenstein', code: 'LI'},
            {name: 'Lithuania', code: 'LT'},
            {name: 'Luxembourg', code: 'LU'},
            {name: 'Macao', code: 'MO'},
            {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
            {name: 'Madagascar', code: 'MG'},
            {name: 'Malawi', code: 'MW'},
            {name: 'Malaysia', code: 'MY'},
            {name: 'Maldives', code: 'MV'},
            {name: 'Mali', code: 'ML'},
            {name: 'Malta', code: 'MT'},
            {name: 'Marshall Islands', code: 'MH'},
            {name: 'Martinique', code: 'MQ'},
            {name: 'Mauritania', code: 'MR'},
            {name: 'Mauritius', code: 'MU'},
            {name: 'Mayotte', code: 'YT'},
            {name: 'Mexico', code: 'MX'},
            {name: 'Micronesia, Federated States of', code: 'FM'},
            {name: 'Moldova, Republic of', code: 'MD'},
            {name: 'Monaco', code: 'MC'},
            {name: 'Mongolia', code: 'MN'},
            {name: 'Montserrat', code: 'MS'},
            {name: 'Morocco', code: 'MA'},
            {name: 'Mozambique', code: 'MZ'},
            {name: 'Myanmar', code: 'MM'},
            {name: 'Namibia', code: 'NA'},
            {name: 'Nauru', code: 'NR'},
            {name: 'Nepal', code: 'NP'},
            {name: 'Netherlands', code: 'NL'},
            {name: 'Netherlands Antilles', code: 'AN'},
            {name: 'New Caledonia', code: 'NC'},
            {name: 'New Zealand', code: 'NZ'},
            {name: 'Nicaragua', code: 'NI'},
            {name: 'Niger', code: 'NE'},
            {name: 'Nigeria', code: 'NG'},
            {name: 'Niue', code: 'NU'},
            {name: 'Norfolk Island', code: 'NF'},
            {name: 'Northern Mariana Islands', code: 'MP'},
            {name: 'Norway', code: 'NO'},
            {name: 'Oman', code: 'OM'},
            {name: 'Pakistan', code: 'PK'},
            {name: 'Palau', code: 'PW'},
            {name: 'Palestinian Territory, Occupied', code: 'PS'},
            {name: 'Panama', code: 'PA'},
            {name: 'Papua New Guinea', code: 'PG'},
            {name: 'Paraguay', code: 'PY'},
            {name: 'Peru', code: 'PE'},
            {name: 'Philippines', code: 'PH'},
            {name: 'Pitcairn', code: 'PN'},
            {name: 'Poland', code: 'PL'},
            {name: 'Portugal', code: 'PT'},
            {name: 'Puerto Rico', code: 'PR'},
            {name: 'Qatar', code: 'QA'},
            {name: 'Reunion', code: 'RE'},
            {name: 'Romania', code: 'RO'},
            {name: 'Russian Federation', code: 'RU'},
            {name: 'RWANDA', code: 'RW'},
            {name: 'Saint Helena', code: 'SH'},
            {name: 'Saint Kitts and Nevis', code: 'KN'},
            {name: 'Saint Lucia', code: 'LC'},
            {name: 'Saint Pierre and Miquelon', code: 'PM'},
            {name: 'Saint Vincent and the Grenadines', code: 'VC'},
            {name: 'Samoa', code: 'WS'},
            {name: 'San Marino', code: 'SM'},
            {name: 'Sao Tome and Principe', code: 'ST'},
            {name: 'Saudi Arabia', code: 'SA'},
            {name: 'Senegal', code: 'SN'},
            {name: 'Serbia and Montenegro', code: 'CS'},
            {name: 'Seychelles', code: 'SC'},
            {name: 'Sierra Leone', code: 'SL'},
            {name: 'Singapore', code: 'SG'},
            {name: 'Slovakia', code: 'SK'},
            {name: 'Slovenia', code: 'SI'},
            {name: 'Solomon Islands', code: 'SB'},
            {name: 'Somalia', code: 'SO'},
            {name: 'South Africa', code: 'ZA'},
            {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
            {name: 'Spain', code: 'ES'},
            {name: 'Sri Lanka', code: 'LK'},
            {name: 'Sudan', code: 'SD'},
            {name: 'Suriname', code: 'SR'},
            {name: 'Svalbard and Jan Mayen', code: 'SJ'},
            {name: 'Swaziland', code: 'SZ'},
            {name: 'Sweden', code: 'SE'},
            {name: 'Switzerland', code: 'CH'},
            {name: 'Syrian Arab Republic', code: 'SY'},
            {name: 'Taiwan, Province of China', code: 'TW'},
            {name: 'Tajikistan', code: 'TJ'},
            {name: 'Tanzania, United Republic of', code: 'TZ'},
            {name: 'Thailand', code: 'TH'},
            {name: 'Timor-Leste', code: 'TL'},
            {name: 'Togo', code: 'TG'},
            {name: 'Tokelau', code: 'TK'},
            {name: 'Tonga', code: 'TO'},
            {name: 'Trinidad and Tobago', code: 'TT'},
            {name: 'Tunisia', code: 'TN'},
            {name: 'Turkey', code: 'TR'},
            {name: 'Turkmenistan', code: 'TM'},
            {name: 'Turks and Caicos Islands', code: 'TC'},
            {name: 'Tuvalu', code: 'TV'},
            {name: 'Uganda', code: 'UG'},
            {name: 'Ukraine', code: 'UA'},
            {name: 'United Arab Emirates', code: 'AE'},
            {name: 'United Kingdom', code: 'GB'},
            {name: 'United States', code: 'US'},
            {name: 'United States Minor Outlying Islands', code: 'UM'},
            {name: 'Uruguay', code: 'UY'},
            {name: 'Uzbekistan', code: 'UZ'},
            {name: 'Vanuatu', code: 'VU'},
            {name: 'Venezuela', code: 'VE'},
            {name: 'Viet Nam', code: 'VN'},
            {name: 'Virgin Islands, British', code: 'VG'},
            {name: 'Virgin Islands, U.S.', code: 'VI'},
            {name: 'Wallis and Futuna', code: 'WF'},
            {name: 'Western Sahara', code: 'EH'},
            {name: 'Yemen', code: 'YE'},
            {name: 'Zambia', code: 'ZM'},
            {name: 'Zimbabwe', code: 'ZW'}
        ],
        chData: null,
        balanceStart: null,
        balanceEnd: null,
        royaltiesDeadline: null,
        rates_max_date: new Date(),
        unlockDate: null
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else if (error.response && error.response.data && error.response.data['hydra:description'])
                this.error = this.$i18n.t("error." + error.response.data['hydra:description']);
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            if(this.balanceStart !== null)
                return time.getTime() < (new Date(this.balanceStart)) || time.getTime() > this.rates_max_date;
            else
                return time.getTime() > this.rates_max_date;
        },
        addToTable() {
            this.form_error = null;
            if (this.date === null) {
                this.form_error = this.$t("royalty.date_required");
                return;
            }
            if (this.amount === null) {
                this.form_error = this.$t("royalty.amount_required");
                return;
            }

            if (this.currency === null) {
                this.form_error = this.$t("royalty.currency_required");
                return;
            }

            if (this.beneficiaryName === null) {
                this.form_error = this.$t("royalty.beneficiary_name_required");
                return;
            }

            if (this.beneficiaryCountry === null) {
                 this.form_error = this.$t("royalty.beneficiary_country_required");
                return;
            }

            this.saving = true;
            axios.post(process.env.VUE_APP_SERVER_BASE_URL + "royalties",
                { royaltyDate: new Date(this.date).toDateString(),
                  amount: parseFloat(this.amount),
                  currencyCode: this.currency.currencyCode,
                  beneficiaryName: this.beneficiaryName,
                  beneficiaryCountry: this.beneficiaryCountry})
            .then(response => {
                this.conversions.push(response.data);
                this.date = null;
                this.amount = null;
                this.currency = null;
                this.beneficiaryName = null;
                this.beneficiaryCountry = null;
            }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        },
        deleteRoyalty(index) {
            Swal.fire({
                title: this.$t("are_you_sure"),
                text: this.$t("royalty.delete_message") + this.formatDate(this.conversions[index].royaltyDate, false) + ". " + this.$t("common.proceed") + "?",
                confirmButtonClass: "btn btn-primary",
                showCancelButton: true,
                confirmButtonText: this.$t("common.yes_proceed"),
                confirmButtonColor: "#FF3636",
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'royalties/' + this.conversions[index].id)
                    .then(response => {
                        ElMessage.success(this.$t("royalty.removed"));
                        this.conversions.splice(index, 1);
                    }).catch(error => {this.showError(error);}).finally(() => {Swal.hideLoading();});
                }
            });
        },
        exportTable() {
            let csvContent = "data:text/csv;charset=utf-8,\uFEFF";
            csvContent += ["Date;Amount;Currency;Rate;Amount in Pounds;Beneficiary;Beneficiary Country;\n"];

            for (let row of this.conversions)
            {
                let date = this.formatDate(row.royaltyDate, true);
                let amount = row.amount;
                let currency = row.hmrcRateRef.currencyCode;
                let rate = row.hmrcRateRef.rate;
                let amountInPounds = row.amountInPounds;
                let beneficiaryName = row.beneficiaryName;
                let beneficiaryCountry = row.beneficiaryCountry;
                csvContent += [date + ";" + amount + ";" + currency + ";" + rate + ";" + amountInPounds + ";" +
                              beneficiaryName + ";" + beneficiaryCountry + ";\n"];
            }

            csvContent += ["\n\n;;;Total:;" + this.sumConversions + ";\n"];

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", "royalties.csv");
            link.click();
        },
        showInfoBox() {
            Swal.fire({
                position: 'top-end',
                icon: 'info',
                html: '<p class="text-justify">' + this.$t('royalty.what_is') + '</p>',
                showConfirmButton: false,
                showCloseButton: true
            });

        },
        deliverTable() {
            Swal.fire({
                icon: 'info',
                title: this.$t("royalty.deliver_royalties"),
                html: '<p class="text-justify">' + this.$t("royalty.delivery_message") + '</p>',
                showCloseButton: true,
                showCancelButton: true,
                confirmButtonClass: "btn btn-primary",
                confirmButtonText: this.$t("common.confirm"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    axios.post(process.env.VUE_APP_SERVER_BASE_URL + "royalties/deliver/", {})
                    .then(response => {
                        if(response.data.status === 'ok')
                            this.$router.push('/documents/sign_document/' + response.data.doc_id);
                        else
                            this.showError(response);
                    }).catch(error => {this.showError(error);}).finally(() => {});
                }
            });
        }
    },
    computed: {
        sumConversions() {
            let sum = 0;
            for (let item of this.conversions)
                sum += item.amountInPounds;
            return sum;
        },
        disableActions() {
            return (this.unlockDate !== null && this.unlockDate > new Date()) || this.royaltiesDeadline < new Date();
        }
    },
    beforeMount() {
        //TODO: pagination?
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "royalties/get_mine/")
        .then(response => {
            this.conversions = response.data["hydra:member"];
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});


        axios.get(process.env.VUE_APP_SERVER_BASE_URL + "hmrc_rates/get_last_period_available")
        .then(response => {
            console.log(response.data);
            //Remember: the months in date go from 0 to 11. the day 0 means, to to the last of the previous month
            this.rates_max_date = new Date(response.data.period.year, parseInt(response.data.period.month), 0);
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});


        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_house/filter_by_customer/')
            .then(response => {
                this.chData = response.data;
                if (response.data.accountLastMadeUpTo) {
                    this.balanceStart = response.data.accountLastMadeUpTo;
                    if (!response.data.accountNextMadeUpTo) {
                        let d = (new Date(response.data.accountLastMadeUpTo));
                        this.balanceEnd = d.setFullYear(d.getFullYear() + 1);
                    }
                }

                if (response.data.accountNextMadeUpTo) {
                    this.balanceEnd = response.data.accountNextMadeUpTo;
                    if (this.balanceStart === null) {
                        let d = (new Date(response.data.accountNextMadeUpTo));
                        this.balanceStart = d.setFullYear(d.getFullYear() - 1);
                    }
                }

                if (this.balanceEnd !== null) {
                    this.royaltiesDeadline = new Date(this.balanceEnd);
                    this.royaltiesDeadline.setMonth(this.royaltiesDeadline.getMonth() -1);
                    this.royaltiesDeadline.setDate(this.royaltiesDeadline.getDate() - 7);
                }

                if (response.data.royaltiesUnlockDate)
                    this.unlockDate = response.data.royaltiesUnlockDate;

            }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>

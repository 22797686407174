<template>
  <div>
    <div class="row">
      <vnud-card centered class="text-center p-5 col-12 col-lg-8 m-auto" style="background-color: rgba(255,255,255, 20%);">
        <template v-slot:header>
          <div>
<!--            <div class="logo-container">-->
<!--              <img src="/img/logo_colors.png" alt="" />-->
<!--            </div>-->
          </div>
        </template>

        <div class="row">
          <div class="col-12 text-white">
            <h1><i class="not-found-icon nc-icon nc-puzzle-10"></i>404</h1>
            <h5>{{ $t('error.page_not_found') }}</h5>
          </div>
        </div>
      </vnud-card>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
};
</script>
<style lang="scss">
.not-found-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>

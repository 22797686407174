<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                <p v-if="userData" class="m-1 text-center font-weight-bold">
                  <span v-if="userData.company">{{ userData.company.name }}</span>
                  <span v-else>
                    {{userData.email}} <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                  </span>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-lg-6 text-center m-auto">
                <div>
                  <br><br>
                  <h5>Last synchronization:</h5>
                  <div v-if="!loading">
                    <div v-if="chData">
                      <p>{{ formatDate(chData.updateDate, true) }}</p>
                      <n-button native-type="button" type="info" class="btn btn-sm btn-link"
                                @click="refreshData()"
                                :disabled="loading || saving">
                        <i class="now-ui-icons arrows-1_refresh-69"></i> Manually Sync data
                      </n-button>
                    </div>
                    <div v-else>
                      <p>Never</p>
                      <div v-if="!company">
                        <p>No company data found.</p>
                        <p>Please, go to the page of the company to update its data:</p>
                        <router-link :to="'/manager/handle_user/' + userData.id">
                          <n-button native-type="button" type="info" class="btn btn-sm btn-link">
                            <i class="now-ui-icons arrows-1_refresh-69"></i> Go to company
                          </n-button>
                        </router-link>
                      </div>
                      <div v-else-if="!company.companyNumber">
                        <p>The company number is not present.</p>
                        <p>Please, go to the page of the company to update its data:</p>
                        <router-link :to="'/manager/handle_user/' + userData.id">
                          <n-button native-type="button" type="info" class="btn btn-sm btn-link">
                            <i class="now-ui-icons arrows-1_refresh-69"></i> Go to company
                          </n-button>
                        </router-link>
                      </div>
                      <div v-else>
                        <n-button native-type="button" type="info" class="btn btn-sm btn-link"
                                  @click="refreshData()"
                                  :disabled="loading || saving">
                          <i class="now-ui-icons arrows-1_refresh-69"></i> Manually Sync data
                        </n-button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>





    <div class="row">
      <div class="col-12 col-lg-8 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row pt-2" v-if="chData && chData.companyStatus">
              <div class="col-12 text-center">
                <p>Company Status: <b>{{ chData.companyStatus }}</b></p>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-12 col-md-6">
                <h4>Accounts:</h4>
                <div v-if="!chData">
                  No data available yet.
                </div>
                <div v-else>
                  Next accounts made up to
                  <b>
                    <span v-if="chData.accountNextMadeUpTo">
                      {{ formatDate(chData.accountNextMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  due by

                  <b>
                    <span v-if="chData.accountNextDue">
                      {{ formatDate(chData.accountNextDue, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  <br>
                  Last accounts made up to
                  <b>
                    <span v-if="chData.accountLastMadeUpTo">
                      {{ formatDate(chData.accountLastMadeUpTo, false) }}
                    </span>
                    <span v-else> - </span>
                  </b>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <h4>Confirmation statement:</h4>
                <div v-if="!chData">
                  No data available yet.
                </div>
                <div v-else>
                  Next statement date:
                  <b>
                    <span v-if="chData.confirmationStatementNextMadeUpTo">
                      {{ formatDate(chData.confirmationStatementNextMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  due by

                  <b>
                    <span v-if="chData.confirmationStatementNextDue">
                      {{ formatDate(chData.confirmationStatementNextDue, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>

                  <br>
                  Last statement dated
                  <b>
                    <span v-if="chData.confirmationStatementLastMadeUpTo">
                      {{ formatDate(chData.confirmationStatementLastMadeUpTo, false) }}
                      </span>
                    <span v-else> - </span>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElCheckbox, ElRadioGroup, ElRadio } from "element-plus";
  import Swal from "sweetalert2";


  export default {
    name: "ManageCompanyHouseData",
    components: {
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        userData: null,
        company: null,
        chData: null,
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
                return dateObj.toLocaleDateString();
        },
        disabledDate(time) {
            return time.getTime() > (new Date());
        },
        startDisabledDate(time) {
            return time.getTime() > this.endDate;
        },

        refreshData() {
            this.loading = true;
            Swal.fire({
                title: 'Please wait',
                text: "The synchronization process may take a while.",
                padding: "2em",
                // width: 600,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'manager/company_house/update_data/' + this.userData.id, {timeout: 300000})
            .then(response => {
                if (response.data.status === "ok") {
                    axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_houses/' + response.data.company_house_id)
                    .then(response => {
                        this.chData = response.data;
                        ElMessage.success("company house data successfully updated");
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false; Swal.close();});
                }
                else {
                    this.showError(response.data.error);
                    this.loading = false;
                    Swal.close();
                }
            }).catch(error => {this.showError(error); this.loading = false; Swal.close();});
        },
    },
    computed: {
    },
    beforeMount() {
        this.loading = true;
        let fetchedId = this.$route.params.user_id;
        if (!fetchedId) {
            this.showError("no_customer_selected");
            return;
        }

        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
        .then(response => {
            this.userData = response.data;
            if(response.data.company) {
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'companies/' + response.data.company.id)
                .then(response => {
                    this.company = response.data;
                    if(response.data.companyHouse) {
                        let companyHouseID = response.data.companyHouse.split("/");
                        companyHouseID = companyHouseID[companyHouseID.length-1];
                        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_houses/' + companyHouseID)
                            .then(response => {
                                this.chData = response.data;
                            }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
                    } else {this.loading = false;}
                }).catch(error => {this.showError(error); this.loading = false;});
            } else {this.loading = false;}
        }).catch(error => {this.showError(error); this.loading = false;});


    },
  }
</script>

<style scoped>

</style>

// =========================================================
// * Vue Now UI Dashboard PRO - v2.1.0
// =========================================================
//
// * Product Page: http://www.creative-tim.com/product/vue-now-ui-dashboard-pro
// * Copyright 2019 Creative Tim (http://www.creative-tim.com)
//
// * Designed by www.invisionapp.com Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import axiosConfig from "./axiosConfig";
import i18nConfig from "./i18nConfig";
import FontAwesomeIcon from "./fontawesomeConfig";
import { VueReCaptcha } from 'vue-recaptcha-v3';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = { containerClassName: "ct-notification" };

import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";

import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/scss/bootstrap.scss";
import "./assets/scss/now-ui-dashboard.scss";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(Toast, options);
appInstance.use(GlobalComponents);
appInstance.use(GlobalDirectives);
appInstance.use(i18nConfig);
appInstance.use(store);
appInstance.use(VueAxios, axios);
appInstance.use(VueReCaptcha, {siteKey: '6LftqLAeAAAAAOkX8kiYYX0eejvlP0vBN_pI2YwT'});
appInstance.use(axiosConfig);
appInstance.component("font-awesome-icon", FontAwesomeIcon);
appInstance.mount("#app");

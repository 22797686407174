<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="d-inline">{{ $t('document.upload_bank_statement_description') }} </p>
            <p v-if="chData" class="d-inline">{{ $t('document.financial_statement_dates') }} <b>{{ balanceStart }} - {{ balanceEnd }}</b></p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-3 m-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <h4 class="title m-auto">
                  <i class="text-info now-ui-icons business_bank" style="font-size: 28px"></i> {{ $t('document.upload_new') }}
                </h4>
              </div>
            </div>
            <upload-in-folder folder="bankStatement" @upload-completed="loadDocs(currentPageNumber, pageSize)"></upload-in-folder>

          </div>
        </div>
      </div>



      <div class="col-12 col-md-6 col-lg-8 col-xl-9 m-auto">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12 col-md-6">
                    <h4 class="title mt-1">{{ $t('document.bank_statement_uplaoded') }}</h4>
                  </div>

                  <div class="col-12 text-center">
                    <p v-if="loading" class="text-danger">{{ $t("common.loading") }}...</p>
                  </div>


                  <div class="col-12 col-lg-6">
                    <div class="row mt-lg-5">
                      <div class="col-6 col-xl-5 text-center">
                        <n-button class="m-auto btn-default btn-sm"
                                  :disabled="loading || saving || selectedDocuments.length === 0"
                                  v-on:click="downloadSelectedDocuments"
                        >
                          <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                          {{ $t('common.download') }} {{ selectedDocuments.length }} {{ $t('common.selected') }}
                        </n-button>
                      </div>

                      <div class="col-6 col-xl-5 text-center">
                        <n-button class="m-auto btn-default btn-sm"
                                  :disabled="loading || saving || selectedDocuments.length === 0"
                                  v-on:click="deleteSelectedDocuments"
                        >
                          <font-awesome-icon icon="trash"></font-awesome-icon>
                          {{ $t('common.delete') }} {{ selectedDocuments.length }} {{ $t('common.selected') }}
                        </n-button>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 my-2">
                    <label class="mt-3">{{ $t('document.search_doc') }}</label>
                    <el-input
                            v-model="searchDocument"
                            type="text"
                            name="searchDocument"
                            v-on:change="handleSearchDocument"
                            v-on:clear="loadDocs(currentPageNumber, pageSize)"
                            clearable
                            :disabled="loading"
                            :placeholder="$t('common.search_info')">
                      <template #prepend><i class="el-icon-search"></i></template>
                    </el-input>
                  </div>


                  <div class="col-12">
                    <el-table class="table-striped"
                              :data="documents"
                              @selection-change="documentSelection"
                              border style="width: 100%"
                    >

                      <el-table-column type="selection" width="55"></el-table-column>

                      <el-table-column key="regDate" :min-width="80" prop="regDate" :label="$t('document.upload_date')">
                        <template #default="scope">
                          {{ formatDate(scope.row.uploadDate, true) }}
                        </template>
                      </el-table-column>
                      <el-table-column key="name" :min-width="120" prop="name" label="File"></el-table-column>
                      <el-table-column key="notes" :min-width="120" prop="notes" :label="$t('document.notes')">
                        <template #default="scope">
                          <div class="row">
                            <div class="col-11">
                              <el-input
                                      v-model="scope.row.notes"
                                      name="notes"
                                      maxlength="500"
                                      show-word-limit
                                      rows="2"
                                      type="textarea"
                                      :placeholder="$t('document.notes')"
                                      v-on:change="saveNewNote(scope.$index)"
                              >
                              </el-input>
                            </div>
                            <div class="col-1 px-0 m-auto">
                              <span v-if="scope.row.savingNewNote">
                                <i class="fa fa-spinner fa-spin text-warning fa-lg"></i>
                              </span>
                              <span v-else-if="scope.row.savedNewNote">
                                <i class="fa fa-check text-success fa-lg"></i>
                              </span>
                              <span v-else-if="scope.row.errorSavingNewNote">
                                <i class="fa fa-times text-danger fa-lg"></i>
                              </span>
                            </div>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column key="actions" :min-width="60" prop="id" :label="$t('common.actions')">
                        <template #default="scope">
                          <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                    :disabled="loading || saving"
                                    v-on:click="preview(scope.row.id)">
                            <font-awesome-icon icon="eye"></font-awesome-icon>
                          </n-button>

                          <n-button native-type="button" type="info" class="btn btn-sm btn-icon ml-1"
                                    :disabled="loading || saving"
                                    v-on:click="downloadDocument(scope.row.id, scope.row.name)">
                            <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                          </n-button>
                          <n-button native-type="button" type="danger" class="btn btn-sm btn-icon ml-1"
                                    :disabled="loading || saving"
                                    v-if="!scope.row.signed"
                                    v-on:click="deleteDocument(scope.row.id)">
                            <font-awesome-icon icon="trash"></font-awesome-icon>
                          </n-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>


                  <div class="col-12">
                    <div class="row">
                      <div class="order-0 order-lg-0 col-6 col-lg-3">
                        <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                        <el-select class="select-default" v-model="pageSize" value="" label=""
                                   v-on:change="handlePageSizeChange">
                          <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                          <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                          <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                          <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                        </el-select>
                      </div>

                      <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                        <el-pagination
                                class="pt-3"
                                v-model:currentPage="currentPageNumber"
                                v-model:page-size="pageSize"
                                :page-sizes="[10,20,50,100]"
                                :pager-count="11"
                                :small="true"
                                :disabled="loading || saving"
                                layout="prev, pager, next"
                                :total="totalItems"
                                @current-change="handlePageChange"
                        >
                        </el-pagination>
                      </div>

                      <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                        <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                      </div>

                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import axios from 'axios';
  import { ElMessage, ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElPagination } from "element-plus";
  import UploadInFolder from "./UploadInFolder";
  import Swal from 'sweetalert2';

  export default {
    name: "UploadBankStatement",
    components: {
      UploadInFolder,
      [ElSelect.name]: ElSelect,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn,
      [ElPagination.name]: ElPagination,
      [ElInput.name]: ElInput
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        form: {fileList: [], notes: ''},
        maxUpload: 50,
        listErrors: [],
        numFilesUploaded: 0,
        chData: null,
        balanceStart: null,
        balanceEnd: null,
        documents: [],
        totalItems: 0,
        currentPageNumber: 1,
        pageSize: 20,
        searchDocument: null,
        selectedDocuments: [],
      }
    },
    methods: {
      showError(error) {
        this.error = "";
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++)
          {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message) {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        ElMessage.error(this.error);
        console.log(error);
      },
      formatDate(date, time = true) {
        var dateObj = new Date(date);
        if(time)
          return dateObj.toLocaleString();
        else
          return dateObj.toLocaleDateString();
      },
        preview(docIri) {
            let id = docIri.split("/");
            id = id[id.length-1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-customer_document/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data], {type: response.headers['content-type']});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.target = "_blank";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);});
        },
        downloadDocument(docIri, nomeFile) {
            let id = docIri.split("/");
            id = id[id.length-1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-customer_document/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data]);
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = nomeFile;
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        },
        handlePageSizeChange () {
            this.loadDocs(this.currentPageNumber, this.pageSize);
        },
        handlePageChange (val) {
            this.loadDocs(val, this.pageSize);
        },
        loadDocs(page, pageSize) {
            this.loading = true;
            this.error = null;

            let filter = "";
            if (this.searchDocument && this.searchDocument.length > 2)
                filter = "&name=" + encodeURIComponent(this.searchDocument);

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/personal?folder=bankStatement&itemsPerPage=' + pageSize + '&page=' + page + filter)
                .then(response => {
                    this.documents = response.data["hydra:member"];
                    this.totalItems = response.data["hydra:totalItems"];
                    // this.activeFolder = folder;
                    this.currentPageNumber = page;
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
        deleteDocument(id) {
            this.saving = true;
            axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/' + id)
                .then(response => {
                    this.loadDocs(this.currentPageNumber, this.pageSize);
                    ElMessage.success(this.$t("document.delete_success"));
                }).catch(error => {this.showError(error);}).finally(() => {this.saving = false;});
        },
        handleSearchDocument() {
            if (this.searchDocument && this.searchDocument.length > 2)
                this.loadDocs(this.currentPageNumber, this.pageSize);
        },
        saveNewNote(index) {
            this.documents[index].savingNewNote = true;
            this.documents[index].savedNewNote = false;
            this.documents[index].errorSavingNewNote = false;

            axios.patch(process.env.VUE_APP_SERVER_BASE_URL + "customer_documents/" + this.documents[index].id,
                {notes: this.documents[index].notes},
                {headers: {'Content-Type': 'application/merge-patch+json'}
                }).then((response) => {
                this.documents[index].savedNewNote = true;
            }).catch(error => {
                this.showError(error);
                this.documents[index].errorSavingNewNote = true;
            }).finally(() => {
                this.documents[index].savingNewNote = false;
            });
        },
        documentSelection(items) {
            this.selectedDocuments = items;
        },
        downloadSelectedDocuments() {
            this.loading = true;

            let ids = "";
            for(let i=0; i<this.selectedDocuments.length; i++) {
                ids += this.selectedDocuments[i].id;
                if(i<this.selectedDocuments.length -1)
                    ids += ",";
            }

            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/get_zip_selected?ids=' + ids, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/zip'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = this.$i18n.tc('documents_folders.bankStatement', 2) + ".zip";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
        deleteSelectedDocuments() {
            Swal.fire({
                title: this.$i18n.t("document.delete_docs"),
                text: this.$i18n.t("document.going_to_delete") + this.selectedDocuments.length + " " +
                    this.$i18n.tc("document.document", this.selectedDocuments.length > 1? 2 : 1) +
                    ". " + this.$i18n.t("common.proceed") + "?",
                showCancelButton: true,
                confirmButtonText: this.$i18n.t("common.proceed"),
                confirmButtonColor: '#FF3636'
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.showLoading();
                    let count = this.selectedDocuments.length;

                    for(let document of this.selectedDocuments) {
                        axios.delete(process.env.VUE_APP_SERVER_BASE_URL + 'customer_documents/' + document.id)
                        .then(response => {}).catch(error => {
                            this.showError(error);
                        }).finally(() => {
                            count = count - 1;
                            if (count === 0) {
                                Swal.hideLoading();
                                this.loadDocs(this.currentPageNumber, this.pageSize);
                            }
                        });
                    }
                }
            });
        },
    },
    computed: {
    },
    beforeMount() {
        this.loading = true;
        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'company_house/filter_by_customer/')
        .then(response => {
            this.chData = response.data;
            if (response.data.accountLastMadeUpTo) {
                this.balanceStart = this.formatDate(response.data.accountLastMadeUpTo, false);
                if (!response.data.accountNextMadeUpTo) {
                    let d = (new Date(response.data.accountLastMadeUpTo));
                    d = d.setFullYear(d.getFullYear() + 1);
                    this.balanceEnd = this.formatDate(d, false);
                }
            }

            if (response.data.accountNextMadeUpTo) {
                this.balanceEnd = this.formatDate(response.data.accountNextMadeUpTo, false);
                if (this.balanceStart === null) {
                    let d = (new Date(response.data.accountNextMadeUpTo));
                    d = d.setFullYear(d.getFullYear() - 1);
                    this.balanceStart = this.formatDate(d, false);
                }
            }

        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

        this.loadDocs(this.currentPageNumber, this.pageSize);
    },
  }
</script>

<template>
  <div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="title">Download Pro Forma</h4>
          </div>
          <div class="card-body">
            <div class="row">

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by year"
                        v-model="filter.year"
                        name="filter_year"
                        maxlength="4"
                        type="number"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by name"
                        v-model="filter.customerName"
                        name="filter_name"
                        type="text"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                <el-input
                        placeholder="filter by email"
                        v-model="filter.customerContact"
                        name="filter_email"
                        type="email"
                        clearable>
                </el-input>
              </div>

              <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
                <n-button native-type="button" type="primary" class="btn mt-0" @click="loadInvoices(pageSize, 1)" :disabled="loading || saving">Apply Filters</n-button>
              </div>

              <div class="col-12 m-auto pt-4 text-center">
                <n-button native-type="button" type="primary" class="btn" @click="downloadInvoices" :disabled="loading || saving">Download All</n-button>

                <span v-if="loading" class="text-danger d-block"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
              </div>



              <div class="col-12 mt-5">


                <el-table class="table-striped" :data="invoices" border style="width: 100%">

                  <el-table-column key="number" :min-width="100" prop="number" label="Pro Forma n.">
                    <template #default="scope">
                      <span v-if="scope.row.number">
                        {{ scope.row.number }} - {{ scope.row.year }}
                      </span>
                      <span v-else>Pending..</span>
                    </template>
                  </el-table-column>

                  <el-table-column key="Date" :min-width="100" prop="createdAt" label="Date">
                    <template #default="scope">
                      {{ formatDate(scope.row.createdAt, true) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="customerName" :min-width="160" prop="customerName" label="To">
                    <template #default="scope">
                      {{ scope.row.customerName }} - {{ scope.row.customerContact }}
                    </template>
                  </el-table-column>

                  <el-table-column key="total" :min-width="90" prop="total" label="Total"></el-table-column>


                  <el-table-column key="actions" :min-width="120" class-name="text-center" :label="$t('common.actions')">
                    <template #default="scope">

                      <n-button native-type="button" type="primary" class="btn btn-sm btn-icon"
                                @click="downloadInvoice(scope.row.id)"
                                :disabled="!scope.row.number">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>


                      <router-link v-if="!scope.row.invoiceRef" :to="'/manager/new_pro_forma/' + scope.row.id">
                        <n-button native-type="button" type="warning" class="btn btn-sm btn-icon ml-1">
                          <i class="now-ui-icons arrows-1_refresh-69"></i>
                        </n-button>
                      </router-link>
                      <n-button v-else native-type="button" type="warning" class="btn btn-sm btn-icon ml-1" :disabled="true">
                        <i class="now-ui-icons arrows-1_refresh-69"></i>
                      </n-button>

                      <span v-if="scope.row.invoiceRef && scope.row.invoiceRef.invoiceNumber">
                        <n-button native-type="button" type="primary" class="btn btn-sm ml-1"
                                  @click="downloadInvoiceRef(scope.row.invoiceRef.id)">
                          INV {{ scope.row.invoiceRef.invoiceNumber }} {{ scope.row.invoiceRef.year }}
                        </n-button>
                      </span>
                      <span v-else>
                        <n-button native-type="button" type="primary" class="btn btn-sm btn-icon ml-1"
                                  @click="generateInvoiceRef(scope.row.id, scope.$index)"
                                  :disabled="scope.row.invoiceRef">
                          <i class="now-ui-icons shopping_tag-content"></i>
                        </n-button>
                      </span>

                    </template>
                  </el-table-column>

                  <template #empty="">
                    <span>{{ $t('common.no_data') }}</span>
                  </template>

                </el-table>

              </div>

              <div class="col-12">
                <div class="row">
                  <div class="order-0 order-lg-0 col-6 col-lg-3">
                    <label class="m-0">{{ $t('document.page_size') }}:</label><br>
                    <el-select class="select-default" v-model="pageSize" value="" label=""
                               v-on:change="handlePageSizeChange">
                      <el-option class="select-default" key="10" label="10" :value="10"></el-option>
                      <el-option class="select-default" key="20" label="20" :value="20"></el-option>
                      <el-option class="select-default" key="50" label="50" :value="50"></el-option>
                      <el-option class="select-default" key="100" label="100" :value="100"></el-option>
                    </el-select>
                  </div>

                  <div class="order-2 order-lg-1 col-lg-6 pt-4 text-center">
                    <el-pagination
                            class="pt-3"
                            v-model:currentPage="currentPageNumber"
                            v-model:page-size="pageSize"
                            :page-sizes="[10,20,50,100]"
                            :pager-count="11"
                            :small="true"
                            :disabled="loading || saving"
                            layout="prev, pager, next"
                            :total="totalItems"
                            @current-change="handlePageChange"
                    >
                    </el-pagination>
                  </div>

                  <div class="order-1 order-lg-2 col-6 col-lg-3 mt-4 pt-3 text-right">
                    <p>{{ $t('document.total') }}: {{ totalItems }}</p>
                  </div>

                </div>
              </div>


                <div class="col-12 mt-4">
                  <p class="m-0">Legenda: </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                    </n-button>
                    Download the pro forma
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="warning" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons arrows-1_refresh-69"></i>
                    </n-button>
                    Update the data and regenerate the pro forma
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm btn-icon m-0 mb-1" disabled>
                      <i class="now-ui-icons shopping_tag-content"></i>
                    </n-button>
                    Generate the invoice related to the pro forma
                  </p>

                  <p class="m-0">
                    <n-button native-type="button" type="primary" class="btn btn-sm m-0 mb-1" disabled>
                      INV ..
                    </n-button>
                    Download the invoice that refers to the pro forma
                  </p>
                </div>

            </div>
          </div>
        </div>
      </div>
    </div>




  </div>
</template>

<script>
    import axios from 'axios';
    import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage, ElDatePicker, ElPagination } from "element-plus";
    import Swal from "sweetalert2";


    export default {
        name: "DownloadInvoices",
        components: {
            [ElSelect.name]: ElSelect,
            [ElOption.name]: ElOption,
            [ElInput.name]: ElInput,
            [ElTable.name]: ElTable,
            [ElTableColumn.name]: ElTableColumn,
            [ElDatePicker.name]: ElDatePicker,
            [ElPagination.name]: ElPagination
        },
        data () {
            return {
                loading: false,
                saving: false,
                error: null,
                invoices: [],
                totalItems: 0,
                currentPageNumber: 1,
                pageSize: 20,
                filter: {year: null, customerName: null, customerContact: null},
            }
        },
        methods: {
            showError(error) {
                this.error = "";
                if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                    this.error = this.$i18n.t("error.login_expired");
                    this.$store.commit('logout');
                    this.$router.push('/login');
                }
                else if (error.response && error.response.data && error.response.data.violations &&
                    error.response.data.violations.length > 0) {
                    for (let i = 0; i < error.response.data.violations.length; i++)
                    {
                        let stringErr = error.response.data.violations[i].message;
                        if(stringErr.endsWith("."))
                            stringErr = stringErr.slice(0, -1);
                        this.error += this.$i18n.t(stringErr) + ". ";
                    }
                } else if(error.response && error.response.data && error.response.data.message)
                {
                    let stringErr = error.response.data.message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error = this.$i18n.t("error." + stringErr);
                }
                else this.error = this.$i18n.t("error.try_again");
                ElMessage.error(this.error);
                console.log(error);
            },
            disabledDate(time) {
                return time.getTime() > (new Date());
            },
            formatDate(date, time = true)
            {
                var dateObj = new Date(date);
                if(time)
                    return dateObj.toLocaleString();
                else
                    return dateObj.toLocaleDateString();
            },
            formatPrice(price)
            {
                return "€ " + (price/100).toFixed(2);
            },
            handlePageSizeChange () {
                this.loadInvoices(this.pageSize, this.currentPageNumber);
            },
            handlePageChange (val) {
                this.loadInvoices(this.pageSize, val);
            },
            loadInvoices(pageSize, page) {
                this.loading = true;
                this.error = null;
                let filters = "";
                if (this.filter.year !== null && this.filter.year !== "")
                    filters += "&year=" + this.filter.year;
                if (this.filter.customerName && this.filter.customerName !== "")
                    filters += "&customerName=" + encodeURIComponent(this.filter.customerName);
                if (this.filter.customerContact && this.filter.customerContact !== "")
                    filters += "&customerContact=" + this.filter.customerContact;

                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'pro_formas?itemsPerPage=' + pageSize + '&page=' + page + filters)
                    .then(response => {
                        this.invoices = response.data["hydra:member"];
                        this.totalItems = response.data["hydra:totalItems"];
                        this.currentPageNumber = page;
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadInvoices() {
                this.loading = true;
                let filters = "year=" + this.filter.year + "&customerName=" + this.filter.customerName +
                    "&customerContact=" + this.filter.customerContact;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'pro_formas/get_zip?' + filters, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/zip'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "pro_forma.zip";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            downloadInvoice(id) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-pro_forma/' + id, {responseType: 'blob'})
                .then(response => {
                    const blob = new Blob([response.data],{type:'application/pdf'});
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = "pro_forma.pdf";
                    link.click();
                    URL.revokeObjectURL(link.href);
                }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
            generateInvoiceRef(proformaId, index) {
                Swal.fire({
                    title: "Generate the invoice",
                    text: "You are going to generate the invoice that refer to this proforma. Please be sure to have received the payment first.",
                    showCancelButton: true,
                    confirmButtonText: 'Proceed',
                    confirmButtonColor: '#FF3636'
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.showLoading();
                        this.saving = true;
                        axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'pro_formas/' + proformaId + '/convert')
                        .then((response) => {
                            ElMessage.success("Invoice data saved! It will be generated in few minutes.");
                            this.invoices[index].invoiceRef = {id: response.data.id, invoiceNumber: null};
                        }).catch(error => {this.showError(error);}).finally(() => {this.saving = false; Swal.hideLoading();});
                    }
                });
            },
            downloadInvoiceRef(id) {
                this.loading = true;
                axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-invoice/' + id, {responseType: 'blob'})
                    .then(response => {
                        const blob = new Blob([response.data],{type:'application/pdf'});
                        const link = document.createElement('a');
                        link.href = URL.createObjectURL(blob);
                        link.download = "invoice.pdf";
                        link.click();
                        URL.revokeObjectURL(link.href);
                    }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
            },
        },
        computed: {
        },
        beforeMount() {
            this.loadInvoices(this.pageSize, this.currentPageNumber);
        },
    }
</script>

<style scoped>

</style>

<template>
  <div>
    <div class="row">

      <div v-if="error" class="col-12 mt-2 text-danger font-weight-bold">{{ error }}</div>
      <div class="col-12">
        <vnud-card plain class="card-subcategories">
          <template v-slot:header>
            <h4 class="card-title text-center">{{ $t('subscriptions.choose_subscription' )}}</h4>
            <br />
          </template>

          <tabs type="primary"
                tabContentClasses="tab-subcategories"
                square centered
                class="row"
                value="Self-Publishing">

            <tab-pane label="Self-Publishing" icon="now-ui-icons objects_umbrella-13" style="min-width: 100%; width: 100%">
              <div class="row">
                <div class="col-12">
                 <p class="text-center p-2">{{ $t('subscriptions.self_pub_description') }}</p>
                </div>
                <div class="col-auto m-auto text-center">
                  <el-table :data="self_tab.items"
                            header-cell-class-name="font-weight-bold"
                            :header-row-style="{'font-size': '12px'}"
                            >
                    <el-table-column width="400" label="" property="name"></el-table-column>
                    <el-table-column width="125" label="Basic" align="center">
                      <template v-slot:default="props">
                        <div v-if="props.row.last">

                          <n-button native-type="submit" type="primary" round size="sm" class="d-block m-auto px-2"
                                    :disabled="buttonDisabled"
                                    v-on:click="subscribe(self_tab.stripeIdBasic)">
                            {{ $t('common.buy') }}
                          </n-button>

                          <p class="mt-2 mb-0" style="word-break: normal">{{props.row.basic}}€ {{ $t('subscriptions.month') }}</p>
                        </div>
                        <div v-else-if="props.row.basic">
                          <i class="now-ui-icons ui-1_check text-success font-weight-bold" style="font-size: 18px;"></i>
                        </div>
                        <div v-else>
                          <i class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold" style="font-size: 18px"></i>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="125" label="Premium" align="center" fixed="right">
                      <template v-slot:default="props">
                        <div v-if="props.row.last">

                          <n-button native-type="button" type="primary" round size="sm" class="d-block m-auto px-2"
                                    :disabled="buttonDisabled"
                                    v-on:click="subscribe(self_tab.stripeIdPremium)">
                            {{ $t('common.buy') }}
                          </n-button>

                          <p class="mt-2 mb-0" style="word-break: normal">{{props.row.premium}}€ {{ $t('subscriptions.month') }}</p>
                        </div>
                        <div v-else-if="props.row.premium">
                          <i class="now-ui-icons ui-1_check text-success font-weight-bold" style="font-size: 18px"></i>
                        </div>
                        <div v-else>
                          <i class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold" style="font-size: 18px"></i>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>




            <tab-pane label="Affiliate Marketing & Dropshipping" icon="now-ui-icons shopping_shop">
              <div class="row">
                <div class="col-12">
              <p class="text-center p-2">{{ $t('subscriptions.affiliate_description') }}</p>
                </div>
                <div class="col-auto m-auto text-center">
                  <el-table :data="affiliate_tab.items" header-cell-class-name="font-weight-bold" :header-row-style="{'font-size': '12px'}">
                    <el-table-column width="400" style="max-width: 50%" label="" property="name"></el-table-column>
                    <el-table-column width="125" label="Basic" align="center" fixed="right">
                      <template v-slot:default="props">
                        <div v-if="props.row.last">
                          <n-button native-type="submit" type="primary" round size="sm" class="d-block m-auto px-2"
                                    :disabled="buttonDisabled"
                                    v-on:click="subscribe(affiliate_tab.stripeIdBasic)">
                            {{ $t('common.buy') }}
                          </n-button>
                          <p class="mt-2 mb-0" style="word-break: normal">{{props.row.basic}}€ {{ $t('subscriptions.month') }}</p>
                        </div>
                        <div v-else-if="props.row.basic">
                          <i class="now-ui-icons ui-1_check text-success font-weight-bold" style="font-size: 18px;"></i>
                        </div>
                        <div v-else>
                          <i class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold" style="font-size: 18px"></i>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>

            <tab-pane :label="$t('subscriptions.other_business')" icon="now-ui-icons ui-2_settings-90">
              <div class="row">
                <div class="col-12">
                  <p class="text-center p-2">{{ $t('subscriptions.other_description') }}</p>
                </div>
                <div class="col-auto m-auto text-center">
                  <el-table :data="other_tab.items" header-cell-class-name="font-weight-bold" :header-row-style="{'font-size': '12px'}">
                    <el-table-column width="400" label="" property="name"></el-table-column>
                    <el-table-column width="125" label="Basic" align="center">
                      <template v-slot:default="props">
                        <div v-if="props.row.last">
                          <n-button native-type="submit" type="primary" round size="sm" class="d-block m-auto px-2"
                                    :disabled="buttonDisabled"
                                    v-on:click="subscribe(other_tab.stripeIdBasic)">
                            {{ $t('common.buy') }}
                          </n-button>
                          <p class="mt-2 mb-0" style="word-break: normal">{{props.row.basic}}€ {{ $t('subscriptions.month') }}</p>
                        </div>
                        <div v-else-if="props.row.basic">
                          <i class="now-ui-icons ui-1_check text-success font-weight-bold" style="font-size: 18px;"></i>
                        </div>
                        <div v-else>
                          <i class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold" style="font-size: 18px"></i>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column width="125" label="Premium" align="center" fixed="right">
                      <template v-slot:default="props">
                        <div v-if="props.row.last">
                          <n-button native-type="button" type="primary" round size="sm" class="d-block m-auto px-2"
                                    :disabled="buttonDisabled"
                                    v-on:click="subscribe(other_tab.stripeIdPremium)">
                            {{ $t('common.buy') }}
                          </n-button>
                          <p class="mt-2 mb-0" style="word-break: normal">{{props.row.premium}}€ {{ $t('subscriptions.month') }}</p>
                        </div>
                        <div v-else-if="props.row.premium">
                          <i class="now-ui-icons ui-1_check text-success font-weight-bold" style="font-size: 18px"></i>
                        </div>
                        <div v-else>
                          <i class="now-ui-icons ui-1_simple-remove text-danger font-weight-bold" style="font-size: 18px"></i>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </vnud-card>
      </div>

      <input type="hidden" name="lookup_key" :value="stripe_price_lookup_key" />
<!--      <stripe-checkout-->
<!--              ref="stripe_checkout_item"-->
<!--              :pk="publishableKey"-->
<!--              :session-id="sessionId"-->
<!--      />-->
    </div>
  </div>
</template>

<script>

import { ref } from "vue";
import { TabPane, Tabs } from "src/components";
import { ElTable, ElTableColumn } from "element-plus";
import { Form } from "vee-validate";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
// import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  name: "new-subscription",
  components: {
    TabPane,
    Tabs,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    // StripeCheckout,
  },
  data() {
    return {
      error: null,
      buttonDisabled: false,
      saving: false,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
      self_tab: {
        stripeIdBasic: process.env.VUE_APP_STRIPE_SELFPUB_BASIC_PRICE_ID,
        stripeIdPremium: process.env.VUE_APP_STRIPE_SELFPUB_PREMIUM_PRICE_ID,
        subIdBasic: "",
        subIdPremium: "",
        items: [
          {name: "", basic: "200", premium: "300", last: true},
          {name: this.$i18n.t("subscriptions.list_services.incorporation"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.book_keeping"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.tax_return"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.financial_state"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.virtual_office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.bank_open"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.statement"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.billing"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.invoice_ocr"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.online_assistance"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.free_closure"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.community"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.business_school"), basic: false, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.annual_summit"), basic: false, premium: true},
          {name: "Total", basic: "200", premium: "300", last: true}
        ]

      },
      affiliate_tab: {
        stripeIdBasic: process.env.VUE_APP_STRIPE_AFFILIATE_BASIC_PRICE_ID,
        stripeIdPremium: "",
        subIdBasic: "",
        subIdPremium: "",
        items: [
          {name: "", basic: "300", premium: "", last: true},
          {name: this.$i18n.t("subscriptions.list_services.incorporation"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.book_keeping"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.tax_return"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.financial_state"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.vat_returns"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.virtual_office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.bank_open"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.statement"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.billing"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.invoice_ocr"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.online_assistance"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.community"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.business_school"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.annual_summit"), basic: true, premium: true},
          {name: "Total", basic: "300", premium: "", last: true}
        ]

      },
      other_tab: {
        stripeIdBasic: process.env.VUE_APP_STRIPE_OTHER_BASIC_PRICE_ID,
        stripeIdPremium: process.env.VUE_APP_STRIPE_OTHER_PREMIUM_PRICE_ID,
        subIdBasic: "",
        subIdPremium: "",
        items: [
          {name: "", basic: "200", premium: "300", last: true},
          {name: this.$i18n.t("subscriptions.list_services.incorporation"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.book_keeping"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.tax_return"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.financial_state"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.virtual_office"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.bank_open"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.statement"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.billing"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.invoice_ocr"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.online_assistance"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.free_closure"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.community"), basic: true, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.business_school"), basic: false, premium: true},
          {name: this.$i18n.t("subscriptions.list_services.annual_summit"), basic: false, premium: true},
          {name: "Total", basic: "200", premium: "300", last: true}
        ]

      },
      sessionId: "",
      stripe_price_lookup_key: "",
      sessionUrl: ""
    }
  },
  methods: {
    subscribe (subStripeId) {
      this.saving = true;
      this.buttonDisabled = true;
      this.error = null;
      this.stripe_price_lookup_key = subStripeId;

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscriptions_stripe/create_session/' + subStripeId)
      .then(response => {
        if(response.data.status === "ok") {
          this.sessionId = response.data.sessionId;
          this.sessionUrl = response.data.sessionUrl;
          // this.stripe_checkout_item.value.redirectToCheckout();
          window.open(this.sessionUrl, "_self");
        }
        else
          this.error = this.$i18n.t(response.data.message);
      }).catch(error => {
        if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
            this.error = this.$i18n.t("error.login_expired");
            this.$store.commit('logout');
            this.$router.push('/login');
        }
        else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
          for (let i = 0; i < error.response.data.violations.length; i++) {
            let stringErr = error.response.data.violations[i].message;
            if(stringErr.endsWith("."))
              stringErr = stringErr.slice(0, -1);
            this.error += this.$i18n.t(stringErr) + ". ";
          }
        } else if(error.response && error.response.data && error.response.data.message) {
          let stringErr = error.response.data.message;
          if(stringErr.endsWith("."))
            stringErr = stringErr.slice(0, -1);
          this.error = this.$i18n.t("error." + stringErr);
        }
        else this.error = this.$i18n.t("error.try_again");
        console.log(error);
      }).finally(() => {this.buttonDisabled = false;  this.saving = false;});
    },
  },
  setup() {
    let stripeScript = document.createElement('script');
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
    document.head.appendChild(stripeScript);

    // const stripe_checkout_item = ref(null);
    //
    // return {
    //   stripe_checkout_item
    // }
  },
  beforeMount() {
    // axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscription_histories/get_active_subscription')
    //         .then(response => {
    //           this.loading = false;
    //           this.userPlan = response.data;
    //         }).catch(error => {
    //   this.loading = false;
    //   if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
    //     if (error.response.data.message) {
    //       this.$store.commit('logout');
    //       this.$router.push('/login');
    //     }
    //   } else {
    //     Message.error(this.$i18n.t('subscriptions.error_loading_page'));
    //     console.log(error);
    //   }
    // });
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>

<template>
  <div>

      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-body">
                      <div class="row">
                          <div class="col-12">
                              <p v-if="loading" class="m-1 text-center text-danger">{{$t('common.loading')}}...</p>
                              <p v-if="userData" class="m-1 text-center font-weight-bold">
                                <span v-if="userData.company">{{ userData.company.name }}</span>
                                <span v-else>
                                  {{userData.email}} <span v-if="userData.firstName || userData.lastName">- {{userData.firstName}} {{userData.lastName}}</span>
                                </span>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>


    <div class="row">
      <div class="col-12 col-lg-10 m-auto">
        <div class="card">
          <div class="card-header">
            <h4 class="title">List Subscriptions</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <el-table class="table-striped" :data="subs" border style="width: 100%"
                          :header-cell-style="{'font-size': '14px', 'font-weight': 'bold'}">

                  <el-table-column key="createdAt" :min-width="110" prop="createdAt" label="Date">
                    <template #default="scope">
                        {{ formatDate(scope.row.createdAt, true) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="createdAt" :min-width="110" prop="startAt" label="Period">
                    <template #default="scope">
                        {{ formatDate(scope.row.startAt, false) }} - {{ formatDate(scope.row.endAt, false) }}
                    </template>
                  </el-table-column>

                  <el-table-column key="subscription" :min-width="140" prop="subscription.name" label="Subscription"></el-table-column>

                  <el-table-column key="status" :min-width="90" prop="status" label="Status">
                      <template #default="scope">
                          {{ $t("subh_status." + scope.row.status) }}
                      </template>
                  </el-table-column>

                  <el-table-column key="actions" :min-width="65" prop="invoice" label="Invoice">
                    <template #default="scope">

                      <n-button native-type="button" type="info" class="btn btn-sm btn-icon"
                                :disabled="loading || saving || scope.row.invoice === null || scope.row.invoice.invoiceNumber === null"
                                v-on:click="downloadInvoice(scope.row.invoice.id)">
                        <i class="now-ui-icons arrows-1_cloud-download-93"></i>
                      </n-button>
                    </template>
                  </el-table-column>

                  <template #empty="">
                      <span v-if="loading"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.loading') }}...</span>
                      <span v-else>{{ $t('common.no_data') }}</span>
                  </template>
                </el-table>
                  <div v-if="saving"><i class="fa fa-spinner fa-spin"></i> {{ $t('common.saving') }}...</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
  import axios from 'axios';
  import { ElSelect, ElOption, ElInput, ElTable, ElTableColumn, ElMessage } from "element-plus";


  export default {
    name: "UserSubscriptionHistory",
    components: {
      [ElSelect.name]: ElSelect,
      [ElInput.name]: ElInput,
      [ElOption.name]: ElOption,
      [ElTable.name]: ElTable,
      [ElTableColumn.name]: ElTableColumn
    },
    data () {
      return {
        loading: true,
        saving: false,
        error: null,
        userData: null,
        subs: [],
      }
    },
    methods: {
        showError(error) {
            this.error = "";
            if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
                this.error = this.$i18n.t("error.login_expired");
                this.$store.commit('logout');
                this.$router.push('/login');
            }
            else if (error.response && error.response.data && error.response.data.violations &&
                error.response.data.violations.length > 0) {
                for (let i = 0; i < error.response.data.violations.length; i++)
                {
                    let stringErr = error.response.data.violations[i].message;
                    if(stringErr.endsWith("."))
                        stringErr = stringErr.slice(0, -1);
                    this.error += this.$i18n.t(stringErr) + ". ";
                }
            } else if(error.response && error.response.data && error.response.data.message)
            {
                let stringErr = error.response.data.message;
                if(stringErr.endsWith("."))
                    stringErr = stringErr.slice(0, -1);
                this.error = this.$i18n.t("error." + stringErr);
            }
            else this.error = this.$i18n.t("error.try_again");
            ElMessage.error(this.error);
            console.log(error);
        },
        formatDate(date, time = true) {
            var dateObj = new Date(date);
            if(time)
                return dateObj.toLocaleString();
            else
            return dateObj.toLocaleDateString();
        },
        formatPrice(price)
        {
            return "€ " + (price/100).toFixed(2);
        },
        downloadInvoice(iri) {
            this.loading = true;
            let id = iri.split("/");
            id = id[id.length-1];
            axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'download-invoice/' + id, {responseType: 'blob'})
            .then(response => {
                const blob = new Blob([response.data],{type:'application/pdf'});
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = "invoice.pdf";
                link.click();
                URL.revokeObjectURL(link.href);
            }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
        },
    },
    computed: {
    },
    beforeMount() {
      this.loading = true;
      let fetchedId = this.$route.params.userid;
      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'users/' + fetchedId + '/basic')
        .then(response => {
            this.userData = response.data;
        }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});

      axios.get(process.env.VUE_APP_SERVER_BASE_URL + 'subscription_histories?userRef.id=' + fetchedId)
      .then(response => {
        this.subs = response.data['hydra:member'];
      }).catch(error => {this.showError(error);}).finally(() => {this.loading = false;});
    },
  }
</script>

<style scoped>

</style>

<template>
  <div>
    <Form ref="form" @submit="validate" :validation-schema="schema">
    <h5 class="info-text">{{ $t('company.this_is_last_step') }}</h5>

    <div class="row">
      <div class="col-12 col-md-6 col-xl-4 m-auto">
        <Field
                name="name"
                type="text"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.compiler_name') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="name"
                  type="text"
                  v-model="name">
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

      <div class="col-12 col-md-6 col-xl-4 m-auto">
        <Field
                name="surname"
                type="text"
                v-slot="{ value, field, errorMessage }">
          <label>{{ $t('company.compiler_surname') }}</label>
          <el-input
                  v-bind="field"
                  :model-value="value"
                  name="surname"
                  type="text"
                  v-model="surname">
          </el-input>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

      <div class="col-12 col-md-10 mt-5 mx-auto">
        <Field
                name="acceptTerms"
                type="text"
                v-slot="{ value, field, errorMessage }">
          <el-checkbox v-bind="field"
                       :model-value="value"
                       v-model="acceptTerms"
                      class="d-flex">
            {{ $t('company.disclaimer') }}
          </el-checkbox>
          <div class="text-danger invalid-feedback d-block" v-if="errorMessage">
            <div v-if="errorMessage.key">{{ $t(errorMessage.key, errorMessage.values) }}</div>
            <div v-else>{{ $t(errorMessage) }}</div>
          </div>
        </Field>
      </div>

    </div>

    </Form>
  </div>
</template>
<script>


import * as Yup from "yup";

import { ElInput, ElCheckbox } from "element-plus";
import { Form, Field } from "vee-validate";

export default {
  components: {
    [ElInput.name]: ElInput,
    [ElCheckbox.name]: ElCheckbox,
    Form,
    Field
  },
  data() {
    const schema = Yup.object().shape({
      name: Yup.string().required(),
      surname: Yup.string().required(),
      acceptTerms: Yup.boolean().default(false).oneOf([true], "yup.checkbox_required"),
    });

    return {
      schema,
      name: "",
      surname: "",
      acceptTerms: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => {
        if(res.valid)
          this.$emit('on-new-data', {compiler: {name: this.name, surname: this.surname, termsAccepted: this.acceptTerms}});
        this.$emit("on-validated", res);
        return res;
      });
    },
  },
};
</script>
<style scoped>
  .el-checkbox {
    white-space: normal;
  }
</style>
